import { Component, OnInit,AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../api/location.service';
import { Location,Door,Room,RoomWrapper } from '../api/location';
import { AuthService } from '../core/auth.service';
import { first } from 'rxjs/operators';
import { Observable, of, fromEvent } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import {  finalize, catchError } from 'rxjs/operators';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { ViewChild } from '@angular/core';
import { SensorFieldMapperComponent } from '../core/sensor-field-mapper/sensor-field-mapper.component';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-door-crud',
  templateUrl: './door-crud.component.html',
  styleUrls: ['./door-crud.component.css']
})
export class DoorCrudComponent implements OnInit, AfterViewInit {
  private roomsSubject = new BehaviorSubject<RoomWrapper[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  door: Door;
  id: number;
  locationId:number;
  isAddMode: boolean;
  IsExternal:boolean=false;
  IsPolling:boolean=false;
  doorDummy:Door={
    id : undefined,
    name : '',
    location_id:-1,
    is_external: true,
    flow_from_id: -1,
    flow_to_id: -1,
    flow_to:undefined,
    flow_from:undefined,
    current_state:0,
    sensor_id: '',
    is_polled: true,
    external_api_key:'',
    external_poll_url:'',
    serialized_sensor_field_map: '',


  };
  doorFrm: FormGroup;
  locations: Array<any>=[];
  rooms: Array<RoomWrapper>=[];
  doors: Array<Door>=[];
  roomsIndex:Array<Number>=[];
  loadedDoor:any;
  @ViewChild(SensorFieldMapperComponent, { static: false}) mapper: SensorFieldMapperComponent;
  env=environment;
  constructor(
    public auth: AuthService,
    public userSvc: UserService,
    private locationSvc: LocationService,
    private router: Router,
    private aR: ActivatedRoute,
    private fb: FormBuilder,
    private changeDetectorRef:ChangeDetectorRef,
  ) { }

  ngOnInit() {
    // this.userSvc.refreshMe();
    this.id =+this.aR.snapshot.params['id'];
    this.locationId = +this.aR.snapshot.params['location_id'];
    debuglog(RxJsLoggingLevel.DEBUG,'door-crud::init: id',this.id);
    debuglog(RxJsLoggingLevel.DEBUG,'door-crud::init: locationId',this.locationId);
    this.isAddMode = !this.id;
    this.setupFormGroup(this.doorDummy);
    if (!this.isAddMode) {
      this.locationSvc.getDoor(this.locationId,this.id)
        .pipe(first())
        .subscribe(x => {
          this.door=x.attributes;
          debuglog(RxJsLoggingLevel.DEBUG,'door-crud::init: door',this.door);
          // this.rooms.push(...x.attributes.rooms);
          // this.dsRooms.data = this.rooms;
          // this.doors.push(...x.attributes.doors);
          // this.dsDoors.data = this.doors;
          // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: doors',this.doors);
          debuglog(RxJsLoggingLevel.DEBUG,'door-crud::init: editModemapper',this.mapper);
          this.doorFrm.patchValue(x.attributes);
          // this.mapper.setFieldMap(JSON.parse(x.attributes.serialized_sensor_field_map));
        });
    }else{
      // debuglog(RxJsLoggingLevel.DEBUG,'door-crud::init: addMode mapper',this.mapper);
      // this.mapper.setFieldMap(null);
    }
    this.locationSvc.getLocationRooms(this.locationId)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false)),
        debug(RxJsLoggingLevel.DEBUG, "Loading location rooms from backend")
      ).subscribe(rooms => {
        // create location index from results
        debuglog(RxJsLoggingLevel.DEBUG, 'door-crud:Init::loadview:subscribe: before roomsIndex', rooms,this.roomsIndex);
        let roomsIndex:Array<number>=[];
        debuglog(RxJsLoggingLevel.DEBUG, 'door-crud:Init::loadview:subscribe: after roomsIndex', this.roomsIndex);
        debuglog(RxJsLoggingLevel.DEBUG, 'door-crud:Init::loadview:results: roomsIndex',roomsIndex);
        // map in favorites to locations results

        debuglog(RxJsLoggingLevel.DEBUG, 'door-crud:Init::loadview:results::LocationsDataSource enhanced rooms', rooms);
        this.rooms=rooms;
        this.roomsIndex=roomsIndex;
        debuglog(RxJsLoggingLevel.DEBUG, 'door-crud:Init::loadview:results: roomsIndex', this.roomsIndex);
        this.roomsSubject.next(this.rooms);
      });
  }

  ngAfterViewInit(){
    if (!this.isAddMode) {
      this.locationSvc.getDoor(this.locationId,this.id)
        .pipe(first())
        .subscribe(x => {
          this.door=x.attributes;

          debuglog(RxJsLoggingLevel.DEBUG,'door-crud::init: editMode mapper',this.mapper);

          this.mapper.setFieldMap(JSON.parse(this.door.serialized_sensor_field_map));
        });
    }else{
      debuglog(RxJsLoggingLevel.DEBUG,'door-crud::init: addMode mapper',this.mapper);
      this.mapper.setFieldMap(null);
    }
  }
  public setupFormGroup(door:Door){
    if(door!= null){
      this.doorFrm = this.fb.group({
        'name' : [door['name'], Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'current_state' : [door['current_state'], Validators.compose([Validators.pattern('[0-9]*')])],
        'is_external' : [(door['is_external']==null?false:door['is_external']), Validators.compose([Validators.required])],
        'sensor_id' : [door['sensor_id'], Validators.compose([Validators.required, Validators.minLength(10)])],
        'is_polled' : [(door['is_polled']==null?false:door['is_polled']), Validators.compose([Validators.required])],
        'external_poll_url' : [door['external_poll_url'], Validators.compose([ Validators.pattern(urlPattern)])],
        'flow_to_id' : [door['flow_to_id'],],
        'flow_from_id' : [door['flow_from_id'],],
        'external_api_key' : [door['external_api_key'],],
        'serialized_sensor_field_map' : [door['serialized_sensor_field_map'],],

      });
    }else{
      this.doorFrm = this.fb.group({
        'name' : [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'current_state' : [0, Validators.compose([Validators.pattern('[0-9]*')])],
        'is_external' : [null, Validators.compose([Validators.required])],
        'sensor_id' : [null, Validators.compose([Validators.required, Validators.minLength(10)])],
        'is_polled' : [null, Validators.compose([Validators.required])],
        'external_poll_url' : [null, Validators.compose([ Validators.pattern(urlPattern)])],
        'flow_to_id' : [null,],
        'flow_from_id' : [null,],
        'external_api_key' : [null,],
        'serialized_sensor_field_map' : [null,],
      });
    }
    debuglog(RxJsLoggingLevel.DEBUG,'door-crud::setupFormGroup: doorFrm',this.doorFrm);

  }

  onIsExternalChange(event:any)
  {
    if (event.checked == true) {
      this.IsExternal = true;
    } else {
      this.IsExternal = false;
    }
  }

  onIsPollingChange(event:any)
  {
    if (event.checked == true) {
      this.changeDetectorRef.detectChanges();
      this.IsPolling = true;
      this.mapper.setFieldMap(JSON.parse(this.door.serialized_sensor_field_map));
    } else {
      this.IsPolling = false;
    }
  }

  addDoor(locationId:number, doorId: number|undefined, door: Door) {
    door.serialized_sensor_field_map=JSON.stringify(this.mapper.getFieldMap());
    door.flow_from_id=door.flow_from_id== -1?null:door.flow_from_id;
    debuglog(RxJsLoggingLevel.DEBUG, 'door-crud:addDoor::door', door,locationId, doorId);

    if ( !isNaN(doorId)) {
      let sub = this.locationSvc.updateDoor(door,locationId, doorId)
        .subscribe(updateDoor => {
          sub.unsubscribe();
          this.router.navigateByUrl('/location-crud/'+locationId);

        })
    } else {

      let sub = this.locationSvc.insertDoor(door,locationId)
        .subscribe(newDoor => {
          // this.locations.push(newLocation);
          sub.unsubscribe();
          this.router.navigateByUrl('/location-crud/'+locationId);

        })
    }
  }

}

const urlPattern= '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
