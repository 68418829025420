export const environment = {
  production: false,
  version: 'v1.7.5',
  ga: 'G-BG7S4Z4243',
  firebase: {
    apiKey: "AIzaSyDTfvQ4iNufvtWzBfMODTDn8qJD-kNbVP8",
    authDomain: "elevated-honor-859.firebaseapp.com",
    databaseURL: "https://elevated-honor-859.firebaseio.com",
    projectId: "elevated-honor-859",
    storageBucket: "elevated-honor-859.appspot.com",
    messagingSenderId: "390004743455",
    appId: "1:390004743455:web:62359df54e9b5b8551a42a",
    vapidKey: "BJlHmABUaBbjZ3Cx9C_eU4GO1sFVDaw3lIAF8SYf25jqi8d02jf2dae5bbQCzgrH-tOkMtIEItt7tiNcCcucJCg"
  },
  uuid : {domain : "headcount.com"  },
  realtimeServerUrl: 'https://api.crowdsenz.com:5001',
  redemptionUrl:'https://api.crowdsenz.com:3000/api/alert_redemptions',
  apiUrl : 'https://api.crowdsenz.com:3000',
  appUrl : 'https://app.crowdsenz.com',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiYnJhc2thbSIsImEiOiJja3NqcXBzbWoyZ3ZvMm5ybzA4N2dzaDR6In0.RUAYJFnNgOnn80wXkrV9ZA',
  },
  maptiler: {
    accessToken: 'KVWzW7nTCKt1c6HtrXar',
  },
};
