import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../api/location.service';
import { AuthService } from '../core/auth.service';
import { first } from 'rxjs/operators';
import { Observable, of, fromEvent } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import {  finalize, catchError } from 'rxjs/operators';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { ViewChild } from '@angular/core';
import { User,TeamMember,TeamMemberWrapper } from '../core/user';
@Component({
  selector: 'app-team-crud',
  templateUrl: './team-crud.component.html',
  styleUrls: ['./team-crud.component.css']
})
export class TeamCrudComponent {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  teamMember: TeamMember;
  id: number;
  locationId:number;
  isAddMode: boolean;
  teamMemberDummy:TeamMember={
    id : undefined,
    role:'',
    user_id:-1,
    location_id:-1,
    user:{
      name:'',
      email : '',
      is_superadmin: false,
      is_admin: false,
      is_anonymous:false,
      roles:{},
      uid:''
    },
    location:{
      name : '',
      address: '',
      city: '',
      state: '',
      country: '',
      max_cap: 0,
      yelp_url: 'http://www.yelp.com',
      site_url: 'www.site.com',
      current_state: 0,
      is_active: true,
      latitude: 0,
      longitude:0,
      postal_code:'',
      categories: [],
      id:undefined,
      created_user_id: undefined,
      updated_user_id: undefined,
      rooms:undefined,
      doors:undefined,
      favId:null,
      isFavorite: false,
      alerts:[],
      fanscnt:null,
      demo_mode:false

    }
  };
  teamMemberFrm: FormGroup;
  loadedTeamMember:any;

  constructor(
    public auth: AuthService,
    public userSvc: UserService,
    private locationSvc: LocationService,
    private router: Router,
    private aR: ActivatedRoute,
    private fb: FormBuilder,
    private changeDetectorRef:ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.id =+this.aR.snapshot.params['id'];
    this.locationId = +this.aR.snapshot.params['location_id'];
    debuglog(RxJsLoggingLevel.DEBUG,'team-crud::init: id',this.id);
    debuglog(RxJsLoggingLevel.DEBUG,'team-crud::init: locationId',this.locationId);
    this.isAddMode = !this.id;
    this.setupFormGroup(this.teamMemberDummy);
    this.teamMember=this.teamMemberDummy;
    if (!this.isAddMode) {
      this.locationSvc.getTeamMember(this.locationId,this.id)
        .pipe(first())
        .subscribe(x => {
          this.teamMember=x.attributes;
          debuglog(RxJsLoggingLevel.DEBUG,'team-crud::init: team',this.teamMember);
          // this.rooms.push(...x.attributes.rooms);
          // this.dsRooms.data = this.rooms;
          // this.doors.push(...x.attributes.doors);
          // this.dsDoors.data = this.doors;
          // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: doors',this.doors);
          // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: rooms',this.rooms);
          this.teamMemberFrm.patchValue(x.attributes);

        });
    }else{

    }

  }
  public setupFormGroup(teamMember:TeamMember){
    if(teamMember!= null){
      this.teamMemberFrm = this.fb.group({
        'role' : [teamMember['role']],
      });
    }else{
      this.teamMemberFrm = this.fb.group({
        'role' : [''],
      });
    }

  }
  canAddWorker(){
    return this.userSvc.canAddWorker(this.locationId);
  }
  canAddManager(){
    return this.userSvc.canAddWorker(this.locationId);
  }
  canAddOwner(){
    return this.userSvc.canAddWorker(this.locationId);
  }

}
