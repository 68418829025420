import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { User,UserWrapper } from '../core/user';
import { AuthService } from '../core/auth.service';
import { Observable, of, fromEvent } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, finalize, catchError } from 'rxjs/operators';
import { PageEvent,  MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ViewChild } from '@angular/core';
import { MyPositionService,MyPositionData } from '../core/my-position.service';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { UserService } from '../api/user.service';

import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
// import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs';
import { ChromaService } from '../core/chroma.service';

@Component({
  selector: 'user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.css']
})
export class UserManageComponent implements OnInit {
  myLocation: MyPositionData;
  myRoles:any;
  form;
  user: User|undefined|null=null;
  categories = new FormControl([]);
  categoryList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];



  // for table and paging of table
  displayedColumns: string[] = ['email', 'actions'];
  // dataSource: UsersDataSource;

  @ViewChild(MatPaginator, {  static: false }) paginator: MatPaginator;
  @ViewChild('searchInput', {  static: false }) input: ElementRef;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20];

  // MatPaginator Output
  pageEvent: PageEvent;
  private users:UserWrapper[]=[];
  private usersIndex:number[];
  dataSourceMat: MatTableDataSource<UserWrapper>;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  filterStr:string='';

  constructor(public auth: AuthService,
    private userSvc: UserService,
    private myPositionSvc: MyPositionService,) { }

  ngOnInit() {
    // to lockdown particular functionality in Component
    // this.dataSource = new UsersDataSource(this.userSvc);
    // this.auth.userFiltered$.subscribe(user => {
    //   this.user=user;
    //   this.userSvc.myRoles$.subscribe(results=>{
        debuglog(RxJsLoggingLevel.DEBUG,'user-manage::init: myRole',this.userSvc.myRoles);
        this.myRoles= this.userSvc.myRoles;
        this.loadViewMat(
          // this.radius.value,
          // this.radiusScale.value,
          // this.categories.value,
          // this.myLocation,
          // '',
          // sortDirection,
          this.filterStr,
          0, 10);
        this.myPositionSvc.getCurrentPositionObservable().subscribe(loc => {
          this.myLocation = loc;

        });

    //   });
    //
    // })
    this.form = new FormGroup({
      name: new FormControl('default'),
      address: new FormControl(),
    });
  }

  ngAfterViewInit() {
    // server-side search TODO
    // fromEvent(this.input.nativeElement, 'keyup')
    //   .pipe(
    //     debounceTime(150),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       this.paginator.pageIndex = 0;
    //       this.loadView();
    //     })
    //   )
    //   .subscribe();
    // this.paginator.page
    //   .pipe(
    //     tap(() => this.loadView())
    //   )
    //   .subscribe();

  }




  loadViewMat(
    // radius = 5, scale = 'miles', categories: [], userlocation, filter = '',
    // sortDirection = 'asc',
    filter=this.filterStr,
    pageIndex = 0, pageSize = 10) {

    this.loadingSubject.next(true);
    // filter, sortDirection,pageIndex, pageSize
    debuglog(RxJsLoggingLevel.DEBUG, 'user-manage:loadViewMat::\: before userSvc.getUsers', filter,pageIndex,pageSize);
    let userQuerySub = this.userSvc.getUsers(
      {
        filter:filter,
        pageIndex: pageIndex,
        pageSize: pageSize
      }
    ).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)),
      debug(RxJsLoggingLevel.DEBUG, "Loading users from backend")
    ).subscribe(result => {
      // create user index from results
      debuglog(RxJsLoggingLevel.DEBUG, 'user-manage:UsersDataSource::loadview:subscribe: before usersIndex', result,this.usersIndex);
      let usersIndex:number[]=[];
      debuglog(RxJsLoggingLevel.DEBUG, 'user-manage:UsersDataSource::loadview:subscribe: after usersIndex', this.usersIndex);
      debuglog(RxJsLoggingLevel.DEBUG, 'user-manage:UsersDataSource::loadview:results: usersIndex',usersIndex);
      // map in favorites to users results

      debuglog(RxJsLoggingLevel.DEBUG, 'user-manage:loadview:results::UsersDataSource enhanced users', result.users.data);
      // this.users=result.users.data;
      this.users.length = result.total;
      this.users.splice(pageIndex*pageSize,result.users.data.length, ...result.users.data);
      this.usersIndex=usersIndex;
      debuglog(RxJsLoggingLevel.DEBUG, 'user-manage:UsersDataSource::loadview:results: usersIndex', this.usersIndex);
      this.dataSourceMat = new MatTableDataSource(this.users);
      this.dataSourceMat.filterPredicate = (data: UserWrapper, filter: string) => data.attributes.email.toLowerCase().indexOf(filter) != -1||data.attributes.uid.toLowerCase().indexOf(filter) != -1;
      this.dataSourceMat.paginator = this.paginator;
      userQuerySub.unsubscribe();
    });

  }

  deleteUser():void{

  }
  search():void{
    const filter = this.input.nativeElement.value ;
    this.filterStr=filter.trim().toLowerCase();
    debuglog(RxJsLoggingLevel.DEBUG, 'user-manage::search: input', this.input);
    // this.dataSourceMat.filter=this.filterStr;
    this.loadViewMat(

      this.filterStr,
      // sortDirection,
      this.paginator.pageIndex, this.paginator.pageSize
    );
  }
  onRowClicked(row:number) {
    debuglog(RxJsLoggingLevel.DEBUG, 'Row clicked: ', row);
  }
  pageChanged(event){
    // this.loading = true;

    let pageIndex = event.pageIndex;
    let pageSize = event.pageSize;

    let previousIndex = event.previousPageIndex;

    let previousSize = pageSize * pageIndex;
    debuglog(RxJsLoggingLevel.DEBUG, 'User-manage::pageChanged ', event);

    this.loadViewMat(this.filterStr,(pageIndex).toString(), pageSize.toString());
  }

}
