import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit, QueryList,Input,ComponentRef,ChangeDetectorRef,RendererFactory2,Renderer2,ViewContainerRef, OnDestroy, Injector } from '@angular/core';

import { ChromaService } from '../core/chroma.service';

import { Location,LocationWrapper } from '../api/location';

import { ViewChild } from '@angular/core';
// import { } from 'googlemaps';
import * as L from 'leaflet';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { Observable, of, fromEvent } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, finalize, catchError } from 'rxjs/operators';

import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
// import * as _ from 'lodash';
import { UiStateService } from '../core/ui-state.service';
import { MyPositionService, MyPositionData } from '../core/my-position.service';

import { MapIconComponent } from '../core/map-icon/map-icon.component';
import { MapMarkerPopupComponent } from '../map-marker-popup/map-marker-popup.component';
@Component({
  selector: 'app-search-map',
  templateUrl: './search-map.component.html',
  // template: `<div style="width:85%;height:400px" #mapElement >map here</div><div id="popupnest" style="display: none;"><div  #popupnest></div><div>`,
  styleUrls: ['./search-map.component.css',
            '../../../node_modules/leaflet/dist/leaflet.css']
})
export class SearchMapComponent implements OnInit,AfterViewInit,OnDestroy {
  @ViewChild('mapElement',{ static: false}) mapElement: ElementRef;
  @ViewChildren('searchMap', {read: true}) searchMap: QueryList<ElementRef>;
  // @ViewChild('viewContainerRef', { read: ViewContainerRef, static: false })
  @ViewChildren('mapElement', { read: ViewContainerRef,  })
  public mapContainers: QueryList<ViewContainerRef>;
  @ViewChildren('popupnest', { read: ViewContainerRef,  })
  public popupNests: QueryList<ViewContainerRef>;
  @Input() locs:LocationWrapper[];
  // {
  //   debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:ViewChild: mapElement', mapElement,this.searchMap);
  //   if(!mapElement) return;
  //   this.mapSetup(mapElement);
  // };
  // gmap: google.maps.Map;
  // mapElement2: ElementRef;
  map: any;
  markers:L.Marker[]=[];
  markersLocIndex:L.Marker[]=[];
  showMap:boolean=false;

  mapMarkers:any[]=[];
  public componentRefs: ComponentRef<MapIconComponent>[] = [];
  public popupComponentRefs: ComponentRef<MapMarkerPopupComponent>[] = [];


  renderer:Renderer2;
  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterData = [];
  clusterMarkers = L.markerClusterGroup();
  mapOptions= {
  	layers: [
  		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...',accessToken: environment.maptiler.accessToken, })
  	],
  	zoom: 5,
  	center: L.latLng(46.879966, -121.726909)
  };
  public mapSubject= new Subject<boolean>;

  public map$ = this.mapSubject.asObservable();

  constructor(
    private uiStateSvc: UiStateService,
    private myPositionSvc: MyPositionService,
    private cdr: ChangeDetectorRef,
    private rendererFactory: RendererFactory2,
    private injector: Injector) {
      this.renderer = rendererFactory.createRenderer(null, null);
    }

  ngOnInit() {
    this.markerClusterGroup = L.markerClusterGroup({removeOutsideVisibleBounds: true});
    this.uiStateSvc.locations$.subscribe(locs => {
      debuglog(RxJsLoggingLevel.DEBUG, 'searchmap: ngOnInit locations locs',this.locs, this.showMap);
      this.locs=locs;
      // this.addMarkers();
      if(this.showMap){
        // this.loadMap();
        // this.showMap=true;
      }else{
        // this.loadMap();
        // this.addMarkers();
      }
      // debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:ngOnInit locs', this.locs);
    });
    this.uiStateSvc.refreshMap$.subscribe(state => {
      debuglog(RxJsLoggingLevel.DEBUG, 'searchmap: ngOnInit refreshMap locs',this.locs, this.showMap, this.map);

      // this.addMarkers();
      if(state){
        this.loadCheck();
        // this.showMap=true;
      }else{
        // this.loadMap();
        // this.addMarkers();
      }
      // debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:ngOnInit locs', this.locs);
    });
    this.uiStateSvc.showMap$.subscribe(showMap => {
      debuglog(RxJsLoggingLevel.DEBUG, 'searchmap: ngOnInit showMap locs',this.locs, this.showMap);
      this.showMap=showMap;
      // this.addMarkers();
      if(showMap){
        this.loadCheck();
        // this.showMap=true;
      }else{
        // this.loadMap();
        // this.addMarkers();
      }
      // debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:ngOnInit locs', this.locs);
    });
  }

  ngOnDestroy(){
    this.componentDestroy();
  }

  componentDestroy(){
    this.componentRefs.map(componentRef =>{
      componentRef.destroy();
    });
    this.popupComponentRefs.map(popupComponentRef =>{
      popupComponentRef.destroy();
    });
  }
  loadCheck(){
    if (this.mapContainers.length > 0 && this.mapContainers.length > 0 ) {
      // The container already exists
      debuglog(RxJsLoggingLevel.DEBUG, 'searchmap: loadCheck immediate mapContainers',this.mapContainers);
      this.loadMap();
      this.myPositionSvc.getCurrentPositionObservable()
      .subscribe((position: any) => {
        this.loadMap();
      });
    };

    this.mapContainers.changes.subscribe(() => {
      this.popupNests.changes.subscribe(() => {
        // The container has been added to the DOM
        debuglog(RxJsLoggingLevel.DEBUG, 'searchmap: loadCheck subscribe mapContainers',this.mapContainers);
        this.loadMap();
        this.myPositionSvc.getCurrentPositionObservable()
        .subscribe((position: any) => {
          this.loadMap();
        });
      });
    });
  }
  ngAfterViewInit() {
    debuglog(RxJsLoggingLevel.DEBUG, 'searchmap: ngAfterViewInit locs',this.locs, this.showMap,this.mapContainers);
    // this.loadMap();
    this.showMap=true;


    this.loadCheck();


    // this.searchMap.changes.subscribe((comps: QueryList<ElementRef>) =>
    // {
    //   debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:loaded comps', comps);
    //   // Now you can access to the child component
    // });
  }
  onMapReady(map: L.Map) {
  	this.map=map;
    this.mapSubject.next(this.map);
    debuglog(RxJsLoggingLevel.DEBUG, 'searchmap::onMapReady map', map);

  }

  private  loadMap(): void {
    debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:loadMap mapElement', this.mapElement,this.map);
    if(this.map == undefined){
      this.map = L.map(this.mapElement.nativeElement).setView([0, 0], 1);
      debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:loadMap map recreate', this.mapElement,this.map);
    }
  // https://api.maptiler.com/maps/openstreetmap/?key='+environment.maptiler.accessToken+'#0.7/22.80535/2.86559'
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
        crossOrigin: true,
        minZoom: 1,
        tileSize: 512,
        zoomOffset: -1,
        accessToken: environment.maptiler.accessToken,
      }).addTo(this.map);

      // const position: any = await this.locationService.getCurrentLocation();
      // this.myPositionSvc.getCurrentPositionObservable()
      // .subscribe((position: any) => {
      let position= this.myPositionSvc.currentPosition;
      if((position.latitude !== undefined && position.latitude !== null) && (position.longitude !== undefined && position.longitude !==null )){
        debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:getCurrentPosition position', position);
        this.map.flyTo([Number(position.latitude), Number(position.longitude)], 13);
        // position.latitude, position.longitude

        const icon = L.icon({
          iconUrl: 'assets/images/marker-icon.png',
          shadowUrl: 'assets/images/marker-shadow.png',
          popupAnchor: [13, 0],
        });

        const marker = L.marker([Number(position.latitude), Number(position.longitude)], { icon }).bindPopup('You are here.');
        // position.latitude, position.longitude
        marker.addTo(this.map);

        this.markers.push(marker);
      };
    // }

    this.addMarkers();

  }

  private async addMarkers(): Promise<void> {
    const { MapIconComponent } = await import('../core/map-icon/map-icon.component');

    this.markers.map((marker)=>{
      this.map.removeLayer(marker);
    });
    this.componentDestroy();
    this.markerClusterGroup.clearLayers();
    this.markers=[];
    let latLongs=[];
    debuglog(RxJsLoggingLevel.DEBUG, 'searchmap::addMarkers start  locs', this.locs);
    this.locs.map((loc)=>{
      debuglog(RxJsLoggingLevel.DEBUG, 'searchmap::addMarkers loc loop enter  loc', loc);
      if(loc.attributes.latitude!== null && loc.attributes.longitude!== null && loc.attributes.latitude!== 0 && loc.attributes.longitude!== 0){
        const componentRef = this.mapContainers.first.createComponent(MapIconComponent);
        componentRef.instance.loc=loc.attributes;
        componentRef.instance.heat=loc.attributes.current_state/(loc.attributes.max_cap >0?loc.attributes.max_cap:1);
        const popupComponentRef = this.popupNests.first.createComponent(MapMarkerPopupComponent);
        popupComponentRef.instance.loc=loc;


        debuglog(RxJsLoggingLevel.DEBUG, 'searchmap::addMarkers loc loop   loc', loc,componentRef,this.locs);
        // this.map.flyTo([Number(loc.attributes.latitude), Number(loc.attributes.longitude)], 13);
        // const icon = L.icon({
        //   iconUrl: 'assets/images/marker-icon.png',
        //   shadowUrl: 'assets/images/marker-shadow.png',
        //   popupAnchor: [13, 0],
        // });

        const svgIcon = L.divIcon({
          html: componentRef.location.nativeElement,
          className: "svg-icon",
          iconSize: [32, 32],
          iconAnchor: [16, 15],
        });

        // const marker = L.marker([loc.attributes.latitude, loc.attributes.longitude], { icon }).bindPopup(loc.attributes.name);
        const marker = L.marker([loc.attributes.latitude, loc.attributes.longitude], { icon: svgIcon }).bindPopup(popupComponentRef.location.nativeElement);
        let el =marker.getElement();
        debuglog(RxJsLoggingLevel.DEBUG, 'searchmap::addMarkers loc loop enter  last part', el,componentRef);
        // this.renderer.appendChild(el, componentRef.location.nativeElement);


        // const div = this.someDiv.nativeElement.querySelector(".insert");
        // const button = this.someButton.nativeElement;
        // div.appendChild(button);
        // popupComponentRef.destroy();
        // marker.addTo(this.map);
        // this.clusterMarkers.addLayer(marker)
        this.markerClusterGroup.addLayer(marker)
        // el.appendChild(componentRef.location.nativeElement);
        this.componentRefs.push(componentRef);
        this.popupComponentRefs.push(popupComponentRef);
        // const parentComponentRef = this.injector.get(this);
        // parentComponentRef.removeChild(popupComponentRef);
        this.markers.push(marker);
        latLongs.push([loc.attributes.latitude, loc.attributes.longitude]);
        // let group =new  L.featureGroup(this.markers);
        this.markersLocIndex[loc.attributes.id]=marker;


      }
    });
    // this.markerClusterGroup.addTo(this.map);
    this.map.addLayer(this.markerClusterGroup);
    this.map.fitBounds(latLongs);
    this.cdr.detectChanges();
    this.uiStateSvc.setRefreshMapIfOpen(false);
    debuglog(RxJsLoggingLevel.DEBUG, 'searchmap::addMarkers end  markers', this.markers,this.markersLocIndex,this.componentRefs);
  }

}
