import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { UserService } from '../api/user.service';
import { RoomSensor,  } from '../api/location';
import { LocationService } from '../api/location.service';
import {  Subscription } from 'rxjs';

@Component({
  selector: 'app-room-sensor-delete',
  templateUrl: './room-sensor-delete.component.html',
  styleUrls: ['./room-sensor-delete.component.css']
})
export class RoomSensorDeleteComponent implements OnInit {
locSub:Subscription;
  constructor(
    private userSvc: UserService,
    private locationSvc: LocationService,
    public dialogRef: MatDialogRef<RoomSensorDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data:  RoomSensor,) { }

  ngOnInit() {
  }
  cancel(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-delete::cancel: data');
    this.dialogRef.close();

  }
  delete(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-delete::delete: data');
    if(this.userSvc.checkAuthorization(['superadmin','admin'])||this.userSvc.checkLocationRole(this.data['roomSensor'].id,['owner']) )this.locSub=this.locationSvc.deleteRoomSensor(this.data['roomSensor'].id, this.data['roomId'],this.data['locationId']).subscribe((result)=>{
      this.locSub.unsubscribe();
    });
    
    this.dialogRef.close();

  }


}
