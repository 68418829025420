<table mat-table [dataSource]="dsTeamInvites">

  <!-- Position Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Team Invites</th>
    <td mat-cell *matCellDef="let element">
      {{element.email}}
    </td>
  </ng-container>


  <!-- Weight Column -->
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Role </th>
    <td mat-cell *matCellDef="let element"> {{element.role}} </td>
  </ng-container>


  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let element" class="button-container">
      <!-- TODO add copy link button -->
      <button mat-icon-button  (click)="copyTeamInvite(element.location_id,element.link_id)"><mat-icon>file_copy</mat-icon></button>
      <button mat-icon-button  (click)="deleteTeamInvite(element.location_id,element)"><mat-icon>delete</mat-icon></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
