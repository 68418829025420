import { Component, OnInit } from '@angular/core';
import { UserService } from '../api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../api/location.service';
import { Location,Door,Room, LocationWrapper } from '../api/location';
import { AuthService } from '../core/auth.service';
import { first } from 'rxjs/operators';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { MatTableDataSource } from '@angular/material/table';
import { LocationApiComponent } from '../core/location-api/location-api.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { UiStateService } from '../core/ui-state.service';

@Component({
  selector: 'location-crud',
  templateUrl: './location-crud.component.html',
  styleUrls: ['./location-crud.component.css']
})
export class LocationCrudComponent implements OnInit {
  location: any;
  id: number|undefined;
  isAddMode: boolean;


  locationDummy={
    name : '',
    address: '',
    city: '',
    state: '',
    country: '',
    max_cap: 0,
    yelp_url: 'http://www.yelp.com',
    site_url: 'www.site.com',
    current_state: 0,
    is_active: true,
    latitude: 0,
    longitude:0,
    postal_code:'',
    categories: [],
    id:undefined,
    created_user_id: undefined,
    updated_user_id: undefined,
    rooms:undefined,
    doors:undefined,
    favId:null,
    isFavorite: false,
    alerts:[],
    fanscnt:null,
    demo_mode:false

  };
  locationFrm: FormGroup;
  locations: Array<any>;
  rooms: Array<Room>=[];
  doors: Array<Door>=[];
  loc:any;
  token:string='';
  apiKey:string='';

  categoryList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  dsDoors: MatTableDataSource<Door>;
  dsRooms: MatTableDataSource<Room>;
  durationInSeconds = 5;



  constructor(
    public auth: AuthService,
    public userSvc: UserService,
    private locationSvc: LocationService,
    private router: Router,
    private aR: ActivatedRoute,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private uiStateSvc: UiStateService,
  ) {
     this.dsDoors = new MatTableDataSource<Door>();
     this.dsRooms = new MatTableDataSource<Room>();
  }

  ngOnInit() {
    // this.locationSvc.getLocations()
    //   .subscribe(res=> this.locations = res);
    // this.userSvc.refreshMe();
    this.id = +this.aR.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.setupFormGroup(this.locationDummy);
    if (!this.isAddMode) {
      this.locationSvc.getLocation(this.id)
        .pipe(first())
        .subscribe(x => {
          this.loc=x;
          this.token=x.attributes.api_token!==null?x.attributes.api_token:'<blank>';
          this.apiKey=x.attributes.api_key!==null?x.attributes.api_key:'<blank>';
          debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: loc',this.loc);
          // this.rooms=this.loc.attributes.rooms;
          this.rooms.push(...x.attributes.rooms);
          this.dsRooms.data = this.rooms;
          this.doors.push(...x.attributes.doors);
          this.dsDoors.data = this.doors;
          debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: doors',this.doors);
          debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: rooms',this.rooms);
          this.locationFrm.patchValue(x.attributes);
        });
    }

  }

  public setupFormGroup(location:Location){
    if(location!= null){
      this.locationFrm = this.fb.group({
        'name' : [location['name'], Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(150)])],
        'address' : [location['address'], Validators.compose([Validators.required,])],
        'city' : [location['city'], Validators.compose([Validators.required,])],
        'state' : [location['state'], Validators.compose([Validators.required, ])],
        'postal_code' : [location['postal_code'], Validators.compose([Validators.required, Validators.minLength(5)])],
        'country' : [location['country'], Validators.compose([])],
        'max_cap' : [location['max_cap'], Validators.compose([Validators.required, Validators.pattern('[1-9].[0-9]*')])],
        'yelp_url' : [location['yelp_url'], Validators.compose([ Validators.pattern(urlPattern)])],
        'site_url' : [location['site_url'], Validators.compose([Validators.pattern(urlPattern)])],
        'current_state' : [location['current_state'], Validators.compose([Validators.pattern('[0-9]*')])],
        'is_active' : [location['is_active'], Validators.compose([Validators.required, Validators.minLength(10)])],
        'latitude' : [location['latitude'], Validators.compose([])],
        'longitude' : [location['longitude'], Validators.compose([])],
        'tags' : [location['tags'], Validators.compose([])],
        'demo_mode': [(location['demo_mode']==null?false:location['demo_mode']),Validators.compose([])]
      });
    }else{
      this.locationFrm = this.fb.group({
        'name' : ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(150)])],
        'address' : ['', Validators.compose([Validators.required,])],
        'city' : ['', Validators.compose([Validators.required, ])],
        'state' : ['', Validators.compose([Validators.required, ])],
        'postal_code' : ['', Validators.compose([Validators.required, Validators.minLength(5)])],
        'country' : ['', Validators.compose([])],
        'max_cap' : ['', Validators.compose([Validators.required, Validators.pattern('[1-9].[0-9]*')])],
        'yelp_url' : ['', Validators.compose([ Validators.pattern(urlPattern)])],
        'site_url' : ['', Validators.compose([Validators.pattern(urlPattern)])],
        'current_state' : ['', Validators.compose([Validators.pattern('[0-9]*')])],
        'is_active' : [false, Validators.compose([Validators.required, Validators.minLength(10)])],
        'latitude' : ['', Validators.compose([])],
        'longitude' : ['', Validators.compose([])],
        'tags' : [null, Validators.compose([])],
        'demo_mode': [false,Validators.compose([])]
      });
    }
    debuglog(RxJsLoggingLevel.DEBUG,'location-crud::setupFormGroup: locationFrm',this.locationFrm);

  }

  onDemoModeChange(event:any)
  {
    // if (event.checked == true) {
    //   this.IsExternal = true;
    // } else {
    //   this.IsExternal = false;
    // }
  }

  addLocation(locationId:number|undefined, location: Location) {
    debuglog(RxJsLoggingLevel.DEBUG,'location-crud::addLocation: locationId location',locationId,location);
    if(!this.userSvc.checkAuthorization(['superadmin','admin'])) delete location.demo_mode;
    if (!Number.isNaN(locationId)) {

      let sub=this.locationSvc.updateLocation(location, locationId)
        .subscribe(updateLocation => {
          sub.unsubscribe();
          let snackBarRef =   this._snackBar.open(location.name +  ' Updated', '',{
            duration: this.durationInSeconds * 1000,
            // data: alertW
          });
          snackBarRef.onAction().subscribe(() => {

          });
          snackBarRef.afterDismissed().subscribe(() => {


          });
          this.uiStateSvc.trackInteraction('location_update', 'location', location.name, location.id,undefined, {
              location_id:location.id,
              name:location.name,
          });

          this.router.navigateByUrl('/location-crud/'+locationId);

        })
    } else {

      let sub =this.locationSvc.insertLocation(location)
        .subscribe(newLoc => {
          // this.locations.push(newLocation);
          let newLocation= newLoc.data as LocationWrapper;
          let snackBarRef =   this._snackBar.open(location.name +  ' Created', '',{
            duration: this.durationInSeconds * 1000,
            // data: alertW
          });
          snackBarRef.onAction().subscribe(() => {

          });
          snackBarRef.afterDismissed().subscribe(() => {


          });
          this.uiStateSvc.trackInteraction('location_create', 'location', location.name, newLocation.attributes.id,undefined, {
              location_id:newLocation.attributes.id,
              name:location.name,
          });
          sub.unsubscribe();
          this.router.navigateByUrl('/location-crud/'+newLocation.attributes.id);

        })
    }
  }


}

const urlPattern= '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
