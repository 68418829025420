import { Component, OnInit } from '@angular/core';
import { UserService } from '../api/user.service';
import { User,UserWrapper } from '../core/user';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';


import { AuthService } from '../core/auth.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'user-crud',
  templateUrl: './user-crud.component.html',
  styleUrls: ['./user-crud.component.css']
})
export class UserCrudComponent implements OnInit {
  user: UserWrapper;
  id: number;
  isAddMode: boolean;
  userDummy:User={
    name:'',
    email : '',
    is_superadmin: false,
    is_admin: false,
    is_anonymous:false,
    roles:{
      superadmin:false,
      admin: false,
      owner:false,
      manager:false,
      worker: false,
      is_anonymous: true,
    },
    uid:''
  };
  userFrm: FormGroup;
  users: Array<User>=[];



  categoryList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];



  constructor(
    public auth: AuthService,
    public userSvc: UserService,

    private router: Router,
    private aR: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    // this.userSvc.getUsers()
    //   .subscribe(res=> this.users = res);
    this.id = +this.aR.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.setupFormGroup(this.userDummy);
    if (!this.isAddMode) {
      this.userSvc.getUser(this.id)
        .pipe(first())
        .subscribe(x => {
          x.attributes.is_admin=(x.attributes.is_admin=== null?false:x.attributes.is_admin);
          x.attributes.is_superadmin=(x.attributes.is_superadmin=== null?false:x.attributes.is_superadmin);
          this.user=x;

          this.userFrm.patchValue(x.attributes);
          debuglog(RxJsLoggingLevel.DEBUG, 'user-crud:got user', this.user,this.userFrm);
        });
    }

  }

  // attributes :id, :email, :is_admin,  :is_superadmin, :is_anonymous :roles

  // attributes :user_places, :managed_locations, :worked_locations,:locations, if: Proc.new { |record, params|
  //   params && params[:long] == true

  public setupFormGroup(user:User){
    if(user!= null){
      this.userFrm = this.fb.group({
        'email' : [user['email'], Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'is_superadmin' : [user['is_superadmin'], Validators.compose([Validators.required, Validators.minLength(10)])],
        'is_admin' : [user['is_admin'], Validators.compose([Validators.required, Validators.minLength(10)])],

      });
    }else{
      this.userFrm = this.fb.group({
        'email' : ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'is_superadmin' : [false, Validators.compose([Validators.required, Validators.minLength(10)])],
        'is_admin' : [false, Validators.compose([Validators.required, Validators.minLength(10)])],

      });
    }
    debuglog(RxJsLoggingLevel.DEBUG, 'user-crud:setupFormGroup', user,this.userFrm);

  }

  addUser(userId:number, user: User) {

    if (userId !== undefined) {

      let sub=this.userSvc.updateUser(user, userId)
        .subscribe(updateUser => {
          sub.unsubscribe();
          this.router.navigateByUrl('/user-manage');
        })
    } else {

      let sub=this.userSvc.insertUser(user)
        .subscribe(newUserobj => {
          sub.unsubscribe();
          this.users.push(newUserobj);
          this.router.navigateByUrl('/user-manage');
        })
    }
  }

}

const urlPattern= '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
