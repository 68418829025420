import { Component, Input, OnInit, ElementRef } from '@angular/core';


import { User,Roles } from '../core/user';
import { AuthService } from '../core/auth.service';
import { PageEvent,  MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild } from '@angular/core';
// import { MyPositionService,MyPositionData } from '../core/my-position.service';

import { UserService } from '../api/user.service';
import {Room,RoomList } from '../api/location';
import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import {MatDialog, } from '@angular/material/dialog';
import { RoomDeleteComponent } from '../room-delete/room-delete.component';


@Component({
  selector: 'app-room-manage',
  templateUrl: './room-manage.component.html',
  styleUrls: ['./room-manage.component.css']
})
export class RoomManageComponent implements OnInit {
  // myLocation: MyPositionData;

  myRoles:Roles;

  user: User| null| undefined;
  // for table and paging of table
  displayedColumns: string[] = ['name', 'max_cap', 'current_state','actions'];
  @Input() locationId!:Number;
  @Input() rooms!:RoomList;
  @Input() dsRooms: MatTableDataSource<Room>;
  @ViewChild(MatPaginator, { read: false, static: false }) paginator: MatPaginator;
  @ViewChild('input', { read: false, static: false }) input: ElementRef;
  length:number = 100;
  pageSize:number = 10;
  pageSizeOptions: number[] = [5, 10, 20];

  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(
    public auth: AuthService,
    public dialog: MatDialog ,

    private userSvc: UserService,
    // private myPositionSvc: MyPositionService,
  ) { }

  ngOnInit() {
    // this.auth.user$.subscribe(user => {this.user=user;
    //   this.userSvc.myRoles$.subscribe(results=>{
    //     debuglog(RxJsLoggingLevel.DEBUG,'room-manage::init: myRole',results);
    //     this.myRoles= results;
    //   });
    //
    // });
    this.user=this.auth.user;
    this.myRoles= this.userSvc.myRoles;
  }
  deleteRoom(room:Room):void{
    const dialogRef = this.dialog.open(RoomDeleteComponent, {
      width: '250px',
      data: {room: room, locationId:this.locationId}
    });

    dialogRef.afterClosed().subscribe(result => {

      debuglog(RxJsLoggingLevel.DEBUG, 'room-manage:RoomDeleteComponent::afterClosed:result: ',result);

    });
  }

  onRowClicked(row:number) {
    debuglog(RxJsLoggingLevel.DEBUG, 'Row clicked: ', row);
  }

}
