import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
// import { HttpModule } from '@angular/http';




@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    // HttpModule
  ],
  declarations: [],
  providers: [

    // { provide: 'Chroma', useValue: window['chroma']() },
    // { provide: 'Uuid', useValue: window['uuidv5']() }
  ]
})
export class ApiModule { }
