
      <div mat-dialog-content>
        <div layout="column">
          <div layout="row">
            <div class="h3">Search/ Manage Places</div>
          </div>


          <div>

            <div class="form-group">
              <label for="inputAddress" class="col-lg-2 control-label">Search</label>
              <div class="col-lg-10">

                    <!-- <mat-list-item *ngFor="let link of links"> -->
                    <form>
                      <mat-form-field>
                        <!-- <a matLine href="...">{{ currentPositionTxt }}</a> -->
                        <input type="text"
                           id="inputAddress"
                           placeholder="Where are you?"
                           aria-label="Position"
                           matInput
                           #search
                           value=""
                           [formControl]="myControl"
                           [matAutocomplete]="auto"
                           >
                           <!-- [ngClass]="{'compressed-input-width': isCompressedLayout}" -->
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                          <mat-option *ngFor="let element of myPositionSvc.getSearchResultsObservable() | async" [value]="element">
                            {{(element.attributes.label.length > 0?element.attributes.label :element.attributes.place)}}
                          </mat-option>
                          <mat-option *ngFor="let element of filteredUserPlaces | async" [value]="element">
                            <button class="header-item" mat-icon-button (click)="editUserPlace(element);$event.stopPropagation()">
                              <mat-icon>settings</mat-icon>
                            </button>
                            {{(element.attributes.label.length > 0?element.attributes.label :element.attributes.place)}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field></form>


                      <button class="header-item" mat-icon-button (click)="searchFn()"  *ngIf="showPositionCheckButtons">
                         <mat-icon>check</mat-icon>
                      </button>


                      <button class="header-item" mat-icon-button (click)="newUserPlace()"  *ngIf="myPositionSvc.currentPosition.id < 0">
                         <mat-icon>save</mat-icon>
                      </button>
              </div>
            </div>




        </div>
      </div>
      <div mat-dialog-actions>
        <!-- <button type="button" class="btn btn-primary" (click)="updateUP()"  *ngIf="data['position'].id > -1">Update</button>
        <button type="button" class="btn btn-primary" (click)="save()"  *ngIf="data['position'].id <0 ">Save</button> -->
        <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>&nbsp;&nbsp;&nbsp;
        <!-- <button type="button" class="btn btn-primary" (click)="deleteUP()"  *ngIf="data['position'].id > -1">Delete</button> -->
      </div>
