
<div class="container">
  <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <h3>Register</h3>
      <form [formGroup]="registerForm">
        <div class="form-group">
          <label>Email address</label>
          <input type="email" formControlName="email" class="form-control">
        </div>
        <div class="form-group">
          <label>Password</label>
          <input type="password" class="form-control" formControlName="password">
          <label class="error">{{errorMessage}}</label>
          <label class="success">{{successMessage}}</label>
        </div>
        <button type="submit" (click)="tryRegister(registerForm.value)" class="btn btn-default">Register</button>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-md-offset-4">
      <button type="button" class="btn btn-danger btn-block" (click)="tryGoogleLogin()">Connect Google</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-md-offset-4">
      <button type="button" class="btn btn-primary btn-block" (click)="tryFacebookLogin()">Connect Facebook</button>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-4 col-md-offset-4">
      <button type="button" class="btn btn-info btn-block" (click)="tryTwitterLogin()">Register with Twitter</button>
    </div>
  </div> -->
  <div class="row"  *ngIf="!hideLink">
    <div class="col-md-4 col-md-offset-4">
      <p>Already have an account? <a routerLink="/user">Login</a></p>
    </div>
  </div>
</div>
