import { Injectable } from '@angular/core';

import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { map,tap } from 'rxjs/operators';
import { UserPlace } from './user-place';
import { Observable,of } from 'rxjs';
import { switchMap} from 'rxjs/operators';

import { AuthService} from '../core/auth.service';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserPlaceService {

  constructor(private http: HttpClient,
    private authSvc: AuthService) { }

  getHeaders():HttpHeaders{
    return new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + this.authSvc.currentUserJWT
    });
  }

  getUserPlaces() {
    return this.http.get(environment.apiUrl + "/api/user_places",{ headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'UserPlaceSvc::getUserPlaces:get:response'),
      map(res =>  res["data"]),
    )
  }
  getPositionHttp() {
    return this.http.get(environment.apiUrl + "/api/user_places/reverse_geocode_request",{ headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'UserPlaceSvc::getPositionHttp:get:response'),
      // map(res =>  res["data"]),
    )
  }

  getUserPlacesByUser(criteria){
    const params = new HttpParams().set('filter', criteria['filter'])
    // .set('sortOrder', criteria['sortOrder'])
    .set('pageIndex', criteria['pageIndex'].toString())
    .set('pageSize', criteria['pageSize'].toString());
    // this.lastSearchParams=criteria;
    debuglog(RxJsLoggingLevel.DEBUG,'getUserPlacesByUser::params ',criteria);
    debuglog(RxJsLoggingLevel.DEBUG,'getUserPlacesByUser::params keys ',Object.keys(criteria));
    return this.http.get(environment.apiUrl + "/api/user_places/manage",{ headers: this.getHeaders(), params: params}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'UserPlaceSvc::getUserPlacesByUser:manage:response'),
      map(res =>  res["data"]),
    )
  }

  getUserPlace(id:number) {
    return this.http.get(environment.apiUrl + "/api/user_places/"+id,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'UserPlaceSvc::getUserPlace:loc:response'),
        map(res =>  res["data"]),
      );
      // .subscribe(result => this.result = result.json());
  }

  getUserPlaceFull(id:number) {
    return this.http.get(environment.apiUrl + "/api/user_place/full/"+id,{ headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'UserPlaceSvc::loadView:full:response'),
      map(res =>  res["data"]),
  );
      // .subscribe(result => this.result = result.json());
  }

  insertUserPlace(post: any) {

    return this.http.post(environment.apiUrl + '/api/user_places', JSON.stringify(post), {headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  updateUserPlace(post: UserPlace, id:number) {
    return this.http.put(environment.apiUrl + '/api/user_places/'+id, JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'UserPlaceSvc::updateUserPlace:put:response'),
      map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }

  deleteUserPlace(id:number) {
    return this.http.delete(environment.apiUrl + "/api/user_places/"+id,{ headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }
  geocodePlace(position:string){
    const params = new HttpParams().set('place',position);
    return this.http.get(environment.apiUrl + "/api/user_places/geocode_place",{ headers: this.getHeaders(), params: params}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'UserPlaceSvc::geocodePlace:place:response'),
        // map(res =>  res["data"]),
      );
  }
  reverseGeocode(latitude:string,longitude:string){
    const params = new HttpParams().set('latitude',latitude).set('longitude',longitude);
    return this.http.get(environment.apiUrl + "/api/user_places/reverse_geocode_point",{ headers: this.getHeaders(), params: params}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'UserPlaceSvc::reverseGeocode:place:response'),
        // map(res =>  res["data"]),
      );
  }
}
