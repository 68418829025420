import { Injectable } from '@angular/core';
import { Observable,of,BehaviorSubject, Subject } from 'rxjs';
import { LocationWrapper } from '../api/location';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { GoogleAnalyticsService ,GaActionEnum} from 'ngx-google-analytics';
import { UserService } from '../api/user.service';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UiStateService {
  public showMapSubject= new Subject<boolean>;
  public locationsSubject= new Subject<LocationWrapper[]>;
  public showMap$ = this.showMapSubject.asObservable();
  public locations$ = this.locationsSubject.asObservable();
  private showMap:boolean=false;
  private refreshMap:boolean=false;
  public refreshMapSubject= new Subject<boolean>;
  public refreshMap$ = this.refreshMapSubject.asObservable();
  private trackList=[];
  userSvc:UserService;
  private isGAsend:boolean=false;

  constructor(
    private usrSvc: UserService,
    private $gaService: GoogleAnalyticsService
  ) {
    // this.showMap= new BehaviorSubject(false);
    this.userSvc =usrSvc;
    this.showMapSubject.next(this.showMap);
    this.locationsSubject.next([]);
    this.refreshMapSubject.next(this.refreshMap);

  }

  getShowMapState(): Observable<boolean> {
    return this.showMap$;
  }
  getShowMap(): boolean {
    return this.showMap;
  }
  getLocations(): Observable<LocationWrapper[]> {
    return this.locations$;
  }
  // getShowMapState(): Observable<any> {
  //   return this.showMap$
  // }
  setRefreshMapIfOpen(state:boolean){
    if(this.showMap){
      debuglog(RxJsLoggingLevel.DEBUG,'ui-state::setRefreshMapIfOpen: ',state);
      this.refreshMapSubject.next(state);
      this.refreshMap=state;
    }
  }
  setLocations(locs:LocationWrapper[]){
    debuglog(RxJsLoggingLevel.DEBUG,'ui-state::setLocations: ',locs);
    this.locationsSubject.next(locs);
  }
  setShowMap(state:boolean){
    this.showMapSubject.next(state);
    this.showMap=state;
  }
  toggleShowMap():boolean{
    debuglog(RxJsLoggingLevel.DEBUG,'ui-state::toggleShowMap: entered');
    let state=this.showMap;
    debuglog(RxJsLoggingLevel.DEBUG,'ui-state::toggleShowMap: current',state);
    this.showMapSubject.next(!state);
    this.showMap=!state;
    return this.showMap;
    debuglog(RxJsLoggingLevel.DEBUG,'ui-state::toggleShowMap: next',!state);

  }

  trackInteraction(action: GaActionEnum | string, category?: string, label?: string, value?: number, interaction?: boolean, options?: Object){
    this.trackList.push({action:action,category:category,label:label, value:value, interaction:interaction, options:options});
    this.sendTracks();

  }

  trackPage(path:  string, title?: string, location?: string,  options?: Object){
    this.trackList.push({path:path,title:title,location:location, options:options});
    debuglog(RxJsLoggingLevel.TRACE,'ga call page trak ',{path:path,title:title,location:location, options:options});
    this.sendTracks();

  }

  async sendTracks(){
    // while(this.trackList.length){
      debuglog(RxJsLoggingLevel.TRACE,'ga call enter ',this.trackList.length,this.isGAsend);
      if(!this.isGAsend){
        this.isGAsend=true;
        // let track=this.trackList.shift();
        if(this.userSvc.me == undefined){
          let userSub=this.userSvc.myRoles$.subscribe(async results=>{
            // this.tagTrack(track.options);
            // // environment.appUrl+
            // if(track.path !== undefined){
            //   this.$gaService.pageView(track.path, track.title, track.location, track.options);
            //   debuglog(RxJsLoggingLevel.DEBUG,'ga sub call send page ',track);
            // };
            // if(track.action !== undefined){
            //   this.$gaService.event(track.action, track.category, track.label, track.value,track.interaction, track.options);
            //   debuglog(RxJsLoggingLevel.DEBUG,'ga sub call send event ',track);
            // };
            //

            // debuglog(RxJsLoggingLevel.DEBUG,'ga sub call ',track,this.isGAsend);

            debuglog(RxJsLoggingLevel.TRACE,'ga sub call ',this.isGAsend);
            if (userSub !==null)userSub.unsubscribe();
            await sleep(2000);
            this.isGAsend=false;
            if(this.trackList.length>0){

              debuglog(RxJsLoggingLevel.TRACE,'ga sub call newNext ',this.isGAsend);
              this.sendTracks();
            } else {
              this.isGAsend=false;
            }
          });
        }else{
          let track=this.trackList.shift();
          this.tagTrack(track.options);
          if(track.path !== undefined){
            this.$gaService.pageView(track.path, track.title, track.location, track.options);
            debuglog(RxJsLoggingLevel.TRACE,'ga call send page ',track);
          };
          if(track.action !== undefined){
            this.$gaService.event(track.action, track.category, track.label, track.value,track.interaction, track.options);
            debuglog(RxJsLoggingLevel.TRACE,'ga call send event ',track);
          };

          debuglog(RxJsLoggingLevel.DEBUG,'ga call ',track,this.isGAsend);
          await sleep(5000);
          this.isGAsend=false;
          if(this.trackList.length>0){
            this.sendTracks();
          } else {
            this.isGAsend=false;
          }
        };

      }

    // }
  }

  tagTrack(options?:any):Object{
    let userState:string='anon';
    if(this.userSvc.checkAuthorization(['verified'])){
      userState='verified';
    }else if(this.userSvc.checkAuthorization(['registered'])){
      userState='registered';
    };
    if(options !== undefined){
      options.uid=this.userSvc.me.uid;
      options.ustate=userState;
      options.ver=environment.version;

    }else {
      options={
        uid: this.userSvc.me.uid,
        ver:environment.version,
        ustate:userState,
      };
    };
    return options;
  }

}
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
