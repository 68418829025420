import { Component,Input, } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { UserService } from '../api/user.service';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UiStateService } from '../core/ui-state.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent {
	email:string='';
	password:string='';
	myRoles:any=[];
  loginForm: FormGroup;
  errorMessage: string = '';
  @Input() hideLink!:boolean;
  constructor(
    public auth: AuthService,
    private userSvc:UserService,
    private router: Router,
    private fb: FormBuilder,
    private uiStateSvc:UiStateService,
    ) {
    debuglog(RxJsLoggingLevel.DEBUG,'user.profile::constructor: auth',this.auth, this.auth.authenticated);
    this.createForm();
   }

  ngOnInit():void{
    debuglog(RxJsLoggingLevel.DEBUG,'user.profile::init: auth',this.auth, this.auth.authenticated);
  }

 googleLogin():void{
		this.auth.loginGoogle().then(this.auth.upgradeUserData).catch(e=>{
				debuglog(RxJsLoggingLevel.DEBUG,'user.profile::googleLogin event',e);
			if(e.code=="auth/credential-already-in-use"){
				this.auth.signInWithCredential(e.credential).then(user=> {
					debuglog(RxJsLoggingLevel.DEBUG,"user-profile::googleLogin:Sign In Success", user);
          this.uiStateSvc.trackInteraction('signin_google', 'user',this.userSvc.me.uid,undefined,undefined, {
          });
					this.userSvc.myRoles$.subscribe(results=>{
						debuglog(RxJsLoggingLevel.DEBUG,'user-profile::googleLogin: myRole',results);
						this.myRoles= results;
					});
				  });
			}
		});
 }
 facebookLogin():void{
		this.auth.loginFacebook().then((data:any)=>{this.auth.upgradeUserData(data);debuglog(RxJsLoggingLevel.DEBUG,"user-profile::Anonymous account successfully FB upgraded", data.user);});
 }
 twitterLogin():void{
		this.auth.loginTwitter().then((data:any)=>{this.auth.upgradeUserData(data);debuglog(RxJsLoggingLevel.DEBUG,"user-profile::Anonymous account successfully twitter upgraded", data.user);});
 }
 emailLogin():void{
		this.auth.loginEmailPass(this.email,this.password);
 }
 annonymousLogin():void{this.auth.anonymousLogin()}

 // new code
 createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['',Validators.required]
    });
  }

  tryFacebookLogin(){
    this.auth.loginFacebook()
    .then(res => {
      this.uiStateSvc.trackInteraction('signin_google', 'user',this.userSvc.me.uid,undefined,undefined, {
      });
      // this.router.navigate(['/user']);
    })
  }

  tryTwitterLogin(){
    this.auth.loginTwitter()
    .then(res => {
      // this.router.navigate(['/user']);
    })
  }



  tryLogin(value){
    this.auth.loginEmailPass(value.email, value.password)
    .then(res => {
      this.uiStateSvc.trackInteraction('signin_emailpass', 'user',this.userSvc.me.uid,undefined,undefined, {
      });
      this.router.navigate(['/user']);
    }, err => {
      debuglog(RxJsLoggingLevel.DEBUG,'user-profile::tryLogin err',err);
      this.errorMessage = err.message;
    })
  }


}
