import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef,MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MyPositionService,MyPositionData } from '../core/my-position.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { UserPlaceService } from '../api/user-place.service';
import { UserPlace } from '../api/user-place';
// import * as _ from 'lodash';

@Component({
  selector: 'set-user-location-dialog',
  templateUrl: './set-user-location-dialog.component.html',
  styleUrls: ['./set-user-location-dialog.component.css']
})
export class SetUserLocationDialog implements OnInit {
  userPlaceFrm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<SetUserLocationDialog>,
    @Inject(MAT_DIALOG_DATA) public data:  any,
    private fb: FormBuilder,
    private userPlaceSvc: UserPlaceService ) {}

  save(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'usr-place-dialog::save: data',this.userPlaceFrm.value);
    let newPlace=  {
      nickname:this.userPlaceFrm.value.label,
      address:this.userPlaceFrm.value.place,
      longitude:this.data['userPlaces'].longitude,
      latitude:this.data['userPlaces'].latitude
    };
    this.userPlaceSvc.insertUserPlace(newPlace).subscribe(userPlace => {
      this.data['userPlaces'].push(userPlace);
      this.dialogRef.close();
    });
  }
  updateUP(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'usr-place-dialog::upddateUP: data',this.userPlaceFrm.value,this.data['userPlace'].attributes.id);
    this.userPlaceSvc.updateUserPlace(this.userPlaceFrm.value,this.data['userPlace'].attributes.id ).subscribe(userPlace => {
      let userPlaces=this.data['userPlaces'];
      let upIn=this.data['userPlace'];
      debuglog(RxJsLoggingLevel.DEBUG,'usr-place-dialog::upddateUP: aftrupdate',userPlace);

      this.data['userPlaces'].map(function(up,i){
        if(upIn.attributes.id ==up.attributes.id){
          debuglog(RxJsLoggingLevel.DEBUG,'usr-place-dialog::upddateUP:in loop up ',i, up );

          up.attributes.label=userPlace.attributes.label;
          up.attributes.place=userPlace.attributes.place;
        }
        return up;
      });
      this.dialogRef.close(this.data['userPlaces']);
    });
  }
  deleteUP(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'usr-place-dialog::deleteUP: data',this.data['userPlace'].attributes.id);
    this.userPlaceSvc.deleteUserPlace(this.data['userPlace'].attributes.id).subscribe(userPlace => {
      const newList= this.data['userPlaces'].filter(userPlace => userPlace.attributes.id!==this.data['userPlace'].attributes.id);
      this.dialogRef.close(newList);
    });
  }
  cancel(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'usr-place-dialog::cancel: data',this.userPlaceFrm.value);
    this.dialogRef.close();

  }
  ngOnInit() {
    debuglog(RxJsLoggingLevel.DEBUG,'usr-place-dialog::init: data',this.data);
    if(this.data['position']){
      this.userPlaceFrm = this.fb.group({
        'label' : [this.data['position'].label, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'place' : [this.data['position'].place, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],

      });
    } else {
      // TODO can be removed or refactored
      this.userPlaceFrm = this.fb.group({
        'label' : [this.data['userPlace'].attributes.label, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'place' : [this.data['userPlace'].attributes.place, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],

      });
    }
  }

}
