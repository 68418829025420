<a [routerLink]="['/location-crud/'+locationId]">
  Back
</a>
<div class="location-container">
  <form [formGroup]="doorFrm"  *ngIf="doorFrm">
    <!-- (ngSubmit)="addDoor(locationId,id,doorFrm.value)" -->
    <div class="row expanded">
      <div  layout="column" layout-align="center end">
        <div>
          <label>Name
            <input type="text" name="name" [formControl]="doorFrm.controls['name']" formControlName="name">
          </label>
          <div class="alert" *ngIf="!doorFrm.controls['name'].valid && doorFrm.controls['name'].touched">
            You must specify a name that's at least 10 characters long.
          </div>
        </div>
        <div>
          <label for="flow_to_id">To Where:</label>
          <select formControlName="flow_to_id" class="form-control">
            <option disabled>Select Room</option>
            <option [ngValue]="null" >Choose Room</option>
            <option *ngFor="let room of rooms" [ngValue]="room.attributes.id">{{room.attributes.name}}</option>
          </select>

          <!-- <div *ngIf="f.flow_to_id.touched && f.flow_to_id.invalid" class="alert alert-danger">

              <div *ngIf="f.website.errors.required">To Where is required.</div>

          </div> -->

      </div>

        <div>
          Internal Door <mat-slide-toggle formControlName="is_external" [checked]="doorFrm.get('is_external').value" (change)="onIsExternalChange($event)">External Door</mat-slide-toggle>
        </div>
        <div  *ngIf="!doorFrm.get('is_external').value">
          <label for="flow_to_id">From Where:</label>
          <select formControlName="flow_from_id" class="form-control">
            <option disabled>Select Room</option>
            <option [ngValue]="null" >Choose Room</option>
            <option *ngFor="let room of rooms" [ngValue]="room.attributes.id">{{room.attributes.name}}</option>
          </select>
        </div>
        <div>
          <label>Sensor Label
            <input type="text" name="sensor_id" [formControl]="doorFrm.controls['sensor_id']" formControlName="sensor_id" >
          </label>
          <div class="alert" *ngIf="!doorFrm.controls['sensor_id'].valid && doorFrm.controls['sensor_id'].touched">
            You must specify a name that's at least 10 characters long.
          </div>
        </div>

        <div>
           Sensor Push <mat-slide-toggle formControlName="is_polled" [checked]="doorFrm.get('is_polled').value" (change)="onIsPollingChange($event)">Poll Sensor</mat-slide-toggle>
        </div>
        <div  *ngIf="doorFrm.get('is_polled').value; else elseBlockPushInfo">
          <div>
            <label>Sensor Polling Url
              <input type="text" name="external_poll_url" [formControl]="doorFrm.controls['external_poll_url']" formControlName="external_poll_url">
            </label>
            <div class="alert" *ngIf="doorFrm.controls['external_poll_url'].value &&!doorFrm.controls['external_poll_url'].valid && doorFrm.controls['external_poll_url'].touched">
              You must specify a properly formated Sensor polling url.
            </div>
          </div>
          <div>
            <label>Sensor API Key
              <input type="text" name="external_api_key" [formControl]="doorFrm.controls['external_api_key']" formControlName="external_api_key">
            </label>
            <div class="alert" *ngIf="!doorFrm.controls['external_api_key'].valid && doorFrm.controls['external_api_key'].touched">
              You must specify a name that's at least 10 characters long.
            </div>
          </div>

        </div>
        <ng-template #elseBlockPushInfo>
          <div>
          <label *ngIf="door; else elseBlockNeedDoor">{{env.apiUrl}}/api/doormsgs/{{door.sensor_id}}<br />
            APIKEY: {{door.location.api_key}}
          </label>
          <ng-template #elseBlockNeedDoor>
            <label>Need to provide initial configuration info including Unique Sensor ID and Save. You can then complete Door Sensor configuration.
            </label>
          </ng-template>
          </div>
        </ng-template>
        <app-sensor-field-mapper></app-sensor-field-mapper>
        <div *ngIf="userSvc.checkAuthorization(['superadmin','admin']); else elseBlockCurrentState">
          <label>Current Net Traffic
            <input type="text" name="current_state" [formControl]="doorFrm.controls['current_state']" formControlName="current_state">
          </label>
          <div class="alert" *ngIf="!doorFrm.controls['current_state'].valid && doorFrm.controls['current_state'].touched">
            You must specify an Integer equal to or greater than 0.
          </div>

        </div>
        <ng-template #elseBlockCurrentState>
          <label>Current Net Traffic: {{doorFrm.get('current_state').value}}

          </label>
        </ng-template>








        <input type="submit" class="cta-btn filled" value="Save" (click)="addDoor(locationId,id,doorFrm.value)">
        <!-- [disabled]="!doorFrm.valid" -->

      </div>
    </div>
  </form>
</div>
