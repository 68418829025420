<mat-card>
  <mat-card-actions>
    <div class="mat-elevation-z8">User management
      <!-- add input to filter -->
      <input #searchInput placeholder="filter test">
      <button mat-icon-button  (click)="search()"><mat-icon>search</mat-icon></button>
      <!-- add button for add new location -->
      <button mat-icon-button  routerLink="/location-crud"><mat-icon>add</mat-icon></button>
    </div>

  </mat-card-actions>
  <mat-card-content>
    <div class="mat-elevation-z8">
      <div class="spinner-container" *ngIf="loading$ | async">
        <mat-spinner></mat-spinner>
      </div>
      <!-- TODO use ngTemplate to swap in message to search first -->
      <table mat-table [dataSource]="dataSourceMat" >

        <!-- Position Column -->
        <!-- <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name </th>
          <td mat-cell *matCellDef="let element"> {{element.attributes.name}}
            <br><small>{{element.attributes.address}}</small> </td>
        </ng-container> -->

        <!-- Name Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>email </th>
          <td mat-cell *matCellDef="let element"> {{element.attributes.email}} </td>
        </ng-container>



        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element" class="button-container">
            <button mat-icon-button  routerLink="/user-crud/{{ element.attributes.id }}"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button  (click)="deleteUser(element.attributes.id)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [length]="length"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageChanged($event)"
              showFirstLastButtons>
      </mat-paginator>
    </div>

  </mat-card-content>

</mat-card>
