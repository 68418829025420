import { Injectable } from '@angular/core';

import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { map,tap } from 'rxjs/operators';
import { Alert } from './alert';
import { Observable,of } from 'rxjs';
import { switchMap} from 'rxjs/operators';

import { AuthService} from '../core/auth.service';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  result:any;

  constructor(private http: HttpClient,
    private authSvc: AuthService) { }

  getHeaders():HttpHeaders{
    return new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + this.authSvc.currentUserJWT
    });
  }

  getAlerts() {
    return this.http.get(environment.apiUrl + "/api/alerts",{ headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'AlertSvc::getAlerts:get:response'),
      map(res =>  res["data"]),
    )
  }

  getAlertsByUser(criteria){
    const params = new HttpParams().set('filter', criteria['filter'])
    // .set('sortOrder', criteria['sortOrder'])
    .set('pageIndex', criteria['pageIndex'].toString())
    .set('pageSize', criteria['pageSize'].toString())
    .set('location_id', criteria['locationId']!==null?criteria['locationId'].toString():'');
    // this.lastSearchParams=criteria;
    debuglog(RxJsLoggingLevel.DEBUG,'getAlertsByUser::criteria ',criteria);
    debuglog(RxJsLoggingLevel.DEBUG,'getAlertsByUser::params ',params);
    debuglog(RxJsLoggingLevel.DEBUG,'getAlertsByUser::params keys ',Object.keys(criteria));
    return this.http.get(environment.apiUrl + "/api/alerts/manage",{ headers: this.getHeaders(), params: params}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'AlertSvc::getAlertsByUser:manage:response'),
      // map(res =>  res["data"]),
    )
  }

  getAlert(id:number) {
    return this.http.get(environment.apiUrl + "/api/alerts/"+id,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'AlertSvc::getAlert:loc:response'),
        map(res =>  res["data"]),
      );
      // .subscribe(result => this.result = result.json());
  }

  getAlertFull(id:number) {
    return this.http.get(environment.apiUrl + "/api/alert/full/"+id,{ headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'AlertSvc::loadView:full:response'),
      map(res =>  res["data"]),
  );
      // .subscribe(result => this.result = result.json());
  }

  insertAlert(post: Alert) {

    return this.http.post(environment.apiUrl + '/api/alerts', JSON.stringify(post), {headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  // redeming alerts== alert redemption call
  redeem(post: any) {

    return this.http.post(environment.apiUrl + '/api/alert_redemptions', JSON.stringify(post), {headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  updateAlert(post: Alert, id:number) {
    return this.http.put(environment.apiUrl + '/api/alerts/'+id, JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'AlertSvc::updateAlert:put:response'),
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }

  deleteAlert(id:number) {
    return this.http.delete(environment.apiUrl + "/api/alerts/"+id,{ headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  getAlertStats(id:number) {
    return this.http.get(environment.apiUrl + "/api/alerts/stats/"+id,{ headers: this.getHeaders()}).pipe(
      debug(RxJsLoggingLevel.DEBUG, 'AlertSvc::stats:response'),
      // map(res =>  res["data"]),
      // shareReplay(1)
    );
      // .subscribe(result => this.result = result.json());
  }
}
