<a [routerLink]="['/location/'+locationId+'/room-crud/'+roomId]">
  Back
</a>
<div class="location-container">
  <form [formGroup]="roomSensorFrm" (ngSubmit)="addRoomSensor(locationId,roomId,id,roomSensorFrm.value)" *ngIf="roomSensorFrm">
    <div class="row expanded">
      <div  layout="column" layout-align="center end">
        <div>
          <label>Name
            <input type="text" name="name" [formControl]="roomSensorFrm.controls['name']" formControlName="name">
          </label>
          <div class="alert" *ngIf="!roomSensorFrm.controls['name'].valid && roomSensorFrm.controls['name'].touched">
            You must specify a name that's at least 10 characters long.
          </div>
        </div>



        <div>
          <label>Sensor Label
            <input type="text" name="sensor_id" [formControl]="roomSensorFrm.controls['sensor_id']" formControlName="sensor_id" >
          </label>
          <div class="alert" *ngIf="!roomSensorFrm.controls['sensor_id'].valid && roomSensorFrm.controls['sensor_id'].touched">
            You must specify a name that's at least 10 characters long.
          </div>
        </div>


        <div>
          <mat-slide-toggle formControlName="is_polled" [checked]="roomSensorFrm.get('is_polled').value" (change)="onIsPollingChange($event)">Poll Sensor (vs Sensor Push)</mat-slide-toggle>
        </div>
        <div  *ngIf="roomSensorFrm.get('is_polled').value; else elseBlockPushInfo">
          <div>
            <label>Sensor Polling Url
              <input type="text" name="external_poll_url" [formControl]="roomSensorFrm.controls['external_poll_url']" formControlName="external_poll_url">
            </label>
            <div class="alert" *ngIf="roomSensorFrm.controls['external_poll_url'].value &&!roomSensorFrm.controls['external_poll_url'].valid && roomSensorFrm.controls['external_poll_url'].touched">
              You must specify a properly formated Sensor polling url.
            </div>
          </div>
          <div>
            <label>Sensor API Key
              <input type="text" name="external_api_key" [formControl]="roomSensorFrm.controls['external_api_key']" formControlName="external_api_key">
            </label>
            <div class="alert" *ngIf="!roomSensorFrm.controls['external_api_key'].valid && roomSensorFrm.controls['external_api_key'].touched">
              You must specify a name that's at least 10 characters long.
            </div>
          </div>
        </div>

        <ng-template #elseBlockPushInfo>
          <div>
          <label *ngIf="roomSensor; else elseBlockNeedRoomSensor">http://127.0.0.1:3000/api/room_sensor_msgs/{{room_sensor.sensor_id}}<br />
            APIKEY:
          </label>
          <ng-template #elseBlockNeedRoomSensor>
            <label>Need to provide Unique Sensor ID
            </label>
          </ng-template>
          </div>
        </ng-template>
        <app-sensor-field-mapper></app-sensor-field-mapper>
        <div *ngIf="userSvc.checkAuthorization(['superadmin','admin']); else elseBlockCurrentState">
          <label>Current Occupancy
            <input type="text" name="current_state" [formControl]="roomSensorFrm.controls['current_state']" formControlName="current_state">
          </label>
          <div class="alert" *ngIf="!roomSensorFrm.controls['current_state'].valid && roomSensorFrm.controls['current_state'].touched">
            You must specify an Integer equal to or greater than 0.
          </div>

        </div>
        <ng-template #elseBlockCurrentState>
          <label>Current Occupancy: {{roomSensorFrm.get('current_state').value}}

          </label>
        </ng-template>

        <input type="submit" class="cta-btn filled" value="Save" >
        <!-- [disabled]="!roomSensorFrm.valid" -->

      </div>
    </div>
  </form>
</div>
