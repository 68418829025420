import { Component, Input, OnInit, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { LocationService } from '../api/location.service';
import { Observable, of, fromEvent } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, finalize, catchError } from 'rxjs/operators';
import { PageEvent,  MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../api/user.service';
import { User,TeamInvite,TeamInviteWrapper, TeamMember } from '../core/user';
import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-join-team',
  templateUrl: './join-team.component.html',
  styleUrls: ['./join-team.component.css']
})
export class JoinTeamComponent {
  @ViewChild('link_id', { read: false, static: false }) input: ElementRef;
  linkId:string;
  teamEntry:TeamMember;
  isExpired:boolean=false;
  isWrongEmail:boolean=false;
  showInput:boolean=false;
  showWelcome:boolean=false;
  statusMsg:string='';
  showStatus:boolean=false;
  inviteDummy:any={

    linkId : '',

  };
  joinFrm: FormGroup;
  constructor(
    public auth: AuthService,
    private userSvc: UserService,
    private locationSvc: LocationService,
    private aR: ActivatedRoute,
    private changeDetectorRef:ChangeDetectorRef,
    private fb: FormBuilder,
  ) {


  }
  ngOnInit() {
    this.linkId = this.aR.snapshot.params['link_id'];
    debuglog(RxJsLoggingLevel.DEBUG,'join-team::init: linkId',this.linkId);
    this.setupFormGroup();
    if(this.linkId){
      this.userSvc.getMe().subscribe(me=>{
        debuglog(RxJsLoggingLevel.DEBUG,'join-team::init: me loaded',me);
        if(me.is_anonymous && me.email== me.uid+'@firebase.com'){
          this.statusMsg='You need to be a registered user to join a team.';
          this.showStatus=true;
        }else{
          // this.showInput=true;
        }
      })
    }else{
      this.showInput=true;
    }
  }
  public setupFormGroup(){

      this.joinFrm = this.fb.group({
        'link_id' : ['', Validators.compose([Validators.required, Validators.minLength(15), Validators.maxLength(50)])],
      });


  }
  checkCode(linkObj:any){
    this.linkId=linkObj.link_id;
    this.getInvite(this.linkId);
  }
  isAnonymous(){
    return this.userSvc.me.is_anonymous;
  }
  getInvite(linkId:string){
    this.locationSvc.getTeamInvitePublic(this.linkId)
      .pipe(first())
      .subscribe(x => {
        if(x.status){
          this.teamEntry=x.msg.data.attributes;
          debuglog(RxJsLoggingLevel.DEBUG,'joinTeam-crud::getInvite: teamInvite',this.teamEntry);
          // if(this.teamInvite.is_complete) this.isExpired = true;
          // if(this.teamInvite.email !== this.userSvc.me.email) this.isWrongEmail=true;
          this.showWelcome=true;
          this.showInput=false;
        }else{
          // isBadInvite isWrongEmail isExpired
          this.statusMsg=x.msg;
          switch(x.msg){
            case 'isBadInvite':
              this.statusMsg='Apologies, that invite is not recognized';
              break;

            case 'isWrongEmail':
              this.statusMsg='There was a problem processing the invite.  Confirm you are logged in as the right user.';
              break;

            case 'isExpired':
              this.statusMsg='This invite seems to have already been processed.';
              break;

            default:
              break;

          }
          this.showInput=this.showStatus=true;
        }

        // this.teamInviteFrm.patchValue(x.attributes);

      })
  }

}
