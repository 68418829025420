import { Injectable } from '@angular/core';

import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { map,catchError , } from 'rxjs/operators';
import { UserLocationFav,UserLocationFavWrapper } from './user-location-fav';
import { Observable,of } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { LocationList} from './location-list';
import { AuthService} from '../core/auth.service';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserLocationFavService {
  result:any;

  constructor(private http: HttpClient,
  private authSvc: AuthService) {

  }
  getHeaders():HttpHeaders{
    return new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + this.authSvc.currentUserJWT
    });
  }
  getUserFavorites() {
    return this.http.get<UserLocationFavWrapper[]>(environment.apiUrl + "/api/user_location_favs/", {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'UserLocFav::getUserFavorites:response'),
      map(res =>  res["data"]),
  );
  }
  addFavorite(locationId:Number){

    return this.http.post<UserLocationFav[]>(environment.apiUrl + "/api/user_location_favs/", {location_id:locationId} ,{ headers: this.getHeaders() }).pipe(
        debug(RxJsLoggingLevel.DEBUG, 'UserLocFav::getUserFavorites:response'),
        map(res =>  res["data"]),
    );
  }
  deleteFavorite(favId: Number){
    const url = environment.apiUrl + `/api/user_location_favs/${favId}`;
    return this.http.delete<UserLocationFav[]>(url,{ headers: this.getHeaders()}).pipe(
        catchError(debug(RxJsLoggingLevel.DEBUG, 'UserLocFav::deleteFavorite:ERROR')),
        debug(RxJsLoggingLevel.DEBUG, 'UserLocFav::deleteFavorite:response'),
        map(res =>  res["data"]),
    );
  }

}
