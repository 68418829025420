import { Component, Input, OnInit, ElementRef, } from '@angular/core';
// import { FormGroup, FormControl } from '@angular/forms';
import { User } from '../core/user';
import { AuthService } from '../core/auth.service';
// import { Observable, of, fromEvent } from 'rxjs';
// import { tap, debounceTime, distinctUntilChanged, finalize, catchError } from 'rxjs/operators';
import { PageEvent,  MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
// import { MatProgressSpinner } from '@angular/material/progress-spinner';
// import { MatInput } from '@angular/material/input';
import { ViewChild } from '@angular/core';
// import { MyPositionService,MyPositionData } from '../core/my-position.service';

import { UserService } from '../api/user.service';
import { Door } from '../api/location';
import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import {MatDialog, } from '@angular/material/dialog';
import { DoorDeleteComponent } from '../door-delete/door-delete.component';

@Component({
  selector: 'app-door-manage',
  templateUrl: './door-manage.component.html',
  styleUrls: ['./door-manage.component.css'],
  inputs:['doors']
})
export class DoorManageComponent implements OnInit {


  myRoles:any;
  user: User| null | undefined;
  @Input() locationId!:number;
  @Input() doors!:Array<Door>;


  // for table and paging of table
  displayedColumns: string[] = ['name', 'is_external', 'flow_from','flow_to','actions'];
  @Input() dsDoors: MatTableDataSource<Door>;


  @ViewChild(MatPaginator, { read: false, static: false }) paginator: MatPaginator;
  @ViewChild('input', { read: false, static: false }) input: ElementRef;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20];

  // MatPaginator Output
  pageEvent: PageEvent;


  constructor(
    public auth: AuthService,
    public dialog: MatDialog ,

    private userSvc: UserService,
    // private myPositionSvc: MyPositionService,

  ) { }

  ngOnInit() {
    // to lockdown particular functionality in Component

    // this.auth.user$.subscribe(user => {this.user=user;
    //   this.userSvc.myRoles$.subscribe(results=>{
    //     debuglog(RxJsLoggingLevel.DEBUG,'door-manage::init: myRole',results);
    //     this.myRoles= results;
    //   });
    //
    // })
    // debuglog(RxJsLoggingLevel.DEBUG,'door-manage::init: doors',this.doors);
    this.user=this.auth.user;
    this.myRoles= this.userSvc.myRoles;

  }

  deleteDoor(door:Door):void{
    const dialogRef = this.dialog.open(DoorDeleteComponent, {
      width: '250px',
      data: {door: door, locationId: this.locationId}
    });

    dialogRef.afterClosed().subscribe(result => {

      debuglog(RxJsLoggingLevel.DEBUG, 'door-manage:DoorDeleteComponent::afterClosed:result: ',result);

    });
  }

  onRowClicked(row: number) {
    debuglog(RxJsLoggingLevel.DEBUG, 'Row clicked: ', row);
  }

}
