import { Directive, ElementRef, Input  } from '@angular/core';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';


@Directive({
  selector: '[appHeatcolor]'
})
export class HeatcolorDirective {

  element:ElementRef;
  constructor(el: ElementRef ) {
    this.element =el;
  }
  ngOnInit() {
    debuglog(RxJsLoggingLevel.TRACE,  'HeatcolorDirective',this.color);
    this.element.nativeElement.style.backgroundColor = this.color;
  }
  @Input('appHeatcolor') color : string;
  ngOnChanges() {
    debuglog(RxJsLoggingLevel.TRACE, 'HeatcolorDirective updated',this.color);
    this.element.nativeElement.style.backgroundColor = this.color;
  }
  // private heatcolor(color: string) {
  //   this.el.nativeElement.style.backgroundColor = color ;
  // }
}
