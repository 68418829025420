import { Component, OnInit,AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../api/location.service';
// import { BehaviorSubject } from 'rxjs';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {RoomSensor, } from '../api/location';
import { AuthService } from '../core/auth.service';
import { first } from 'rxjs/operators';

import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
// import { MatTableDataSource } from '@angular/material';
import { ViewChild } from '@angular/core';
import { SensorFieldMapperComponent } from '../core/sensor-field-mapper/sensor-field-mapper.component';

@Component({
  selector: 'app-room-sensor-crud',
  templateUrl: './room-sensor-crud.component.html',
  styleUrls: ['./room-sensor-crud.component.css']
})
export class RoomSensorCrudComponent implements OnInit,AfterViewInit {
  roomSensor: any;

  id:number;
  locationId:number;
  roomId:number;
  isAddMode: boolean;
  IsPolling:boolean=false;

  roomSensorDummy:RoomSensor={
    name: '',
    current_state: 0,
    sensor_id: 'sensorXXXXX',
    is_polled: true,
    external_api_key:'',
    external_poll_url:'',
    serialized_sensor_field_map: '',
    id: undefined,
    room_id: undefined,
  };
  roomSensorFrm: FormGroup;



  loadedRoomSensor:any;
  // dsRoomSensorMsgs: MatTableDataSource<RoomSensor>;
  @ViewChild(SensorFieldMapperComponent, { static: false}) mapper: SensorFieldMapperComponent;



  constructor(
    public auth: AuthService,
    public userSvc: UserService,
    private locationSvc: LocationService,
    private router: Router,
    private aR: ActivatedRoute,
    private fb: FormBuilder,
    private changeDetectorRef:ChangeDetectorRef,
  ) {
    // this.dsRoomSensorMsgs = new MatTableDataSource<RoomSensor>();
    // this.dsDoors = new MatTableDataSource<Door>();
   }

   ngAfterViewInit(){
     if (!this.isAddMode) {

           debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-crud::init: editMode mapper',this.mapper);

           this.mapper.setFieldMap(JSON.parse(this.roomSensor.serialized_sensor_field_map));

     }else{
       debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-crud::init: addMode mapper',this.mapper);
       this.mapper.setFieldMap(null);
     }
   }

  ngOnInit() {
    this.userSvc.refreshMe();
    this.id = +this.aR.snapshot.params['id'];
    this.roomId = +this.aR.snapshot.params['room_id'];
    this.locationId = +this.aR.snapshot.params['location_id'];
    debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-crud::init: id',this.id);
    debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-crud::init: roomId',this.roomId);
    debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-crud::init: locationId',this.locationId);
    this.isAddMode = !this.id;
    this.setupFormGroup(this.roomSensorDummy);
    if (!this.isAddMode) {
      this.locationSvc.getRoomSensor(this.locationId,this.roomId,this.id)
        .pipe(first())
        .subscribe(x => {
          this.roomSensor=x.attributes;
          debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-crud::init: roomSensor',this.roomSensor);
          // this.rooms.push(...x.attributes.rooms);
          // this.dsRoomSensorMsgs.data = this.roomSensor.room_sensor_msgs;

          // this.doors.push(...x.attributes.doors);
          // this.dsDoors.data = this.doors;
          // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: doors',this.doors);
          // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: rooms',this.rooms);
          this.roomSensorFrm.patchValue(x.attributes);
          // this.mapper.setFieldMap(JSON.parse(x.attributes.serialized_sensor_field_map.length>0?x.attributes.serialized_sensor_field_map:null));
        });
    }else{
      // this.mapper.setFieldMap(null);
    }
  }
  public setupFormGroup(roomSensor){
    if(roomSensor!= null){
      this.roomSensorFrm = this.fb.group({
        'name' : [roomSensor['name'], Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'current_state' : [roomSensor['current_state'], Validators.compose([Validators.pattern('[0-9]*')])],
        'external_api_key' : [roomSensor['external_api_key'],],
        'serialized_sensor_field_map' : [roomSensor['serialized_sensor_field_map'],],
        'sensor_id' : [roomSensor['sensor_id'], Validators.compose([Validators.required, Validators.minLength(10)])],
        'is_polled' : [(roomSensor['is_polled']==null?false:roomSensor['is_polled']), Validators.compose([Validators.required])],
        'external_poll_url' : [roomSensor['external_poll_url'], Validators.compose([ Validators.pattern(urlPattern)])],
      });
    }else{
      this.roomSensorFrm = this.fb.group({
        'name' : [this.roomSensorDummy['name'], Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'current_state' : [this.roomSensorDummy['current_state'], Validators.compose([Validators.pattern('[0-9]*')])],
        'external_api_key' : [this.roomSensorDummy['external_api_key'],],
        'serialized_sensor_field_map' : [this.roomSensorDummy['serialized_sensor_field_map'],],
        'sensor_id' : [this.roomSensorDummy['sensor_id'], Validators.compose([Validators.required, Validators.minLength(10)])],
        'is_polled' : [(this.roomSensorDummy['is_polled']==null?false:this.roomSensorDummy['is_polled']), Validators.compose([Validators.required])],
        'external_poll_url' : [this.roomSensorDummy['external_poll_url'], Validators.compose([ Validators.pattern(urlPattern)])],
      });
    }

  }

  onIsPollingChange(event:any)
  {
    if (event.checked == true) {
      this.IsPolling = true;

      this.mapper.setFieldMap(JSON.parse(this.roomSensor.serialized_sensor_field_map));
    } else {
      this.IsPolling = false;
    }
  }
  addRoomSensor(locationId,roomId, roomSensorId, roomSensor: RoomSensor) {
    roomSensor.serialized_sensor_field_map=JSON.stringify(this.mapper.getFieldMap());
    debuglog(RxJsLoggingLevel.DEBUG, 'room-sensor-crud:addRoomSensor::door', roomSensor);

    if (roomSensorId !== undefined) {

      let sub=this.locationSvc.updateRoomSensor(roomSensor,locationId, roomId,roomSensorId)
        .subscribe(updateRoomSensor => {
          sub.unsubscribe();
          this.router.navigateByUrl('/location/'+locationId+'/room-crud/'+roomId);
        })
    } else {

      let sub=this.locationSvc.insertRoomSensor(roomSensor,locationId,roomId)
        .subscribe(newRoomSensor => {
          // this.locations.push(newLocation);
          sub.unsubscribe();
          this.router.navigateByUrl('/location/'+locationId+'/room-crud/'+roomId);
        })
    }
  }

}
const urlPattern= '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
