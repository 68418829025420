<table mat-table [dataSource]="dsRooms">

  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Room</th>
    <td mat-cell *matCellDef="let element"> {{element.name}}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="max_cap">
    <th mat-header-cell *matHeaderCellDef>Max Cap</th>
    <td mat-cell *matCellDef="let element"> {{element.max_cap}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="current_state">
    <th mat-header-cell *matHeaderCellDef>Current Occupancy</th>
    <td mat-cell *matCellDef="let element"> {{element.current_state}} </td>
  </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions <a [routerLink]="['/location/'+locationId+'/room-crud/']">
      <mat-icon>add</mat-icon>
    </a></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button  routerLink="/location/{{element.location_id}}/room-crud/{{ element.id }}"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button  (click)="deleteRoom(element.id)"><mat-icon>delete</mat-icon></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
