import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// pages
import { AppComponent } from './app.component';
import { LocationManageComponent } from './location-manage/location-manage.component';
import { LocationCrudComponent } from './location-crud/location-crud.component';
import { DoorCrudComponent } from './door-crud/door-crud.component';

import { RoomCrudComponent } from './room-crud/room-crud.component';
import { RoomSensorCrudComponent } from './room-sensor-crud/room-sensor-crud.component';
import { TeamInviteComponent } from './team-invite/team-invite.component';
import { TeamCrudComponent } from './team-crud/team-crud.component';

import { UserManageComponent } from './user-manage/user-manage.component';
import { UserCrudComponent } from './user-crud/user-crud.component';
import { AlertManageComponent } from './alert-manage/alert-manage.component';
import { AlertCrudComponent } from './alert-crud/alert-crud.component';
import { JoinTeamComponent } from './join-team/join-team.component';
import { AlertRedemptionComponent } from './alert-redemption/alert-redemption.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { LocationSearchComponent } from './location-search/location-search.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { AdminGuard } from './core/admin.guard';
import { LocationModifyGuard } from './core/location-modify.guard';
import { LoggedInGuard } from './core/logged-in.guard';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: '',  component: LocationSearchComponent, pathMatch: 'full'  },
  { path: '',  component: LocationSearchComponent },
  { path: 'home',  component: LocationSearchComponent },
  { path: 'location-manage',  component: LocationManageComponent,canActivate: [LocationModifyGuard] },
  { path: 'location-crud/:id',  component: LocationCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location-crud',  component: LocationCrudComponent,canActivate: [AdminGuard] },
  { path: 'location/:location_id/door-crud/:id',  component: DoorCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/room-crud/:id',  component: RoomCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/alert-crud',  component: AlertCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/alert-crud/:id',  component: AlertCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/door-crud',  component: DoorCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/room-crud',  component: RoomCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/room/:room_id/room-sensor-crud',  component: RoomSensorCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/room/:room_id/room-sensor-crud/:id',  component: RoomSensorCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/team-invite',  component: TeamInviteComponent,canActivate: [LocationModifyGuard] },
  { path: 'location/:location_id/team-crud/:id',  component: TeamCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'user-manage',  component: UserManageComponent,canActivate: [AdminGuard] },
  { path: 'user-crud/:id',  component: UserCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'user-crud',  component: UserCrudComponent,canActivate: [AdminGuard] },
  { path: 'alert-manage',  component: AlertManageComponent,canActivate: [AdminGuard] },
  { path: 'alert-crud/:id',  component: AlertCrudComponent,canActivate: [LocationModifyGuard] },
  { path: 'alert-crud',  component: AlertCrudComponent,canActivate: [AdminGuard] },
  { path: 'alert-redemption',  component: AlertRedemptionComponent,canActivate: [LocationModifyGuard] },
  { path: 'join-team/:link_id',  component: JoinTeamComponent },
  { path: 'join-team',  component: JoinTeamComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'user', component: UserProfileComponent},  // ,canActivate: [LoggedInGuard]
];

export const routing = RouterModule.forRoot(
  routes,
  { onSameUrlNavigation: 'reload' }
  // { enableTracing: true } // <-- debugging purposes only)
);
