<mat-card>
    <mat-card-actions>
      <div class="mat-elevation-z8">Alert management
        <!-- add input to filter -->
        <input #searchInput placeholder="filter test">
        <button mat-icon-button  (click)="search()"><mat-icon>search</mat-icon></button>
        <!-- add button for add new alert -->

        <button mat-icon-button  routerLink="{{backRoutePath}}"><mat-icon>add</mat-icon></button>
      </div>

    </mat-card-actions>
    <mat-card-content>
      <div class="mat-elevation-z8">
        <div class="spinner-container" *ngIf="loading$ | async">
          <mat-spinner></mat-spinner>
        </div>
        <!-- TODO use ngTemplate to swap in message to search first -->
        <table mat-table [dataSource]="dataSourceMat">
            <!-- ['msg', 'start', 'end','location','actions']; -->
          <!-- Position Column -->
          <ng-container matColumnDef="msg">
            <th mat-header-cell *matHeaderCellDef>message </th>
            <td mat-cell *matCellDef="let element"> {{element.attributes.msg}}
              <br><small *ngIf="element.attributes.location">{{element.attributes.location.name}}</small> </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef>Start </th>
            <td mat-cell *matCellDef="let element"> {{dateFormat(element.attributes.date_campaign_start)}} <br />
                {{dateFormat(element.attributes.date_special_start)}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef> End </th>
            <td mat-cell *matCellDef="let element"> {{dateFormat(element.attributes.date_end)}} </td>
          </ng-container>


          <!-- Action Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element" class="button-container">
              <button mat-icon-button  routerLink="{{ crudRoutePath+element.attributes.id }}"><mat-icon>edit</mat-icon></button>
              <button mat-icon-button  (click)="deleteAlert(element)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- <mat-paginator [length]="length"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChanged($event)"
                showFirstLastButtons>
        </mat-paginator> -->
        <div fxLayout="column" fxLayoutAlign="space-around center" >
          <!-- <mat-paginator
              appResponsivePaginator
              [length]="length"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageChanged($event)"
              showFirstLastButtons>
          </mat-paginator> -->
          <div  fxLayout="column" fxLayoutAlign="space-around center" *ngIf="alerts.length > 0" >
            <div layout="row"   layout-align="center center">
              <button [disabled]="pageIndex==0?true:false"  (click)="pageChanged(0)">&lt;&lt;</button>
              <button  [disabled]="pageIndex==0?true:false"  (click)="pageChanged(pageIndex-1)">&lt;</button>
              <!-- <pagination-controls
              (pageChange)="pageIndex= $event; pageChanged($event)"
              (pageBoundsCorrection)="pageChanged($event)"
              id="locs"></pagination-controls> -->
              {{pageIndex+1}}  of {{lastPage+1}}
              <button [disabled]="pageIndex==lastPage?true:false" (click)="pageChanged(pageIndex+1)">&gt;</button>
              <button  [disabled]="pageIndex==lastPage?true:false" (click)="pageChanged(lastPage)">&gt;&gt;</button>
            </div>
          </div>
          <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
          <div fxLayout="column" fxLayoutAlign="space-around center"  *ngIf="alerts.length > 0" >
            <div layout="row"   layout-align="center center">
              <mat-form-field appearance="fill">
                <mat-label>Items per page:</mat-label>

                <!-- style="width: 50px;margin-left: 15px" -->
                <mat-select [(value)]="pageSize" (valueChange)="pageIndex = 0;pageChanged(0)" >
                  <mat-option [value]="2">2</mat-option>
                  <mat-option [value]="5">5</mat-option>
                  <mat-option [value]="10">10</mat-option>
                  <mat-option [value]="50">50</mat-option>
                  <mat-option [value]="100">100</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

    </mat-card-content>

  </mat-card>
