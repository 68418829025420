import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService} from '../core/auth.service';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceLogService {

  constructor(private http: HttpClient,
    private authSvc: AuthService) {

  }
  getHeaders():HttpHeaders{
    return new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + this.authSvc.currentUserJWT
    });
  }
  addPushSubscriber(sub:any) {
      return this.http.post(environment.apiUrl +'/api/device_info', sub, {headers: this.getHeaders()});
  }
}
