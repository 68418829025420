import { Injectable } from '@angular/core';

import { Roles } from './user';
// https://github.com/HyperLife1119/ngx-socketio2#readme
// https://copyprogramming.com/howto/how-to-send-credentials-with-ngx-socket-io
import { UserService } from '../api/user.service';
import { Location } from '../api/location';
import { Alert } from '../api/alert';
import { debug,debuglog, RxJsLoggingLevel } from '../core/debug.operator';
// import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import { Socket } from 'ngx-socketio2';
import { map,tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// import {SwPush,} from "@angular/service-worker";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { DeviceLogService } from './device-log.service';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

@Injectable({
  providedIn: 'root',
  useFactory: ( userSvc: UserService,fireMessaging: AngularFireMessaging,deviceLogSvc: DeviceLogService) =>
      new RealtimeSocketService( userSvc,fireMessaging,deviceLogSvc),
  deps: [UserService,AngularFireMessaging,DeviceLogService],
})

export class RealtimeSocketService  extends Socket  {
  public locsSubject = new Subject<RealtimeMsgObj[]>();
  public locs$ = this.locsSubject.asObservable();
  public alertsSubject = new Subject<RealtimeMsgObj[]>();
  public alerts$ = this.alertsSubject.asObservable();
  sub: PushSubscription;

  readonly VAPID_PUBLIC_KEY = environment.firebase.vapidKey;

  constructor(
    // private  authSvc: AuthService,
    private userSvc:UserService,

    private fireMessaging: AngularFireMessaging,
    private deviceLogSvc:DeviceLogService
  ) {
    super({ url: userSvc.myRoles.realtime_server_url+'/?_rtUserId='+userSvc.myRoles.realtime_user_id+'&_rtToken='+userSvc.myRoles.realtime_token, options: {} });
    this.emit('realtime_user_id_connected', { userId: userSvc.myRoles.realtime_user_id });
    this.emit("subscribe", 'realtime_msg');
    this.on<RealtimeMsgObj[]>('realtime_msg').pipe(
      tap(res => {
        debuglog(RxJsLoggingLevel.TRACE,'RealtimeSocketService:filter::new msg',res,res[0].msg["resource"]);
        switch(res[0].msg["resource"]){
          case 'locations':
            debuglog(RxJsLoggingLevel.TRACE,'RealtimeSocketService:realtime_msg::new location',res);
            this.locsSubject.next(res);
            break;

          case 'alerts':
            debuglog(RxJsLoggingLevel.TRACE, 'RealtimeSocketService:realtime_msg::new alert',res);
            this.alertsSubject.next(res);
            break;
        }
      }),
      // ,
    ).subscribe((msg:RealtimeMsgObj[])=> {
      debuglog(RxJsLoggingLevel.TRACE, 'RealtimeSocketService:realtime_msg::new msg',msg);
    });

    // this.swPush.requestSubscription({
    //     serverPublicKey: this.VAPID_PUBLIC_KEY
    // },)
    // .then(sub => {
    //
    //     this.sub = sub;
    //     this.sub
    //
    //
    //     console.log("Notification Subscription: ", sub);
    //
    //     this.deviceLogSvc.addPushSubscriber(sub).subscribe(
    //         () => console.log('Sent push subscription object to server.'),
    //         err =>  console.log('Could not send subscription object to server, reason: ', err)
    //     );
    //
    // })
    // .catch(err => console.error("Could not subscribe to notifications", err));

    // this.fireMessaging.requestToken.subscribe({
    //     next: token => {
    //       // Upload the user FCM token to your server.
    //       this.deviceLogSvc.addPushSubscriber(token).subscribe(
    //           () => console.log('Sent push subscription object to server.'),
    //           err =>  console.log('Could not send subscription object to server, reason: ', err)
    //       );
    //
    //       this.fireMessaging.messages.subscribe((message: any) => {
    //         console.log('Firebase Foreground message: ' + message)
    //       })
    //       // this.fireMessaging.subscribeToTopics(['topic1', 'topic2', 'topic3']);
    //     },
    //     error: err => {
    //       console.error('Fetching FCM token failed: ', +err)
    //     }
    // });



    this.requestPermission();
    this.listen();
   }
  getRealtimeSocket(){
    debuglog(RxJsLoggingLevel.TRACE,'RealtimeSocketService::getRealtimeSocket: new socket',this);
    return this;

  }
  messageStream(){
    return this.on<RealtimeMsgObj[]>('realtime_msg').pipe(
      tap((args: RealtimeMsgObj[]) => {
        map(res => res[0].msg),
        debuglog(RxJsLoggingLevel.TRACE,'RealtimeSocketService:realtime_msg::new msg',args);
         // debuglog(RxJsLoggingLevel.DEBUG, 'RealtimeSocketService:realtime_msg::new msg', args);
         // window.realtime.socketIo.emit('realtime_user_id_connected', { userId: window.realtime.userId });
      })
      // ,
    );
  }

  // from https://medium.com/@jishnusaha89/firebase-cloud-messaging-push-notifications-with-angular-1fb7f173bfba
  requestPermission() {
    const messaging = getMessaging();
    debuglog(RxJsLoggingLevel.DEBUG,'RealtimeSocketService::requestPermission: Gefore');
    getToken(messaging,
     { vapidKey: environment.firebase.vapidKey}).then(
       (currentToken) => {
         debuglog(RxJsLoggingLevel.DEBUG,'RealtimeSocketService::requestPermission: currentToken',currentToken);
         if (currentToken) {
           debuglog(RxJsLoggingLevel.TRACE,"RealtimeSocketService::Hurraaa!!! we got the token.....");
           debuglog(RxJsLoggingLevel.TRACE,"RealtimeSocketService::currentToken",currentToken);
         } else {
           debuglog(RxJsLoggingLevel.DEBUG,'RealtimeSocketService::No registration token available. Request permission to generate one.');
         }     }).catch((err) => {
        debuglog(RxJsLoggingLevel.DEBUG,'RealtimeSocketService::An error occurred while retrieving token. ', err);
    });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      debuglog(RxJsLoggingLevel.DEBUG,'RealtimeSocketService::Message received. ', payload);
      // this.message=payload;
    });
  }
}
export interface RealtimeMsgObj {
	msg:RealtimeMsg;
}
// export interface RealtimeMsgWrapper {
// 	msg:RealtimeMsg;
// }
export interface RealtimeMsg {
	id:number;
	obj: Location|Alert;
	action: string | null;
  resource: string |null;
}
// { resource: 'locations',
//         action: action,
//         id: self.id,
//         obj: self }
// {
// realtime_1       |     resource: 'alerts',
// realtime_1       |     action: 'update',
// realtime_1       |     id: 6,
// realtime_1       |     obj: {
// realtime_1       |       msg: "come get lucky at Lucky's",
// realtime_1       |       url: '',
// realtime_1       |       date_end: '2024-10-11T00:08:00.000Z',
// realtime_1       |       date_campaign_start: '2014-10-11T00:08:00.000Z',
// realtime_1       |       description: null,
// realtime_1       |       is_qr_redeem: false,
// realtime_1       |       date_special_start: null,
// realtime_1       |       redemption_max: null,
// realtime_1       |       is_multiple_redeem: false,
// realtime_1       |       id: 6,
// realtime_1       |       location_id: 4,
// realtime_1       |       created_at: '2014-10-11T00:09:42.000Z',
// realtime_1       |       updated_at: '2023-05-20T02:15:51.290Z',
// realtime_1       |       redemption_current_count: null,
// realtime_1       |       user_id: null
// realtime_1       |     }
// realtime_1       |   }
