import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from '../api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../api/location.service';
import { Location, } from '../api/location';
import { AuthService } from '../core/auth.service';
import { User,TeamInvite } from '../core/user';
import { first } from 'rxjs/operators';
import { Observable, of, fromEvent } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import {  finalize, catchError } from 'rxjs/operators';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-team-invite',
  templateUrl: './team-invite.component.html',
  styleUrls: ['./team-invite.component.css']
})
export class TeamInviteComponent {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  teamInvite: TeamInvite;
  id: number;
  locationId:number;
  isAddMode: boolean;
  statusText:string;

  teamInviteDummy:TeamInvite={
    id : undefined,
    email : '',
    location_id:-1,
    role: 'worker',
    sender_id:-1,
    sender:null,
    link_id:null,
    is_complete:false
  };
  teamInviteFrm: FormGroup;
  loadedTeamInvite:any;

  constructor(
    public auth: AuthService,
    public userSvc: UserService,
    private locationSvc: LocationService,
    private router: Router,
    private aR: ActivatedRoute,
    private fb: FormBuilder,
    private changeDetectorRef:ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.id =+this.aR.snapshot.params['id'];
    this.teamInviteDummy.location_id=this.locationId = +this.aR.snapshot.params['location_id'];
    this.teamInviteDummy.sender_id=this.userSvc.me.id;
    debuglog(RxJsLoggingLevel.DEBUG,'teamInvite-crud::init: id',this.id);
    debuglog(RxJsLoggingLevel.DEBUG,'teamInvite-crud::init: locationId',this.locationId);

    this.isAddMode = !this.id;
    this.setupFormGroup(this.teamInviteDummy);
    if (!this.isAddMode) {
      // this.locationSvc.getTeamInvite(this.locationId,this.id)
      //   .pipe(first())
      //   .subscribe(x => {
      //     this.teamInvite=x.attributes;
      //     debuglog(RxJsLoggingLevel.DEBUG,'teamInvite-crud::init: teamInvite',this.teamInvite);
      //     // this.rooms.push(...x.attributes.rooms);
      //     // this.dsRooms.data = this.rooms;
      //     // this.teamInvites.push(...x.attributes.teamInvites);
      //     // this.dsTeamInvites.data = this.teamInvites;
      //     // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: teamInvites',this.teamInvites);
      //     // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: rooms',this.rooms);
      //     this.teamInviteFrm.patchValue(x.attributes);
      //
      //   });
    }else{

    }

  }
  public setupFormGroup(teamInvite:TeamInvite){
    if(teamInvite!= null){
      this.teamInviteFrm = this.fb.group({
        'email' : [teamInvite['email'], ],
        'role' : [teamInvite['role'], ],
        'location_id' : [teamInvite['location_id'], ],
        'sender_id' : [teamInvite['sender_id'], ],


      });
    }else{
      this.teamInviteFrm = this.fb.group({
        'email' : [null,],
        'role' : [null,],
        'location_id' : [null, ],
        'sender_id' : [null, ],
        'link_id':[null, ],

      });
    }

  }
  changeRole(e:any){
    debuglog(RxJsLoggingLevel.DEBUG,'teaminvite::changeRole: teamInvites',e);
    this.role?.setValue(e.target.value,{onlySelf:true,});
  }
  get role(){
    return this.teamInviteFrm.get('role');
  }
  canAddWorker(){
    return this.userSvc.canAddWorker(this.locationId);
  }
  canAddManager(){
    return this.userSvc.canAddWorker(this.locationId);
  }
  canAddOwner(){
    return this.userSvc.canAddWorker(this.locationId);
  }






  addTeamInvite(locationId:number,  teamInvite: TeamInvite) {
    teamInvite.is_complete=false;
    debuglog(RxJsLoggingLevel.DEBUG, 'teamInvite-crud:addTeamInvite::teamInvite', teamInvite);

    // if (teamInviteId !== undefined) {
      // this.locationSvc.updateTeamInvite(teamInvite,locationId, teamInviteId)
      //   .subscribe(updateTeamInvite => {
      //     this.router.navigateByUrl('/location-crud/'+locationId);
      //   })
    // } else {
      debuglog(RxJsLoggingLevel.DEBUG, 'teamInvite-crud:addTeamInvite::no id', teamInvite.email,locationId);
      this.locationSvc.checkEmailRole(teamInvite.email,locationId)
        .subscribe(result =>{
          debuglog(RxJsLoggingLevel.DEBUG, 'teamInvite-crud:addTeamInvite::checkEmailRole', result);
          if(result.status){
            this.statusText='This email already has a role at selected location';
          }else{
            this.locationSvc.insertTeamInvite(teamInvite,locationId)
              .subscribe(newTeamInvite => {
                // this.locations.push(newLocation);
                this.router.navigateByUrl('/location-crud/'+locationId);
              });
          }

        });

    // }
  }

}
