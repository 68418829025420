<!-- <div style="width:85%;height:400px" #mapElement >map here</div>
<div id="popupnest" style="display: none;"><div  #popupnest></div><div> -->

<!-- <div class="map-container" fxLayout="column" fxLayoutAlign="space-around center"  > -->
<!-- (leafletMapReady)="onMapReady($event)" -->
<!-- [leafletOptions]="mapOptions" -->
  <div class="map-frame" fxLayout="row"   fxLayoutAlign="center center">

    <div id="map-id"  style="width:85%;height:400px"  #mapElement
    leaflet
    (leafletMapReady)="onMapReady($event)"
    [leafletMarkerCluster]="markerClusterData"></div>

  </div>
<!-- </div> -->
<div id="popupnest" style="display: none;"><div  #popupnest></div><div>

  <!-- map include alt from above -->
  <!-- <div id="map"></div> -->
  <!-- <ng-container #viewContainerRef><div id="map-id" #mapElement></div></ng-container> -->

<!-- <div class="map-container" fxFlex="40">
  <div class="map-frame">
    <div id="map-id" #mapElement ></div>
  </div>
</div> -->
<!-- style="width:85%;height:400px"  -->
