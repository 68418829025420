<h1 mat-dialog-title>Login/Signup</h1>

<div mat-dialog-content>
  <div *ngIf="flagShowProfile"><app-user-profile [hideLink]="true"></app-user-profile></div>
  <div *ngIf="!flagShowProfile"><app-register [hideLink]="true"></app-register></div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="toggleShowProfile()">{{flagShowProfile?'Register':'Login'}}</button>
  <button mat-button (click)="cancel()">Close</button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>
