import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { AuthService } from '../core/auth.service';
import { UserService } from '../api/user.service';
import { Alert,  } from '../api/alert';
import { AlertService } from '../api/alert.service';
import {  Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-delete',
  templateUrl: './alert-delete.component.html',
  styleUrls: ['./alert-delete.component.css']
})
export class AlertDeleteComponent implements OnInit {
alertSub:Subscription;
  constructor(
    private userSvc: UserService,
    private alertSvc: AlertService,
    public dialogRef: MatDialogRef<AlertDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data:  Alert,
    private router: Router,
  ) { }

  ngOnInit() {
  }
  cancel(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'alert-delete::cancel: data');
    this.dialogRef.close();

  }
  delete(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'alert-delete::delete: data',this.data['alert']);
    let locationAuth=false;
    let parentRoute='/alert-manage';
    if(!isNaN(this.data['alert'].location_id)&& this.data['alert'].location_id!==null){
      locationAuth=this.userSvc.checkLocationRole(this.data['alert'].location_id,['owner']);
      parentRoute='/location-crud/'+this.data['alert'].location_id;
    }
    if(this.userSvc.checkAuthorization(['superadmin','admin'])||locationAuth )this.alertSub=this.alertSvc.deleteAlert(this.data['alert'].id).subscribe((result)=>{
      debuglog(RxJsLoggingLevel.DEBUG,'alert-delete::delete: result',result);
      this.alertSub.unsubscribe();
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function() { return false; };
    this.router.navigateByUrl(parentRoute);
    this.dialogRef.close();

  }


}
