import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {  debuglog, RxJsLoggingLevel } from '../core/debug.operator';

import { UserService } from '../api/user.service';
import { Location,  } from '../api/location';
import { LocationService } from '../api/location.service';
import {  Subscription } from 'rxjs';

@Component({
  selector: 'app-location-delete',
  templateUrl: './location-delete.component.html',
  styleUrls: ['./location-delete.component.css']
})
export class LocationDeleteComponent implements OnInit {
  locSub:Subscription;
  constructor(
    private userSvc: UserService,
    private locSvc: LocationService,
    public dialogRef: MatDialogRef<LocationDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data:  Location,) { }

  ngOnInit() {
  }
  cancel(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'location-delete::cancel: data');
    this.dialogRef.close();

  }
  delete(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'location-delete::delete: data');
    if(this.userSvc.checkAuthorization(['superadmin','admin'])||this.userSvc.checkLocationRole(this.data['loc'].id,['owner']) )this.locSub=this.locSvc.deleteLocation(this.data['loc'].id).subscribe((result)=>{
      this.locSub.unsubscribe();
    });

    this.dialogRef.close();

  }


}
