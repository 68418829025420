import { Injectable, Inject } from '@angular/core';
import { debuglog,RxJsLoggingLevel } from './debug.operator';
import * as chroma from 'chroma.ts';

@Injectable({
  providedIn: 'root'
})


export class ChromaService {

  private xchroma:any;
  private colorset : any=['black', 'blue', 'red'];
  private colormap :any;
  // constructor(@Inject('Chroma') private chroma: any ) {
  //   // this.xchroma=chroma.chroma;
  // }
  // TODO this seems to work but not proper injection
  constructor() {
    this.xchroma=null;
    debuglog(RxJsLoggingLevel.TRACE,'chroma constructor',chroma);
    this.setColors(this.colorset);

  }

  setColors (newColorset) {
    this.colorset = newColorset;
    this.colormap = chroma.scale(this.colorset).mode('lab').correctLightness(false);
  }


  getHeatColor(heat) {
    return this.colormap(heat).hex()
  }
  getHeatColorRGBA(heat) {
    return this.colormap(heat).rgba()
  }



}
