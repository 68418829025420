import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef,MatDialog} from '@angular/material/dialog';
import { Location,LocationWrapper,LocationDialogData } from '../api/location';
import { LocationService } from '../api/location.service';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { ChromaService } from '../core/chroma.service';
import { formatDistance, subDays, format,parseISO } from 'date-fns';
import { AlertDetailComponent } from '../alert-detail/alert-detail.component';
import { ChartConfiguration } from 'chart.js';
import { UiStateService } from '../core/ui-state.service';

import { ViewChild, ElementRef,AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import { environment } from '../../environments/environment';


@Component({
  selector: 'location-detail',
  templateUrl: './location-detail.component.html',
})
export class LocationDetailComponent implements AfterViewInit{
  loc:Location;
  stats:any={
    hrly:[],
    hrly_rollup:[],
    dow_rollup:[]
  };

  public barChartLegend = false;
  public barChartPlugins = [

  ];

  public barChartData: ChartConfiguration<'bar'>['data'];
  public barChartDataDow: ChartConfiguration<'bar'>['data'];

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: false,
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false

        },
        max:1
      },
      x: {

        grid: {
          display: false

        }
      },

    },
    plugins: {
      tooltip: {
        callbacks: {
          // footer: footer,
          label: barLabel
        }
      }
    }
  };
  @ViewChild('mapElement',{ static: false}) mapElement: ElementRef;
  map: any;
  markers:any[]=[];
  showMap:boolean=false;

  mapMarkers:any[]=[];


  constructor(private chroma: ChromaService,
    public dialogRef: MatDialogRef<LocationDetailComponent>,
      @Inject(MAT_DIALOG_DATA) public data: LocationDialogData,
      public dialog: MatDialog,
      locSvc:LocationService,
      private uiStateSvc: UiStateService,
      ){
        this.loc=data.loc.attributes;
        debuglog(RxJsLoggingLevel.DEBUG, 'LocactionDetail::construct loc', this.loc);
        let locSub=locSvc.getLocationStats(data.loc.attributes.id).subscribe(stats=>{
          this.stats=stats;
          this.barChartData={
            labels: this.stats.hrly_rollup.map(stat=> {
                      return ''+(stat.hour<12?stat.hour+1:stat.hour-12+1)+(stat.hour==23?'AM':stat.hour<10?'AM':'PM');
                    }),
            datasets: [
              { data: this.stats.hrly_rollup.map(stat=> {return stat.average;}),
                label: 'Heat',
                backgroundColor: this.stats.hrly_rollup.map(stat=> {let color=this.chroma.getHeatColorRGBA(stat.average);
                  return  'rgba('+color[0]+', '+color[1]+', '+color[2]+', .8)';}),
                borderColor: this.stats.hrly_rollup.map(stat=> {let color=this.chroma.getHeatColorRGBA(stat.average);
                  return  'rgba('+color[0]+', '+color[1]+', '+color[2]+', 1)';}),
                borderWidth: 1
              },
            ]
          };
          this.barChartDataDow={
            labels: this.stats.dow_rollup.map(stat=> {
                      return weekday[stat.dow];
                    }),
            datasets: [
              { data: this.stats.dow_rollup.map(stat=> {return stat.average;}),
                label: 'Heat',
                backgroundColor: this.stats.dow_rollup.map(stat=> {let color=this.chroma.getHeatColorRGBA(stat.average);
                  return  'rgba('+color[0]+', '+color[1]+', '+color[2]+', .8)';}),
                borderColor: this.stats.dow_rollup.map(stat=> {let color=this.chroma.getHeatColorRGBA(stat.average);
                  return  'rgba('+color[0]+', '+color[1]+', '+color[2]+', 1)';}),
                borderWidth: 1
              },
            ]
          };
          let now= new Date();
          debuglog(RxJsLoggingLevel.DEBUG, 'LocactionDetail::get stats loc', stats,this.barChartData,now.getTimezoneOffset());
        });
        this.uiStateSvc.trackInteraction('location_view', 'location', this.loc.name, this.loc.id,undefined, {
            location_id:this.loc.id,
            name:this.loc.name,
        });
       }

       getcolor(heat:Number) {
         let color = this.chroma.getHeatColor(heat);
         debuglog(RxJsLoggingLevel.TRACE, 'getcolor ' + heat, color);
         return color;
       }
       dateFormat(dateItem) {
         debuglog(RxJsLoggingLevel.TRACE, 'LocactionDetail::dateFormat ', dateItem);
         return (dateItem==null?null:format(new Date(dateItem),'MM-dd-yy hh:mm aa'));
       }
       alertDetail(alert): void {
         // TODO update to Notifictions dialog
         const dialogRef = this.dialog.open(AlertDetailComponent, {
           width: '300px',
           data: {'alert': alert}
         });

         dialogRef.afterClosed().subscribe(result => {

           debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:AlertDetailComponent::afterClosed:result: ',result);

         });
       }

       private loadMap(): void {
         debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:loadMap mapElement', this.mapElement);
         // this.map = L.map(this.mapElement.nativeElement).setView([0, 0], 1);
         this.map = L.map('detail-map-id',{
          center: [Number(this.loc.latitude),Number(this.loc.longitude)],
          zoom: 3
        });
     // https://api.maptiler.com/maps/openstreetmap/?key='+environment.maptiler.accessToken+'#0.7/22.80535/2.86559'
         L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
           attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
           crossOrigin: true,
           minZoom: 1,
           tileSize: 512,
           zoomOffset: -1,
           accessToken: environment.maptiler.accessToken,
         }).addTo(this.map);

         // const position: any = await this.locationService.getCurrentLocation();
         // this.myPositionSvc.getCurrentPositionObservable()
         // .subscribe((position: any) => {
         // let position= this.myPositionSvc.currentPosition;
         // if((position.latitude !== undefined && position.latitude !== null) && (position.longitude !== undefined && position.longitude !==null )){
         //   debuglog(RxJsLoggingLevel.DEBUG, 'searchmap:getCurrentPosition position', position);
         //   this.map.flyTo([Number(position.latitude), Number(position.longitude)], 13);
         //   // position.latitude, position.longitude
         //
         //   const icon = L.icon({
         //     iconUrl: 'assets/images/marker-icon.png',
         //     shadowUrl: 'assets/images/marker-shadow.png',
         //     popupAnchor: [13, 0],
         //   });
         //
         //   const marker = L.marker([Number(position.latitude), Number(position.longitude)], { icon }).bindPopup('You are here.');
         //   // position.latitude, position.longitude
         //   marker.addTo(this.map);
         //   this.markers.push(marker);
         // };
         this.addMarkers();

       }
       addMarkers():void{
         this.markers.map((marker)=>{
           this.map.removeLayer(marker);
         });
         // this.markers=[];
         // this.locs.map((loc)=>{
           if(this.loc.latitude!== null && this.loc.longitude!== null){
             debuglog(RxJsLoggingLevel.DEBUG, 'searchmap::loc loop   loc', this.loc);
             // this.map.flyTo([Number(this.loc.latitude), Number(this.loc.longitude)], 13);
             const icon = L.icon({
               iconUrl: 'assets/images/marker-icon.png',
               shadowUrl: 'assets/images/marker-shadow.png',
               popupAnchor: [13, 0],
             });

             const marker = L.marker([this.loc.latitude, this.loc.longitude], { icon }).bindPopup(this.loc.name);
             marker.addTo(this.map);
             this.markers.push(marker);
           }
         // });
         debuglog(RxJsLoggingLevel.DEBUG, 'searchmap::addMarkers end  markers', this.markers);
       }

       ngAfterViewInit(): void {
         this.loadMap();
       }

}
const barLabel = (data) => {
  let label=data.parsed.y<.34?'free':(data.parsed.y>.65?'busy':'average');

  // console.log('barchart barLabel data',data,);
  // formattedValue and parsed.x , parsed.y
  return label;
};
const weekday = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",];
