import { Component, Input,  ElementRef,ViewChild,Injector,ChangeDetectorRef,OnInit,AfterViewInit  } from '@angular/core';
import { UiStateService } from '../ui-state.service';
import { RealtimeSocketService,RealtimeMsgObj} from '../realtime-socket.service';
import { realtimeSocketServiceProvider } from '../realtime-socket.service.provider';
import { ChromaService } from '../chroma.service';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../api/user.service';
import { debug, debuglog, RxJsLoggingLevel } from '../debug.operator';
import { Location,LocationWrapper } from '../../api/location';

@Component({
  selector: 'app-map-icon',
  templateUrl: './map-icon.component.html',
  // template: `<div  #svgIcon>
  //   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="svg-icon">
  //     <title>map pin</title>
  //     <g id="pin" data-name="Pin" #Pin>
  //       <path #Pinpath d="M25.0464,8.4834a10,10,0,0,0-7.9116-5.4258,11.3644,11.3644,0,0,0-2.2691,0A10.0027,10.0027,0,0,0,6.9536,8.4829a10.8062,10.8062,0,0,0,1.481,11.8936l6.7929,8.2588a1,1,0,0,0,1.545,0l6.7929-8.2588A10.8055,10.8055,0,0,0,25.0464,8.4834ZM16,17a4,4,0,1,1,4-4A4.0047,4.0047,0,0,1,16,17Z" style="fill:#262628"/>
  //     </g>
  //   </svg>
  // </div>`,
  styleUrls: ['./map-icon.component.css']
})
export class MapIconComponent implements AfterViewInit, OnInit {
  
  @Input() heat: number;
  @Input() loc: Location;
  realtimeSocketService:RealtimeSocketService;
  @ViewChild('Pin', {  static: false }) pin: ElementRef;
  @ViewChild('Pinpath', {  static: false }) pinPath: ElementRef;
  @ViewChild('svgIcon', {  static: false }) svg: ElementRef;
  uistateSub:Subscription=null;
  authUserSub:Subscription=null;
  authsvc: AuthService;
  userSvc:UserService;

  constructor(private auth: AuthService,
    // private faye: FayeService,
    private chroma: ChromaService,

    private uiStateSvc: UiStateService,

    private injector: Injector,
    private usrSvc: UserService,

    private changeDetectorRefs: ChangeDetectorRef,


  ) {
      this.authsvc = auth;
      this.userSvc =usrSvc;

  }

  ngOnInit(){
    debuglog(RxJsLoggingLevel.DEBUG, 'map-icon::ngOnInit::new Socket', this.loc.id,this.loc.current_state/(this.loc.max_cap >0?this.loc.max_cap:1));
  }
  ngAfterViewInit() {
    this.getcolor(this.heat);
    // this.authsvc.user$.subscribe((user)=> {
    //   this.userSvc.myRoles$.subscribe(results=>{
        this.realtimeSocketService = this.injector.get(RealtimeSocketService);
        debuglog(RxJsLoggingLevel.DEBUG, 'map-icon::realtime_msg::new Socket', this.realtimeSocketService);
        // this.realtimeSocketService.emit("subscribe", 'realtime_msg');
        this.realtimeSocketService.locs$.subscribe((msg:RealtimeMsgObj[])=> {

          if(msg[0].msg["resource"] =='locations'&& Number(msg[0].msg.id)==this.loc.id){
            debuglog(RxJsLoggingLevel.DEBUG, 'map-icon::realtime_msg::id loc',this.loc,msg[0].msg.obj,);
            let loc =msg[0].msg.obj as Location;
            this.getcolor(loc.current_state/(loc.max_cap >0?loc.max_cap:1));

          }
        });;

  //     });
  //
  // });
  }

  getcolor(heat:Number) {
    let color = this.chroma.getHeatColor(heat);
    debuglog(RxJsLoggingLevel.DEBUG, 'map-icon::getcolor ' , heat, color,this.pinPath,this.pin,this.svg);
    this.pinPath.nativeElement.style.fill=color;
    return color;
  }



}
