import { Component, OnInit,Inject,PipeTransform, Pipe, } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MyPositionService,MyPositionData } from '../core/my-position.service';
import { FormBuilder, FormGroup,FormControl, Validators } from '@angular/forms';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { AlertService } from '../api/alert.service';
import { UserService } from '../api/user.service';
import { AlertDialogData,Alert, AlertWrapper } from '../api/alert';
import { Location} from '../api/location';
// import map from 'lodash/map'
// import * as _ from 'lodash';
import { UrlSegment } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { WINDOW } from '../core/window.providers';
import {Clipboard} from '@angular/cdk/clipboard';
import { formatDistance, subDays, format,parseISO } from 'date-fns';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import {Frequency, Options, RRule,rrulestr} from 'rrule';
import { UiStateService } from '../core/ui-state.service';

@Component({
  selector: 'app-alert-detail',
  templateUrl: './alert-detail.component.html',
  styleUrls: ['./alert-detail.component.css']
})
export class AlertDetailComponent implements OnInit {

  alertFrm: FormGroup= new FormGroup({
    msg: new FormControl(''),
    url: new FormControl(''),
    date_end: new FormControl(''),
    date_campaign_start: new FormControl(''),
    description: new FormControl(''),
    date_special_start: new FormControl(''),
    redemption_max: new FormControl(''),
    is_qr_redeem: new FormControl(false),
    is_multiple_redeem: new FormControl(false),
  });
  submitted = false;
  alert:AlertWrapper|undefined; // really jsonapi form of alert
  elementType = 'url';
  qrvalue:string='';
  shareUrl:string;
constructor(
  public dialogRef: MatDialogRef<AlertDetailComponent>,
  @Inject(MAT_DIALOG_DATA) public data:  AlertDialogData,
  // private fb: FormBuilder,
  private alertSvc: AlertService,
  private authSvc: AuthService,
  @Inject(WINDOW) private window: Window,
  private clipboard: Clipboard,
  private userSvc:UserService,
  private uiStateSvc: UiStateService,
 ) {
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::constructor: data',this.data);
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::constructor: domain',this.window.location);
    let baseUrl=this.window.location.href;
    const regex=/cszEvt=([^&]*)/;
    const regex_uid=/uid=([^&]*)/;
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::constructor: cszEvt',this.window.location.search||false);

    if( regex.test(this.window.location.search)){
      this.shareUrl=baseUrl.replace(regex,''+this.data['alert'].attributes.id);
      this.shareUrl=this.shareUrl.replace(regex_uid,''+this.authSvc.currentUserId);
    }else {
      let re =/\?/;
      if(baseUrl.search(re)== -1){
        // no ? present
        this.shareUrl=baseUrl +'?cszEvt='+this.data['alert'].attributes.id+'&uid='+ this.authSvc.currentUserId;
      }else{
        this.shareUrl=baseUrl +'&cszEvt='+this.data['alert'].attributes.id+'&uid='+ this.authSvc.currentUserId;
      }
    }
    this.uiStateSvc.trackInteraction('alert_view', 'alert', this.data['alert'].attributes.msg, this.data['alert'].attributes.id,undefined, {
        alert_id:this.data['alert'].attributes.id,
        msg:this.data['alert'].attributes.msg,
    });

  }

  resendVerification(){
    this.authSvc.resendVerification();
  }

  checkLogin():boolean{
    return this.userSvc.checkAuthorization(['verified']);
  }
  share(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::save: data',this.alertFrm.value);
    // this.alertSvc.insertAlert(this.alertFrm.value).subscribe(alert => {
    //   this.dialogRef.close(this.data['alerts'].push(alert));
    // });
    this.uiStateSvc.trackInteraction('alert_share', 'alert', this.data['alert'].attributes.msg, this.data['alert'].attributes.id,undefined, {
        alert_id:this.data['alert'].attributes.id,
        msg:this.data['alert'].attributes.msg,
    });
  }
  remember(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::upddateUP: data',this.alertFrm.value,this.data.alert!=undefined?this.data.alert.attributes!=undefined?this.data.alert.attributes.id:'undefined attributes':'undefined alert');
    // TODO consider featur to maek alert (mayb it stays in list even whn out of search area)

    // this.alertSvc.updateAlert(this.alertFrm.value,this.data['alert'].attributes.id ).subscribe(alert => {
    //   let alerts=this.data['alerts'];
    //   let upIn=this.data['alert'];
    //   debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::upddateUP: aftrupdate',alert);

    //   map(this.data['alerts'], function(up,i){
    //     if(upIn.attributes.id ==up.attributes.id){
    //       console.log('in loop up ',i, up );

    //       up.attributes.label=alert.attributes.label;
    //       up.attributes.place=alert.attributes.place;
    //     }
    //     return up;
    //   });
    //   this.dialogRef.close(this.data['alerts']);
    // });
  }

  cancel(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::cancel: data');
    this.dialogRef.close();

  }
  ngOnInit() {

    this.alert=this.data.alert;
    // this.qrvalue=JSON.stringify(this.shareUrl+'&uid='+ this.authSvc.currentUserId + '&alert_id='+this.alert.attributes.id);
    this.qrvalue=this.shareUrl;
    // this.qrvalue = '/redeem?a='+ this.alert!==undefined?this.alert.attributes!==undefined?this.alert.attributes.id:0:0 + '&u='+ this.authSvc!=undefined?this.authSvc.user!= undefined?this.authSvc.user.id:'':'';
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::init: data qrvalue',this.data,this.qrvalue);
    // TODO increment alert_view_count add column and method

  }

  copyShareUrl():void{
    this.clipboard.copy(this.shareUrl);
    this.uiStateSvc.trackInteraction('alert_share', 'alert', this.data['alert'].attributes.msg, this.data['alert'].attributes.id,undefined, {
        alert_id:this.data['alert'].attributes.id,
        msg:this.data['alert'].attributes.msg,
    });
  }
  dateFormat(dateItem) {
    return (dateItem==null?null:format(new Date(dateItem),'MM-dd-yy hh:mm aa'));
  }
  addToCalendar(): void {
    let createdTime = new Date().toISOString();
    let nextDates= [(!this.data['alert'].attributes.date_special_start?this.data['alert'].attributes.date_campaign_start:this.data['alert'].attributes.date_special_start)];
    let dstart= new Date(nextDates[0]).toISOString();
    if(this.data['alert'].attributes.rrule){
      let rrule =rrulestr(this.data['alert'].attributes.rrule);
      nextDates=rrule.all(function (date, i) {
        return i== 0;
      });
      dstart= new Date(nextDates[0]).toISOString();
    }
    let calendarData = [
      'data:text/calendar;charset=utf8,',
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DESCRIPTION:' + this.data['alert'].attributes.description + ' '+ this.shareUrl,
      'DTSTART:' + dstart,
      'DURATION: { hours:' + this.data['alert'].attributes.duration_hours+',  minutes:'+this.data['alert'].attributes.duration_minutes +'}',
      'LOCATION:' + (this.data['alert'].attributes.location!==null ?this.data['alert'].attributes.location.address+ ', '+this.data['alert'].attributes.location.city+ ', '+this.data['alert'].attributes.location.state:''),
      'SUMMARY:' + this.data['alert'].attributes.msg,
      'TRANSP:TRANSPARENT',
      'END:VEVENT',
      'END:VCALENDAR',
      'UID:' + this.data['alert'].attributes.id,
      'DTSTAMP:' + createdTime,
      'PRODID:website-1.0'
    ];
    if(this.data['alert'].attributes.rrule) calendarData.splice(6,0,'RRULE:' + this.data['alert'].attributes.rrule);
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::addToCalendar ics',calendarData);
    let icsMsg=calendarData.join('\n');
    window.open(icsMsg);
    this.uiStateSvc.trackInteraction('alert_remember', 'alert', this.data['alert'].attributes.msg, this.data['alert'].attributes.id,undefined, {
        alert_id:this.data['alert'].attributes.id,
        msg:this.data['alert'].attributes.msg,
    });
  }

}

@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
