<h1 mat-dialog-title>{{data['alert'].attributes.msg}}</h1>
<div mat-dialog-content  layout="row" >
  <div *ngIf="data['alert'].attributes.location!== null" layout="row" layout-align="right center" class="mat-caption"><strong>@ {{data['alert'].attributes.location.name}}</strong><br />
    {{data['alert'].attributes.location.address}},{{data['alert'].attributes.location.city}}
  </div>
  <div layout="row" layout-align="left center" class="mat-caption">{{dateFormat(data['alert'].attributes.date_special_start?data['alert'].attributes.date_special_start:data['alert'].attributes.date_campaign_start)}}</div>
  <div [innerHtml]="data['alert'].attributes.description  | safeHtml"></div>
  <div  *ngIf="this.checkLogin()  ; else elseBlockLogin">
    <qrcode *ngIf="data['alert'].attributes.is_qr_redeem && (data['alert'].attributes.redemption_max> data['alert'].attributes.redemption_current_count|| data['alert'].attributes.redemption_current_count ==null ) "
    [elementType]="elementType"
    [qrdata]="qrvalue"
    cssClass="aclass"
    [width]="256"
    errorCorrectionLevel="M">
    </qrcode>
    <div layout="row" layout-align="center center" class="mat-caption">Present at Checkout</div>
    <div *ngIf="data['alert'].attributes.is_multiple_redeem == true" layout="row" layout-align="center center" class="mat-caption">* Can be Redeemed Multiple Times</div>
  </div>
  <ng-template #elseBlockLogin>
    <img *ngIf="!this.checkLogin()" src="/assets/images/qr-need-login.png">
    <div *ngIf="!authSvc.verified">Your account needs to be verified. Check your email. <a (click)="resendVerification()">Resend Verification Email</a></div>
  </ng-template>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="addToCalendar()">Save to Calendar</button>
  <button mat-button (click)="copyShareUrl()">Share<mat-icon>file_copy</mat-icon></button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>
