import { Component, Input, OnInit, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { LocationService } from '../api/location.service';
import { Observable, of, fromEvent } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged, finalize, catchError } from 'rxjs/operators';
import { PageEvent,  MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { ViewChild } from '@angular/core';
import { UserService } from '../api/user.service';
import { User,TeamMember,TeamMemberWrapper } from '../core/user';
import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { TeamDeleteComponent } from '../team-delete/team-delete.component';
import {MatDialog, } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-team-manage',
  templateUrl: './team-manage.component.html',
  styleUrls: ['./team-manage.component.css']
})
export class TeamManageComponent {

  myRoles:any;
  user: User| null | undefined;
  @Input() locationId!:number;
  displayedColumns: string[] = ['name', 'role', 'actions'];
  dsTeamMembers: MatTableDataSource<TeamMember>;
  teamMembers:TeamMember[]=[];

  @ViewChild(MatPaginator, { read: false, static: false }) paginator: MatPaginator;
  @ViewChild('input', { read: false, static: false }) input: ElementRef;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20];
  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    public auth: AuthService,
    private userSvc: UserService,
    private locationSvc: LocationService,
    public dialog: MatDialog ,
    private _snackBar: MatSnackBar,

  ) {
    this.dsTeamMembers = new MatTableDataSource<TeamMember>();

  }

  ngOnInit() {
    // to lockdown particular functionality in Component

    // this.auth.user$.subscribe(user => {
    //   this.user=user;
    //   debuglog(RxJsLoggingLevel.DEBUG,'team-manage::init: user',user);
    //   this.userSvc.myRoles$.subscribe(results=>{
    //     debuglog(RxJsLoggingLevel.DEBUG,'team-manage::init: myRole',results);
    //     this.myRoles= results;
        this.user = this.auth.user;
        this.myRoles = this.userSvc.myRoles;
        // superadmin||admin|| locationowner|| locationmanager
        this.loadTeams();

    //   });
    //
    // });
  }
  loadTeams(){
    this.locationSvc.getTeam(this.locationId).subscribe(teamMembers => {
      // create location index from results
      let teamMembersLocal =[];
      debuglog(RxJsLoggingLevel.DEBUG, 'team_manage:locationSvc:getTeam:subscribe: results', teamMembers);
      if(teamMembers !== null){
        teamMembers.map(function(teamMember:TeamMemberWrapper,i:number){
          debuglog(RxJsLoggingLevel.DEBUG,'in loop teamMembers ',i, teamMember );
          teamMembersLocal.push(teamMember);
          // alert.attributes.alerts=[];
          // alert.attributes.isFavorite=false;
          // alert.attributes.favId=null;
          return teamMember;
        });
        this.teamMembers=teamMembersLocal;
      }else{}
      debuglog(RxJsLoggingLevel.DEBUG, 'team_manage:locationSvc:getTeam:subscribe: finish', this.teamMembers);
      this.dsTeamMembers.data = this.teamMembers;
    });
  }
  deleteTeamMember(location_id: number|string, team:TeamMember):void{
    const dialogRef = this.dialog.open(TeamDeleteComponent, {
      width: '250px',
      data: {team: team, locationId: this.locationId}
    });

    dialogRef.afterClosed().subscribe(result => {

      debuglog(RxJsLoggingLevel.DEBUG, 'team-manage:TeamDeleteComponent::afterClosed:result: ',result);
      this.loadTeams();

    });

  }

  onRowClicked(row: number) {
    debuglog(RxJsLoggingLevel.DEBUG, 'Row clicked: ', row);
  }


}
