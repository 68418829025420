import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import {UserService } from '../api/user.service';
import { tap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationModifyGuard implements CanActivate {
  constructor(private auth: AuthService,private user: UserService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.auth.user$.pipe(
      take(1),
      // map(user => user && user.roles.admin ? true : false),
      map(user => this.user.checkAuthorization( ['admin','superadmin','owner','manager']) ? true : false),
      // tap(isAdmin => {
      //   if (!isAdmin) {
      //     console.error('Access denied - Admins only')
      //   }
      // }
    // )
    );
    // return this.auth.checkAuthorization(this.auth.user$, ['admin','superadmin']);


  }
}
