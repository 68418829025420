<h1 mat-dialog-title>Delete Alert</h1>

<div mat-dialog-content>
  <p>Are you sure you want to delete:</p>
  <p>{{data['alert'].msg}}</p>
  {{data['alert'].description}}

</div>
<div mat-dialog-actions>
  <button mat-button (click)="delete()">Delete</button>
  <button mat-button (click)="cancel()">Cancel</button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>
