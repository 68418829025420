
<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="example-toolbar">
    <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
    <h1 class="example-app-name">CrowdSenz</h1>

    <button mat-icon-button [matMenuTriggerFor]="alertmenu" >
       <mat-icon matBadge="{{alerts.length}}" matBadgeColor="warn">notifications</mat-icon>
    </button>
    <mat-menu #alertmenu="matMenu" >
     <button mat-menu-item *ngFor="let element of alerts " (click)="alertDetail(element)" >
       <span>{{element.attributes.msg}}</span>
     </button>
     </mat-menu>
     <button mat-icon-button (click)="openLoginDialog()" >
       <mat-icon >login</mat-icon>
    </button>



       <button class="header-item" mat-icon-button  [matMenuTriggerFor]="posiionmenu" >
          <mat-icon>location_on</mat-icon>
          <!-- my_location -->
       </button>
       <mat-menu #posiionmenu="matMenu" >
         <mat-option  class="button-container">
           <button class="header-item" mat-icon-button (click)="detectPosition();$event.stopPropagation()" >
             <mat-icon>gps_fixed</mat-icon>
           </button>
           <button class="header-item" mat-icon-button (click)="openPositionSearch();$event.stopPropagation()" >
             <mat-icon>search</mat-icon>
           </button>
         </mat-option>
         <mat-option *ngFor="let element of filteredUserPlaces | async" [value]="element">
           <button class="header-item" mat-icon-button (click)="editUserPlace(element);$event.stopPropagation()">
             <mat-icon>settings</mat-icon>
           </button>
           {{(element.attributes.label.length > 0?element.attributes.label :element.attributes.place)}}
         </mat-option>
        <!-- <button mat-menu-item *ngFor="let element of userPlaces " (click)="alertDetail(element)" >
          <span>{{(element.attributes.label.length > 0?element.attributes.label :element.attributes.place)}}</span>
        </button> -->
        </mat-menu>

      <!-- <button  mat-icon-button (click)="toggleMap()">
        <mat-icon>public</mat-icon>
      </button> -->



  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <app-main-nav></app-main-nav>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <div class="row my-5">
    <div class="col text-end">
      Status:
    </div>
    <div class="col">
      <span class="badge bg-success" *ngIf="isOnline">Online</span>
      <span class="badge bg-danger" *ngIf="!isOnline">Offline</span>
    </div>
  </div>
  <div class="w-100 position-absolute top-0" *ngIf="modalVersion">
    <div class="alert alert-secondary m-2">
      <button type="button" class="btn-close position-absolute top-0 end-0 m-1" aria-label="Close" (click)="closeVersion()"></button>
      A new version of this app is available. <a href="" (click)="updateVersion()">Update now</a>
    </div>
  </div>

  <div class="w-100 position-absolute bottom-0" *ngIf="modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS'">
    <div class="alert alert-secondary m-2">
      <button type="button" class="btn-close position-absolute top-0 end-0 m-1" aria-label="Close" (click)="closePwa()"></button>
      <!-- Android -->
      <div *ngIf="modalPwaPlatform === 'ANDROID'" (click)="addToHomeScreen()">
        Add this WEB app to home screen
      </div>
      <!-- iOS with Safari -->
      <div *ngIf="modalPwaPlatform === 'IOS'">
        To install this WEB app on your device, tap the "Menu" button
        <img src="https://res.cloudinary.com/rodrigokamada/image/upload/v1641089482/Blog/angular-pwa/safari_action_button_38x50.png" class="ios-menu m-0" />
        and then "Add to home screen" button
        <i class="bi bi-plus-square"></i>
      </div>
    </div>
  </div>
</div>
