import { Component, OnInit,Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef,MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MyPositionService,MyPositionData } from '../core/my-position.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { UserPlaceService } from '../api/user-place.service';
import { UserPlace } from '../api/user-place';
import { Observable,of } from 'rxjs';
import {FormControl} from '@angular/forms';
import {  Subscription } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { SetUserLocationDialog } from '../set-user-location-dialog/set-user-location-dialog.component';

@Component({
  selector: 'app-search-user-location',
  templateUrl: './search-user-location.component.html',
  styleUrls: ['./search-user-location.component.css']
})
export class SearchUserLocationComponent {
  @ViewChild('search') searchElement: ElementRef;
  userPlaceSub:Subscription=null;
  myPositionSub:Subscription=null;
  userPlaces:any[];
  userPlaceSelected: number;
  filteredUserPlaces: Observable<any[]>;
  showPositionButtons:boolean=false;
  showPositionCheckButtons:boolean=false;
  private userPlacesIndex:number[];
  myControl = new FormControl();
  myPositionSvc:MyPositionService;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SearchUserLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:  any,
    private fb: FormBuilder,
    private userPlaceSvc: UserPlaceService,
    myPositionSvc: MyPositionService) {
     this.myPositionSvc = myPositionSvc;
   }

   private _filter(value: any): string[] {
     debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:_filter:value',value);
     if (typeof value !== 'string') {
       this.showPositionCheckButtons = false;
       this.myPositionSvc.setCurrentPosition(value);
       // this.showPositionCheckButtons = false;
       //when an object sent it means itsm selectd or (hopefully) position detect loaded
       // this.myPositionSvc.geoSearch(value);
       return this.userPlaces;
     }
     this.myPositionSvc.clearSearchResults();
     const filterValue = value.toLowerCase();

     const newList= this.userPlaces.filter(userPlace => filterValue==''||userPlace.attributes.place.toLowerCase().includes(filterValue)||userPlace.attributes.label.toLowerCase().includes(filterValue));
     // if no matches and sarch filter long enough, show buttons to ad to saved places and set as position(verify and set)
     if(newList.length ==0 && filterValue.length >5){
       this.showPositionCheckButtons = true;
     } else {
       this.showPositionCheckButtons = false;
     }
     return newList;
   }

  cancel(): void {
    // debuglog(RxJsLoggingLevel.DEBUG,'search-user-loction::cancel: data',this.userPlaceFrm.value);
    this.dialogRef.close();

  }

  ngOnInit(){
    this.userPlaceSub=this.userPlaceSvc.getUserPlaces().subscribe(userPlaces => {
      // create location index from results
      debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:UserPlacesDataSource::loadview:subscribe: before userPlaces userPlacesIndex', userPlaces,this.userPlacesIndex);
      let userPlacesIndex=[];
      debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:UserPlacesDataSource::loadview:subscribe: after userPlacesIndex', this.userPlacesIndex);
      debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:UserPlacesDataSource::loadview:results: userPlacesIndex',userPlacesIndex);
      // map in favorites to locations results

      debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:loadview:results::UserPlacesDataSource enhanced userPlaces', userPlaces);
      this.userPlaces=userPlaces;
      this.userPlacesIndex=userPlacesIndex;
      debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:UserPlacesDataSource::loadview:results: userPlacesIndex', this.userPlacesIndex);
      this.filteredUserPlaces = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      this.userPlaceSub.unsubscribe();

    });
  }



  ngOnDestroy(): void {
    // this.mobileQuery.removeListener(this._mobileQueryListener);
    // if (this.alertQuerySub !==null)this.alertQuerySub.unsubscribe();
    // if (this.rolesSub !==null)this.rolesSub.unsubscribe();
    // if (this.alertRTsub !==null)this.alertRTsub.unsubscribe();
    if (this.myPositionSub !==null)this.myPositionSub.unsubscribe();
    if (this.userPlaceSub !==null)this.userPlaceSub.unsubscribe();
    // if (this.authUserSub !==null)this.authUserSub.unsubscribe();

    // if (this.authSvc.user$ !==null) this.authSvc.user$.;
    // this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  searchFn():void{
   // this.filteredUserPlaces = this.myPositionSvc.getSearchResultsObservable();
   // this.myPositionSvc.getSearchResultsObservable().subscribe();
   this.myPositionSvc.geoSearch(this.myControl.value);
   this.searchElement.nativeElement.focus();
  }

  detectPosition(): void {
    this.myPositionSvc.getDevicePosition().subscribe((result)=>{
      if(result.latitude !== null && result.longitude !== null ){
        this.myPositionSvc.clearSearchResults();
        this.myPositionSvc.setSearchResults([{attributes:result}]);

        this.myPositionSvc.setCurrentPosition(result);
      }else{
        alert('Can not determine location\nEnsure Location Service is enabled');
      }
    });

  }

  newUserPlace(): void {
    const dialogRef = this.dialog.open(SetUserLocationDialog, {
      width: '250px',
      data: {'inputPosition': this.myControl.value,
      'position': this.myPositionSvc.currentPosition,
      'userPlaces': this.userPlaces}
    });

    dialogRef.afterClosed().subscribe(result => {
      debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:setMyLocation::afterClosed:result: ',result);
      this.userPlaces=result;
      // this.currentPositionTxt = this.myPositionSvc.currentPosition.address;
    });
  }
  editUserPlace(up): void {
    debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:editMyLocation::beforedialog:result: ',up);
    const dialogRef = this.dialog.open(SetUserLocationDialog, {
      width: '250px',
      data: {'userPlace': up,
      'position': this.myPositionSvc.currentPosition,
      'userPlaces': this.userPlaces}
    });

    dialogRef.afterClosed().subscribe(result => {
      debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:editMyLocation::afterClosed:result: ',result);
      this.userPlaces=result;
      // this.myPositionSvc.setCurrentPosition();
    });
  }

  displayFn(userPlaceW?: any): string | undefined {
    debuglog(RxJsLoggingLevel.DEBUG, 'search-user-location:displayFn::l userPlace',userPlaceW);
    // if(userPlaceW !== undefined){
    //   this.myPositionSvc.setCurrentPosition(userPlaceW);
    //   this.showPositionCheckButtons = false;
    // }
    return userPlaceW ?( userPlaceW.attributes.label.length > 0?userPlaceW.attributes.label:userPlaceW.attributes.place ): '';
  }

}
