import { Component, OnInit,OnDestroy, Inject } from '@angular/core';
import { UserService } from '../api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormGroup,FormControl, Validators } from '@angular/forms';
// import Validation from './utils/validation';
import { AlertService } from '../api/alert.service';
import { Alert,AlertRedmption } from '../api/alert';
import { AuthService } from '../core/auth.service';
import { first } from 'rxjs/operators';
import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { Editor } from 'ngx-editor';
import {Frequency, Options, RRule,rrulestr} from 'rrule';
import { WINDOW } from '../core/window.providers';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-alert-crud',
  templateUrl: './alert-crud.component.html',
  styleUrls: ['./alert-crud.component.css']
})
export class AlertCrudComponent implements OnInit,OnDestroy {
  alert: any;
  id: number|undefined;
  locationId: number|undefined;
  isAddMode: boolean|undefined;
  qrvalue:string='';
  alertDummy:Alert={
    id:undefined,
    location:undefined,
    location_id:undefined,
    msg : '',
    url: '',
    date_end: '',
    date_campaign_start: '',
    description: '',
    is_qr_redeem: false,
    date_special_start: '',
    redemption_max: 0,
    redemption_current_count:0,
    is_multiple_redeem:false,
    alertRedemptions:[],
    duration_hours: 0,
    duration_minutes: 0,
    rrule: null

  };
  alertFrm: FormGroup = new FormGroup({
    msg: new FormControl(''),
    url: new FormControl(''),
    date_end: new FormControl<string | null>(null),
    date_campaign_start: new FormControl<string | null>(null),
    description: new FormControl(''),
    date_special_start: new FormControl(''),
    duration_hours: new FormControl(''),
    duration_minutes: new FormControl(''),
    redemption_max: new FormControl(''),
    is_qr_redeem: new FormControl(false),
    is_multiple_redeem: new FormControl(false),
    start: new FormControl<string | null>(null),
    end: new FormControl<string| null>(null),
    frequncy: new FormControl<string| null>(null),
    onWeekday: new FormControl(this.fb.array(
      [false, false, false, false, false, false, false].map(val => this.fb.control(val)))
    ),
    // onMonthday: [this.alertFrm.value.date_special_start?this.alertFrm.value.date_special_start:this.alertFrm.value.date_campaign_start?this.alertFrm.value.date_campaign_start:null]

    onMonthday: new FormControl<string| null>(null),
    weekdays: new FormControl(''),
  });
  range = new FormGroup({
    start: new FormControl<string | null>(null),
    end: new FormControl<string| null>(null),
  });
  recurringForm: FormGroup;
  private today: Date=new Date();
  submitted = false;
  alerts: Array<any>=[];
  location: Location|undefined;
  alertRedemption: Array<AlertRedmption>=[];
  backRoutePath:string='/alert-manage';


  categoryList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  editor: Editor;
  description:string='';
  Frequency = Frequency;
  weekdayMap = [
    RRule.MO,
    RRule.TU,
    RRule.WE,
    RRule.TH,
    RRule.FR,
    RRule.SA,
    RRule.SU
  ];
  oneTime:string='-1';
  _baseRefSelection:string = "default";
  _customRef:string = "";


  constructor(
    public auth: AuthService,
    public userSvc: UserService,
    private alertSvc: AlertService,
    private router: Router,
    private aR: ActivatedRoute,
    private fb: FormBuilder,
    @Inject(WINDOW) private window: Window,
  ) {
    this.setupFormGroup(this.alertDummy);
    this.alert={'attributes':this.alertDummy};
    debuglog(RxJsLoggingLevel.DEBUG,'alert-crud::constructor: alert',this.alert, this.alertFrm);

  }

  ngOnInit():void {
    // this.alertSvc.getAlerts()
    //   .subscribe(res=> this.alerts = res);

    this.editor = new Editor();
    this.editor.setContent(this.description);
    debuglog(RxJsLoggingLevel.DEBUG,'alert-crud::ngOnInit: editor',this.editor);
    this.id = +this.aR.snapshot.params['id'];
    debuglog(RxJsLoggingLevel.DEBUG,'alert-crud::ngOnInit: id',this.id);
    // this.qrvalue=JSON.stringify({uid: this.auth.currentUserId , alert_id:this.id});
    this.updateQR();
    this.isAddMode = !this.id;
    this.locationId = +this.aR.snapshot.params['location_id'];
    if(this.locationId){
      this.backRoutePath='/location-crud/'+this.locationId;
    }

    if (!this.isAddMode) {
      let sub=this.alertSvc.getAlert(this.id)
        .pipe(first())
        .subscribe(x => {
          this.alert=x;
          this.description=this.alert.attributes.description;
          this.alert.attributes.start=this.alert.attributes.date_campaign_start;
          this.alert.attributes.end=this.alert.attributes.date_end;
          this.alert.attributes.duration=0;
          this.alert.attributes.frequency='-1';
          if(this.alert.attributes.rrule){
            let rrule=rrulestr(this.alert.attributes.rrule);
            this.alert.attributes.frequency=rrule.options.freq;
            this.alert.attributes.weekdays=rrule.options.byweekday;
          }
          // this.alert.attributes.frequency=Frequency.DAILY;

          this.editor.setContent(this.description);
          debuglog(RxJsLoggingLevel.DEBUG,'alert-crud::addAlert: aalert',this.alert);
          this.alertFrm.patchValue(x.attributes);
          this.range.patchValue(x.attributes);
          sub.unsubscribe();
        });

    }else{
      this.alert={'attributes':this.alertDummy};
      this.setupFormGroup(this.alertDummy);
    }

  }

  get baseRefSelection(): string {
    return this._baseRefSelection;
  }
  set baseRefSelection(value: string) {
    this._baseRefSelection = value;
    this.updateQR();
  }

  get customRef(): string {
    return this._customRef;
  }
  set customRef(value: string) {
    this._customRef = value;
    this.updateQR();
  }

  private updateQR(): void {
    // this.goal.name = this._goalOption === "other" ? this._customGoalName : this._goalOption;
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::constructor: domain',this.window.location);
    let baseUrl=environment.appUrl;
    const regex=/cszEvt=([^&]*)/;
    const regex_uid=/uid=([^&]*)/;
    debuglog(RxJsLoggingLevel.DEBUG,'alert-detail::constructor: cszEvt',this.window.location.search||false);
    let re =/\?/;
    switch(this._baseRefSelection){
      case 'custom':
        if(this._customRef.search(re)== -1){
          // no ? present
          this.qrvalue=this._customRef +'?cszEvt='+this.id;
        }else{
          this.qrvalue=this._customRef +'&cszEvt='+this.id
        }
        break;
      default:
        if(baseUrl.search(re)== -1){
          // no ? present
          this.qrvalue=baseUrl +'?cszEvt='+this.id;
        }else{
          this.qrvalue=baseUrl +'&cszEvt='+this.id
        }

    }
    // let baseUrl=this.window.location.href;




  }

  public setupFormGroup(alert:Alert){
    if(alert!= null){
      this.alertFrm = this.fb.group({
        'msg' : [alert['msg'], Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(150)])],
        'url' : [alert['url'], Validators.compose([Validators.pattern(urlPattern)])],
        'date_campaign_start': [  alert['date_campaign_start'], Validators.compose([Validators.required])],
        'date_end' : [alert['date_end'], Validators.compose([Validators.required])],
        'description' : [''+alert['description']], //, Validators.compose([])
        'is_qr_redeem' : [!alert['is_qr_redeem']?'false':'true', Validators.compose([])],
        'date_special_start' : [{value: alert['date_special_start'], disabled: false}, Validators.compose([Validators.required, ])],
        'redemption_max' : [alert['redemption_max'], Validators.compose([ Validators.pattern('[1-9].[0-9]*')])],
        'duration_hours' : [alert['duration'], Validators.compose([  Validators.pattern('[1-9]*')])],
        'duration_minutes' : [alert['duration_minutes'], Validators.compose([  Validators.pattern('[1-9]*')])],
        // 'redemption_current_count' : [alert['redemption_current_count'], Validators.compose([Validators.pattern(urlPattern)])],
        'is_multiple_redeem' : [!alert['is_multiple_redeem']?'false':'true', Validators.compose([Validators.pattern('[0-9]*')])],
        'frequency': [Frequency.DAILY],
        'onWeekday': this.fb.array(
          [false, false, false, false, false, false, false].map(val => this.fb.control(val))
        ),
        // onMonthday: [this.alertFrm.value.date_special_start?this.alertFrm.value.date_special_start:this.alertFrm.value.date_campaign_start?this.alertFrm.value.date_campaign_start:null]

        'onMonthday': [new Date],
        'weekdays':['']

      });
      this.range = this.fb.group({
        'start': [ alert['date_campaign_start'], Validators.compose([Validators.required])],
        'end' : [alert['date_end'], Validators.compose([Validators.required])],
      });

    }else{
      this.alertFrm = this.fb.group({
        'msg' : ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'url' : ['', Validators.compose([Validators.required, Validators.minLength(10)])],
        'date_end' : [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(10)])],
        'date_campaign_start' : [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(10)])],
        'description' : ['<p>hi</p>', Validators.compose([Validators.required, Validators.minLength(5)])],
        'date_special_start' : [{value: null, disabled: false}, Validators.compose([Validators.required, Validators.minLength(10)])],
        'redemption_max' : [null, Validators.compose([Validators.required, Validators.pattern('[1-9].[0-9]*')])],
        'duration' : [null, Validators.compose([ Validators.pattern('[1-9].[0-9]*')])],
        // 'redemption_current_count' : [null, Validators.compose([ Validators.pattern(urlPattern)])],
        'is_multiple_redeem' : ['false', Validators.compose([Validators.pattern(urlPattern)])],
        'is_qr_redeem' : ['false', Validators.compose([Validators.pattern(urlPattern)])],
        'start': [ {value: alert['date_campaign_start'], disabled: false}, Validators.compose([Validators.required])],
        'end' : [{value: alert['date_end'], disabled: false}, Validators.compose([Validators.required])],
        'frequency': [Frequency.DAILY],
        'onWeekday': this.fb.array(
          [false, false, false, false, false, false, false].map(val => this.fb.control(val))
        ),
        // onMonthday: [this.alertFrm.value.date_special_start?this.alertFrm.value.date_special_start:this.alertFrm.value.date_campaign_start?this.alertFrm.value.date_campaign_start:null]

        'onMonthday': [new Date],
        'weekdays':['']

      });
      this.range = this.fb.group({
        'start': [ {value: null, disabled: false}, Validators.compose([Validators.required])],
        'end' : [{value: null, disabled: false}, Validators.compose([Validators.required])],
      });
      this.recurringForm = this.fb.group({

        frequency: [Frequency.DAILY],
        onWeekday: this.fb.array(
          [false, false, false, false, false, false, false].map(val => this.fb.control(val))
        ),
        // onMonthday: [this.alertFrm.value.date_special_start?this.alertFrm.value.date_special_start:this.alertFrm.value.date_campaign_start?this.alertFrm.value.date_campaign_start:null]

        onMonthday: [new Date]
      });

    }
    debuglog(RxJsLoggingLevel.DEBUG,'alert-crud::setupFormGroup:alert & forms',alert,this.alertFrm,this.range, this.recurringForm);


  }
  get f(): { [key: string]: AbstractControl } {
    return this.alertFrm.controls;
  }

  public getMonthlyText(dateTxt:string|null):string{
    if (dateTxt==null) return '(Set Event Start)';
    const dateVar= new Date(dateTxt);
    return ' on day '+dateVar.getDate()+'';
  }

  public getDailyText(dateTxt:string|null):string{
    if (dateTxt==null) return '(Set Event Start)';
    const dateVar= new Date(dateTxt);
    return ' @ '+ dateVar.getHours()+':'+dateVar.getMinutes()+'';
  }


  addAlert(alertId:number , alert: any) {
    let routePath='/alert-manage';
    let allowed= false;

    debuglog(RxJsLoggingLevel.DEBUG,'alert-crud::addAlert: alertFrm is_qr_redeem',alert['is_qr_redeem']);
    debuglog(RxJsLoggingLevel.DEBUG,'alert-crud::addAlert: alertFrm is_multiple_redeem',alert['is_multiple_redeem']);
    alert.is_qr_redeem=alert['is_qr_redeem'] ==false|| alert['is_qr_redeem']==null ? false: true;
    alert.is_multiple_redeem= (alert['is_multiple_redeem']== false || alert['is_multiple_redeem'] ==null ? false: true);
    alert.date_campaign_start=this.range.value.start;
    alert.date_end=this.range.value.end;
    if(alert.frequency==this.oneTime){
      const rule =null;
      const options= null;
      alert.rrule =null;
    }else{
      const options: Partial<Options> = {
          freq: alert.frequency || Frequency.DAILY,
          dtstart: toNativeDate(this.range.value.start || ''+this.today),
          until: toNativeDate(this.range.value.end || ''+this.today),
          byweekday: alert.frequency === Frequency.WEEKLY ?
            alert.weekdays : null,
          bymonthday: alert.frequency === Frequency.MONTHLY ?
            (alert.date_special_start && toNativeDate(alert.date_special_start).getDate() || this.today.getDate()) : null
        };
        const rule = new RRule(options);
        debuglog(RxJsLoggingLevel.DEBUG,'rruleoptions', options,rule);
        alert.rrule=rule.toString();
    }



    if(this.locationId){
      alert.location_id=this.locationId;

      if(this.userSvc.checkLocationRole(this.locationId,['owner','mangaer']) ){
        allowed=true;
      }
    }

    debuglog(RxJsLoggingLevel.DEBUG,'alrt-crud::addAlert: alertFrm',this.alertFrm.value);
    if(this.userSvc.checkAuthorization(['superadmin','admin'])||allowed){
      if (!this.isAddMode) {

        let sub=this.alertSvc.updateAlert(alert, this.id)
          .subscribe(updateAlert => {
            sub.unsubscribe();
            this.router.navigateByUrl(this.backRoutePath);

          });
          //
      } else {

        let sub=this.alertSvc.insertAlert(alert)
          .subscribe(newAlert => {
            this.alerts.push(newAlert);
            sub.unsubscribe();
            this.router.navigateByUrl(this.backRoutePath);

          });
          // sub.unsubscribe();
      }
    }

  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
  private getWeekday(byWeekday: boolean[]): any {
    const result = byWeekday
      .map((v, i) => v && this.weekdayMap[i] || null)
      .filter(v => !!v);
    return result.length ? result : null;
  }

}
export function toNativeDate(ngbDate: string): Date {
  const thisDate= new Date(ngbDate);
  return new Date(Date.UTC(thisDate.getFullYear(), thisDate.getMonth() , thisDate.getDate(),thisDate.getHours(),thisDate.getMinutes(),0,0 ));
}
const urlPattern= '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
