import { Component, Input, ViewChild, ElementRef} from '@angular/core';
import { debuglog,RxJsLoggingLevel } from '../debug.operator';
import {MatCheckboxModule} from '@angular/material/checkbox';

@Component({
  selector: 'app-sensor-field-mapper',
  templateUrl: './sensor-field-mapper.component.html',
  styleUrls: ['./sensor-field-mapper.component.css']
})
export class SensorFieldMapperComponent {

   @Input() fieldMap:any[];
   @Input() sensorId!:number;
   @Input() sensorType!:string;
   @ViewChild('sample') sampleElement: ElementRef;
   displayedColumns: string[] = [ 'is_used','friendly','external_field', ];
   dataSource = FIELD_OBJ_DUMMY;
   parseResults:ParseResult[]=[];
   constructor() { }
   public setFieldMap(map:MapperField[]|null){
     debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::setFieldMap: map',map);
     if(map==null){
       this.fieldMap=FIELD_OBJ_DUMMY;
     }else{
       let patchedMap= this.patchFieldMap(map);
       this.fieldMap=patchedMap;
     }
   }
   public getFieldMap(){
     debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::getFieldMap: map',this.fieldMap);
     return this.fieldMap;
   }
   boxClick(completed: boolean,index:number){
     this.fieldMap[index].iu=completed;
     debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::boxClick: ',completed,index,this.fieldMap);
   }
   onIsConditionalChange(e:Event, i:number){
     this.fieldMap[i].ic= !this.fieldMap[i].ic;
   }
   onBlurEvent(event:any,index:number){
     let selectText:string=event.target.value;
     let [path,value]=selectText.split('>>' );
     this.fieldMap[index].ef=event.target.value;
     this.fieldMap[index].ep=path.trim();
     debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::onBlurEvent: ',event,selectText,index,this.fieldMap);
   }
   onWhereBlurEvent(event:any,index:number){
     let selectText:string=event.target.value;
     let [path,value]=selectText.split('>>' );
     this.fieldMap[index].wf=event.target.value;
     this.fieldMap[index].wp=path.trim();
     this.fieldMap[index].wv=value.trim();
     debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::onWhereBlurEvent: ',event,selectText,index,this.fieldMap);
   }
   onWhereValueBlurEvent(event:any,index:number){
     this.fieldMap[index].wv=event.target.value.trim();
     debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::onWhereValueBlurEvent: ',event,event.target.value,index,this.fieldMap);
   }
   onBlurSelectEvent(event:any,index:number){
     let selectText:string=event.target.innerText;
     debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::onBlurSelectEvent:  top',event.srcElement.attributes.placeholder.nodeValue,selectText,index,this.fieldMap);
     if(selectText !== event.srcElement.attributes.placeholder.nodeValue){
       let [path,value]=selectText.split('>>' );
       this.fieldMap[index].ef=event.target.innerText;
       this.fieldMap[index].ep=path.trim();
       debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::onBlurSelectEvent: ',event,selectText,index,this.fieldMap);
     }

   }
   onWhereBlurSelectEvent(event:any,index:number){
     let selectText:string=event.target.innerText;
     if(selectText !== event.srcElement.attributes.placeholder.nodeValue){
       let [path,val]=selectText.split('>>' );
       debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::onWhereBlurSelectEvent start: ',event,selectText,index,path,val);
       this.fieldMap[index].wf=event.target.innerText;
       this.fieldMap[index].wp=path.trim();
       this.fieldMap[index].wv=val.trim();
       debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::onWhereBlurSelectEvent: ',event,index,path,val,this.fieldMap);
     }
   }
   clearSample(e:Event){
     e.stopPropagation();
     this.parseResults=[];
     this.sampleElement.nativeElement.value='';
   }
   procesSample(e:Event){
     e.stopPropagation();
     let sampleObj= JSON.parse(this.sampleElement.nativeElement.value);
     // https://dev.to/pffigueiredo/typescript-utility-keyof-nested-object-2pa3
     // https://stackblitz.com/edit/angular-ivy-qadvlr?file=src%2Fapp%2Fapp.component.html
     this.recursiveSwitch({key:null, valueType:this.getType(sampleObj),value:sampleObj, path:'', pathSpecific:''});
   }
   // , parentType:string
   objectParse(obj:object, path:string, pathSpecific:string){
     Object.entries(obj)
    .forEach(([key, value]) => {
      this.recursiveSwitch({key:key,valueType:this.getType(value),value:value, path:(path.length?path+'.':'')+key,pathSpecific:(pathSpecific.length?pathSpecific+'.':'')+key});
    });
   }
   arrayParse(value:Array<any>, path:string,pathSpecific:string){
     value.map((child:any,index:number)=>{
       this.recursiveSwitch({key:null,valueType:this.getType(child), value:child, path:(path.length?path:'')+'[]', pathSpecific: (pathSpecific.length?pathSpecific:'')+'['+index+']' })
     });
   }
   recursiveSwitch({key, valueType,value, path, pathSpecific}){
     // output key
     switch(valueType){
       case 'array':
          this.arrayParse(value, path, pathSpecific);
          break;
       case 'object':
          this.objectParse(value,path,pathSpecific);
          break;
       default:
          // end node.. create parseResults objects
          let pathNew=(path.length?path:'');
          let pathSpecificNew=(pathSpecific.length?pathSpecific:'');
          // let pathNew=(path.length?path:'')+(path.length && key !==null?+'.':'')+(key !==null ?key:'');
          // let pathSpecificNew=(pathSpecific.length?pathSpecific:'')+(pathSpecific.length && key !==null?+'.':'')+(key !==null ?key:'');
          const parseResult:ParseResult={
            path: pathNew ,
            pathSpecific:pathSpecificNew,
            val:value,
            displayTxt:pathSpecificNew+' >> '+value
          };
          this.parseResults.push(parseResult);
          debuglog(RxJsLoggingLevel.DEBUG,'sensor-field-mapper::recursiveSwitch: endpoint parseResults',this.parseResults);
          break;
     }

   }
   getType(val: string|boolean|Array<any>|number|object) {
      if (Array.isArray(val)) {// aray's will be type of "object" so need specail cases, and possibly others but this is a good start
        return 'array';
      } else if (typeof val === 'string' || val instanceof String) {
        return 'string';
      } else if (typeof val === 'number' || val instanceof Number) {
        return 'number';
      } else if (typeof val === 'boolean') {
        return 'boolean';
      } else if (typeof val === "object") {
        return 'object'
      }else{
        return 'unknown'
      }
    }
    patchFieldMap(fieldMap):MapperField[]{
      for (const key in FIELD_OBJ_DUMMY[0]) {
        for(let i=0; i<4; i++){
          if(! (key in fieldMap[i])){
            fieldMap[i][key]=FIELD_OBJ_DUMMY[0][key];
          }
        }

      }
      return fieldMap;
    }

}
export interface MapperField {
  fd: string; // field
  ef: string; // external_field
  fr: string; // friendly
  iu: boolean; // is_used
  ic: boolean; // is_conditional
  wf: string|ParseResult; // where_field
  ep:string; // external_path
  wp:string; // where_path
  wv:string; // where_value
}
export interface ParseResult {
  path: string;
  pathSpecific: string;
  val: string|number;
  displayTxt: string;
}
const FIELD_OBJ_DUMMY: MapperField[] = [
  {fd:'occupany',ef:'', fr:'Occupancy',iu:false,ic:true,wf:'',ep:'',wp:'',wv:''},
  {fd:'flow_in',ef:'', fr:'Inbound Traffic',iu:false,ic:true,wf:'',ep:'',wp:'',wv:''},
  {fd:'flow_out',ef:'', fr:'Outbound Traffic',iu:false,ic:true,wf:'',ep:'',wp:'',wv:''},
  {fd:'flow_net',ef:'', fr:'Net Traffic',iu:false,ic:true,wf:'',ep:'',wp:'',wv:''},
];
