<div  #svgIcon>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="svg-icon">
    <title>{{this.loc.name}}</title>
    <g id="pin" data-name="Pin" #Pin>
  		<path #Pinpath d="M25.0464,8.4834a10,10,0,0,0-7.9116-5.4258,11.3644,11.3644,0,0,0-2.2691,0A10.0027,10.0027,0,0,0,6.9536,8.4829a10.8062,10.8062,0,0,0,1.481,11.8936l6.7929,8.2588a1,1,0,0,0,1.545,0l6.7929-8.2588A10.8055,10.8055,0,0,0,25.0464,8.4834ZM16,17a4,4,0,1,1,4-4A4.0047,4.0047,0,0,1,16,17Z" style="fill:#262628;"/>
  	</g>
  	<g id="star" data-name="Star" #Star transform="translate(7,4)">
  		<path #Starpath d="m56,237 74-228 74,228L10,96h240"   style="transform: scale(.07);
          -ms-transform: scale(.07);
          -webkit-transform: scale(.07);fill:yellow;display:{{this.loc.alerts.length>0?'block':'none'}}" />
  	</g>
  </svg>
</div>
