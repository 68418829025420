<h1 mat-dialog-title>Delete Room</h1>

<div mat-dialog-content>
  <p>Are you sure you want to delete:</p>
  <p>{{data['room'].name}}</p>


</div>
<div mat-dialog-actions>
  <button mat-button (click)="delete()">Delete</button>
  <button mat-button (click)="cancel()">Cancel</button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>
