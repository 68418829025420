<div fxLayout="row" fxLayoutAlign="space-between" fxLayout.xs="column" fxFlexFill>
  <div fxFlex="80" fxFlex.xs="60">
    <div class="mat-elevation-z8" fxLayout="column" fxFlexFill>

      <div class="location-container" *ngIf="showInput">
        <form [formGroup]="joinFrm" (ngSubmit)="checkCode(joinFrm.value)" *ngIf="showInput">
          <div class="row expanded">
            <div  layout="column" layout-align="center end">
              <div>
                <h1>Join Your Team
                </h1>
                <br />
                <label>Invite Code
                  <input type="text" name="link_id" [formControl]="joinFrm.controls['link_id']" formControlName="link_id">
                </label>
                <input type="submit" class="cta-btn filled" value="Join"  >
              </div>
            </div>
          </div>
        </form>
      </div>


      <div class="alert" *ngIf="showWelcome">
        <p>Welcome to the <strong>{{teamEntry.location.name}}</strong> team, {{me.email}}</p>
      </div>


      <div class="alert" *ngIf="showStatus">
        <p>{{statusMsg}}</p>
      </div>
    </div>
  </div>
</div>
