<div fxLayout="column" fxLayoutAlign="space-around center" >
  <div layout="row" layout-wrap class="item" *ngIf="chartSelection.value== undefined||chartSelection.value=='hour'">
    <canvas baseChart
      [data]="barChartData"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [type]="'bar'">
    </canvas>
  </div>
  <div layout="row" layout-wrap class="item" *ngIf="chartSelection.value=='dow'">
    <canvas baseChart
      [data]="barChartDataDow"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [type]="'bar'">
    </canvas>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-around center" >
    <div layout="row" layout-wrap class="item">
      <mat-button-toggle-group name="chartSelect" #chartSelection="matButtonToggleGroup" aria-label="Chart Selection" value="hour">
        <mat-button-toggle value="hour" >Hourly</mat-button-toggle>
        <mat-button-toggle value="dow">Daily</mat-button-toggle>

      </mat-button-toggle-group>
    </div>
  </div>
</div>
