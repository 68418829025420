<table mat-table [dataSource]="dsTeamMembers">

  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Team Member</th>
    <td mat-cell *matCellDef="let element"> {{element.attributes.user.name}}<br />
      {{element.attributes.user.email}}
    </td>
  </ng-container>


  <!-- Weight Column -->
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Role </th>
    <td mat-cell *matCellDef="let element"> {{element.attributes.role}} </td>
  </ng-container>


  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions <a [routerLink]="['/location/'+locationId+'/team-invite/']">
      <mat-icon>person_add</mat-icon>
    </a></th>
    <td mat-cell *matCellDef="let element" class="button-container">
      <button mat-icon-button  routerLink="/location/{{element.attributes.location_id}}/team-crud/{{ element.id }}"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button  (click)="deleteTeamMember(element.attributes.location_id,element.attributes)"><mat-icon>delete</mat-icon></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
