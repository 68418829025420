import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { User,Roles } from '../core/user';
// import { Observable, of } from 'rxjs';
// import { switchMap } from 'rxjs/operators';
// import { LocationList } from './location-list';
import { AuthService } from '../core/auth.service';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { Subject, shareReplay } from 'rxjs';
import { environment } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  myRoles:any= {'superadmin':false, 'admin': false, 'owner':false, 'manager': false, 'worker':false,'is_anonymous':true, 'registered': false};
  me:User;
  myLocationTeamsInvert=[];
  public meSubject = new Subject<User>();
  public me$ = this.meSubject.asObservable();
  public meCompleteSubject = new Subject<boolean>();
  public meComplete$ = this.meCompleteSubject.asObservable();
  public myRolesSubject = new Subject<any>();
  public myRoles$ = this.myRolesSubject.asObservable();
  constructor(private http: HttpClient,
    private authSvc: AuthService) {
      this.meCompleteSubject.next(false);
      // this.myRolesSubject.next(this.myRoles);  // only disribute returned roles with realtime_url
      debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::Constructor auth', authSvc);
      // this.authSvc.jwt$.subscribe(jwt=>{
      authSvc.userFiltered$.subscribe(user =>{
        // if(user == undefined) return;
        debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::Constructor auth subscribe', user);
        this.getMe().subscribe(me=>{
          this.meCompleteSubject.next(true);
          let myLocationTeamsInvert=[];
          debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::Constructor myLocationTeamsInvert', this.myLocationTeamsInvert);
          me.location_teams.map(function(team,i){
            debuglog(RxJsLoggingLevel.DEBUG,'in loop location_teams pt1 ',i, team, );
            debuglog(RxJsLoggingLevel.DEBUG,'in loop location_teams pt2',myLocationTeamsInvert );
            myLocationTeamsInvert[Number(team.location_id)] = team.role;
            return team;
          });
          this.myLocationTeamsInvert = myLocationTeamsInvert;
          debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::Constructor getMe subscribe', this.me);
          this.getRoles().subscribe(roles=>{
            debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::Constructor getRoles subscribe', this.myRoles);
          });
        });
      });

    }

    getHeaders(): HttpHeaders {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + this.authSvc.currentUserJWT
      });
    }


  public refreshMe(){
    debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::refreshMe auth subscribe');
    this.getMe().subscribe(me=>{
      this.meCompleteSubject.next(true);
      let myLocationTeamsInvert=[];
      me.location_teams.map(function(team,i){
        debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::refreshMe in loop location_teams refresh',i, team,myLocationTeamsInvert  );
        myLocationTeamsInvert[Number(team.location_id)] = team.role;
        return team;
      });
      this.myLocationTeamsInvert=myLocationTeamsInvert;
      debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::refreshMe getMe subscribe', this.me);
      this.getRoles().subscribe(roles=>{
        debuglog(RxJsLoggingLevel.DEBUG,'UserSvc::refreshMe getRoles subscribe', this.myRoles);
      });
    });
  }

  getUsers(criteria:any){
    const params = new HttpParams().set('filter',criteria['filter'])
    // .set('sortOrder', criteria['sortOrder'])
    .set('page_index', criteria['pageIndex'].toString())
    .set('page_size', criteria['pageSize'].toString());
    // this.lastSearchParams=criteria;
    debuglog(RxJsLoggingLevel.DEBUG,'getUsers::criteria ',criteria);
    debuglog(RxJsLoggingLevel.DEBUG,'getUsers::criteria keys ',Object.keys(criteria));
    return this.http.get<User[]>(environment.apiUrl + "/api/users/manage",{ headers: this.getHeaders(), params: params}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'UserSvc::getUsers:manage:response'),
      // map(res =>  res["data"]),
    )
  }

  getUser(id:number) {
    return this.http.get<User>(environment.apiUrl + "/api/users/"+id,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'UserSvc::getLocation:getUser:response'),
        map(res =>  res["data"]),
      );
      // .subscribe(result => this.result = result.json());
  }
  getMe() {
    return this.http.get<User>(environment.apiUrl + "/api/users/me/",{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'UserSvc::me:response'),
        map(res =>  res["data"][0]),
        map(res => this.me=res.attributes),
        shareReplay(1),
        tap(res => this.meSubject.next(res) ),
      );
      // .subscribe(result => this.result = result.json());
  }
  getUserByUid(uid:string) {
    return this.http.get<User>(environment.apiUrl + "/api/users/by_uid/?uid="+uid,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'UserSvc::getLocation:getUser:response'),
        map(res =>  res["data"]),
        shareReplay(1)
      );
      // .subscribe(result => this.result = result.json());
  }

  insertUser(post: User) {

    return this.http.post<User>(environment.apiUrl + '/api/users', JSON.stringify(post), {headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  updateUser(post: User, id:number) {
    return this.http.put<User>(environment.apiUrl + '/api/users/'+id, JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::updateUser:put:response'),
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }

  getRoles() {
    return this.http.get<Roles>(environment.apiUrl + "/api/users/roles", { headers: this.getHeaders() }).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'UserSvc:getRoles:response'),
      // map(res => res["data"]),
      tap(res => this.myRoles=res),

      tap(res => this.myRolesSubject.next(res) ),
      shareReplay(1),

    )
  }
  // determines if user has matching role
  checkAuthorization(allowedRoles: string[]): boolean {
    debuglog(RxJsLoggingLevel.TRACE,'usrsvc::checkAuthorization:',this.myRoles,allowedRoles);

    for (const role of allowedRoles) {
      if (this.myRoles[role]) {
        return true
      }
    }
    return false
  }

  checkLocationRole(location_id:number,allowedRoles: string[]): boolean {
    debuglog(RxJsLoggingLevel.TRACE,'usrsvc::checkAuthorization:',this.myRoles,allowedRoles);

    for (const role of allowedRoles) {
      debuglog(RxJsLoggingLevel.TRACE,'usrsvc::checkLocationRole: loop',role,this.myLocationTeamsInvert[location_id]);
      if (this.myLocationTeamsInvert[location_id]) {
        if (this.myLocationTeamsInvert[location_id]==role ) return true
      }
    }
    return false
  }

  ///// Role-based Authorization //////

  canRead(): boolean {
    const allowed = ['admin', 'editor', 'subscriber']
    return this.checkAuthorization(allowed)
  }

  canAddLocation(): boolean {
    const allowed = ['admin', 'superadmin']
    return this.checkAuthorization(allowed)
  }
  canAddOwner(): boolean {
    const allowed = ['admin', 'superadmin']
    return this.checkAuthorization(allowed)
  }
  canAddWorker(location_id:number): boolean {
    //wil be more complex to see if manager of the particular location
    const allowed = ['admin', 'superadmin']
    return this.checkAuthorization(allowed)||this.checkLocationRole(location_id,['owner','manager'])
  }
  canManageTeam(location_id:number): boolean {
    //wil be more complex to see if manager of the particular location
    const allowed = ['admin', 'superadmin']
    return this.checkAuthorization(allowed)||this.checkLocationRole(location_id,['owner','manager'])
  }
  canAddManager(location_id:number): boolean {
    //wil be more complex to see if manager of the particular location
    const allowed = ['admin', 'superadmin']
    return this.checkAuthorization(allowed)||this.checkLocationRole(location_id,['owner'])
  }
  canEditLocation(): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(allowed)
  }
  canCreateAlert(): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(allowed)
  }
  canCreateFlash(): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(allowed)
  }
  canManageUsers(): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(allowed)
  }
  canEditProfile(): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(allowed)
  }
  canRedeemAlert(): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(allowed)
  }
  canDelete(): boolean {
    const allowed = ['admin']
    return this.checkAuthorization(allowed)
  }
  canFavorite(): boolean {
    const allowed = ['admin']
    return  this.authSvc.authenticated && this.authSvc.isUserAnonymousLoggedIn? false :true
  }
}
