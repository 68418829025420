import { Component, OnInit, ElementRef,  } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { User } from '../core/user';
import { AuthService } from '../core/auth.service';
import { Observable, of,} from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import {MatDialog, } from '@angular/material/dialog';
import { LocationDeleteComponent } from '../location-delete/location-delete.component';
// import { MatProgressSpinner } from '@angular/material/progress-spinner';
// import { MatInput } from '@angular/material/input';
// import { PageEvent,  MatPaginator } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { ViewChild } from '@angular/core';
import { MyPositionService,MyPositionData } from '../core/my-position.service';
import { LocationService } from '../api/location.service';
import { UserService } from '../api/user.service';
import { Location,LocationWrapper } from '../api/location';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';

import { BehaviorSubject, Subscription } from 'rxjs';
import { ChromaService } from '../core/chroma.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { UiStateService } from '../core/ui-state.service';


@Component({
  selector: 'location-manage',
  templateUrl: './location-manage.component.html',
  styleUrls: ['./location-manage.component.css']
})
export class LocationManageComponent implements OnInit {
  myLocation: MyPositionData=null;

  myRoles:any;
  form:FormGroup;
  user: User| null| undefined;
  categories = new FormControl([]);
  categoryList: string[] = ['Bar', 'Restaurant', 'Club', 'Show', 'Other'];

  radius = new FormControl('1');
  radiusList: string[] = ['1', '5', '10', '25', '50'];
  radiusScale = new FormControl('miles');

  // for table and paging of table
  displayedColumns: string[] = ['name',  'fanscnt','heat','actions'];


  // @ViewChild(MatPaginator, {  static: false }) paginator: MatPaginator;
  @ViewChild('searchInput', {  static: false }) input: ElementRef;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20];

  // MatPaginator Output
  // pageEvent: PageEvent;
  uistateSub:Subscription=null;
  authUserSub:Subscription=null;
  rolesSub:Subscription=null;
  userFavSub:Subscription=null;
  myPositionSub:Subscription=null;
  locRTsub:Subscription=null;
  locs:LocationWrapper[]=[];
  private locsIndex:number[];
  dataSourceMat: MatTableDataSource<LocationWrapper>;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  filterStr:string='';
  searchTotal:number;
  pageIndex:number=0;
  durationInSeconds = 5;

  constructor(
    public auth: AuthService,
    private locationSvc: LocationService,
    private userSvc: UserService,
    private myPositionSvc: MyPositionService,
    private chroma: ChromaService,
    public dialog: MatDialog ,
    private _snackBar: MatSnackBar,
    private uiStateSvc: UiStateService,
  ) { }

  ngOnInit() {
    // to lockdown particular functionality in Component

    this.authUserSub=this.auth.user$.subscribe(user => {
      this.user=user;
      debuglog(RxJsLoggingLevel.DEBUG,'location-manage::init: user',user);
      // this.userSvc.myRoles$.subscribe(results=>{
        debuglog(RxJsLoggingLevel.DEBUG,'location-manage::init: myRole',this.userSvc.myRoles);
        this.myRoles= this.userSvc.myRoles;
        if(this.myLocation==null){
          this.loadViewMat(
            Number(this.radius.value),
            this.radiusScale.value !== null?this.radiusScale.value:'miles',
            // this.categories.value,

            // this.myLocation,
            // '',
            // sortDirection,
            this.filterStr,
            0, 10);
        }
        this.myPositionSub=this.myPositionSvc.getCurrentPositionObservable().subscribe(loc => {
          this.myLocation = loc;
          this.loadViewMat(
            Number(this.radius.value),
            this.radiusScale.value !== null?this.radiusScale.value:'miles',
            // this.categories.value,

            // this.myLocation,
            // '',
            // sortDirection,
            this.filterStr,
            0, 10);
        });

      // }); // roles

    });
    this.form = new FormGroup({
      name: new FormControl('default'),
      address: new FormControl(),
    });


  }

  ngAfterViewInit() {
    // server-side search TODO
    // fromEvent(this.input.nativeElement, 'keyup')
    //   .pipe(
    //     debounceTime(150),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       this.paginator.pageIndex = 0;
    //       this.loadView();
    //     })
    //   )
    //   .subscribe();
    // this.paginator.page
    //   .pipe(
    //     tap(() => this.loadView())
    //   )
    //   .subscribe();

  }

  loadViewMat(radius = Number(this.radius.value),
   scale: string = this.radiusScale.value !== null?this.radiusScale.value:'miles',
  // categories: [],
   filter = this.filterStr,
    // sortDirection = 'asc',
    pageIndex = this.pageIndex, pageSize = this.pageSize) {

    this.loadingSubject.next(true);
    // filter, sortDirection,pageIndex, pageSize
    let lastParams = this.locationSvc.getLastSearchParams();
    let locQuerySub = this.locationSvc.getLocationsByUser({
      radius: radius,
      scale: scale,
      categories: [],
      longitude:null,
      latitude: null,
      view: 'all',
      filter: filter,
      // sortDirection: sortDirection,
      pageIndex: pageIndex,
      pageSize: pageSize
    }).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)),
      debug(RxJsLoggingLevel.DEBUG, "Loading locations from backend")
    ).subscribe(result => {
      // create location index from results
      debuglog(RxJsLoggingLevel.DEBUG, 'location-manage:LocationsDataSource::loadview:subscribe: before locsIndex', result,this.locsIndex);
      let locsIndex:Array<number> =[];
      debuglog(RxJsLoggingLevel.DEBUG, 'location-manage:LocationsDataSource::loadview:subscribe: after locsIndex', this.locsIndex);
      debuglog(RxJsLoggingLevel.DEBUG, 'location-manage:LocationsDataSource::loadview:results: locsIndex',locsIndex);
      // map in favorites to locations results

      debuglog(RxJsLoggingLevel.DEBUG, 'location-manage:loadview:results::LocationsDataSource enhanced locations', result);

      this.locsIndex=locsIndex;
      this.searchTotal = result.total;
      let locations=result.locations.data;
      // this.locs.splice(pageIndex*pageSize,result.locations.data.length, ...result.locations.data);
      this.locs=locations;
      this.locsIndex=locsIndex;
      debuglog(RxJsLoggingLevel.DEBUG, 'location-manage:LocationsDataSource::loadview:results: locsIndex', this.locsIndex);
      // this.locationsSubject.next(this.locs);
      this.dataSourceMat = new MatTableDataSource(this.locs);
      this.dataSourceMat.filterPredicate = (data: LocationWrapper, filter: string) => data.attributes.name.toLowerCase().indexOf(filter) != -1;
      // this.dataSourceMat.paginator = this.paginator;
      locQuerySub.unsubscribe();
    });
    // locQuerySub.unsubscribe();

  }

  getcolor(heat:number) {
    let color = this.chroma.getHeatColor(heat);
    debuglog(RxJsLoggingLevel.TRACE, 'getcolor ' + heat, color);
    return color;
  }

  addWorker(locationId:number){
    if (this.userSvc.canAddWorker(locationId)){
      // do the dang thing
    }
  }
  deleteLocation(locW:LocationWrapper):void{
    const dialogRef = this.dialog.open(LocationDeleteComponent, {
      width: '250px',
      data: {loc: locW.attributes}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.pageChanged(this.pageIndex);
      // let snackBarRef = this._snackBar.openFromComponent(AlertSnackComponent, {
      let snackBarRef =   this._snackBar.open(locW.attributes.name +  ' Deleted', '',{
        duration: this.durationInSeconds * 1000,
        // data: alertW
      });
      snackBarRef.onAction().subscribe(() => {

      });
      snackBarRef.afterDismissed().subscribe(() => {


      });
      this.uiStateSvc.trackInteraction('location_delete', 'location', locW.attributes.name, locW.attributes.id,undefined, {
          location_id:locW.attributes.id,
          name:locW.attributes.name,
      });

      debuglog(RxJsLoggingLevel.DEBUG, 'location-manage:DeleteLocationComponent::afterClosed:result: ',result);

    });
  }
  search():void{
    const filter = this.input.nativeElement.value ;
    this.filterStr=filter.trim().toLowerCase();
    debuglog(RxJsLoggingLevel.DEBUG, 'Alert-manage::search: input', this.input);
    this.dataSourceMat.filter=this.filterStr;
    this.loadViewMat(
      Number(this.radius.value),
      this.radiusScale.value !== null?this.radiusScale.value:'miles',

      this.filterStr,
      // sortDirection,
      this.pageIndex, this.pageSize
    );
  }
  onRowClicked(row:number) {
    debuglog(RxJsLoggingLevel.DEBUG, 'Row clicked: ', row);
  }
  ngOnDestroy(): void {
    // if (this.rolesSub !==null)this.rolesSub.unsubscribe();
    if (this.authUserSub !==null)this.authUserSub.unsubscribe();
    // if (this.userFavSub !==null)this.userFavSub.unsubscribe();
    if (this.myPositionSub !==null)this.myPositionSub.unsubscribe();
    // if (this.locRTsub !==null)this.locRTsub.unsubscribe();
    // if (this.uistateSub !==null)this.uistateSub.unsubscribe();
    // unsubscribe to ensure no memory leaks
    // this.subscriptionAlerts.unsubscribe();
    // this.subscriptionLocationUpdates.unsubscribe();
  }
  pageChanged(event){
    // this.loading = true;

    let previousIndex = this.pageIndex;
    let pageIndex = event;
    let pageSize = this.pageSize;
    this.pageIndex = event;
    // this.pageSize = event.pageSize;



    let previousSize = this.pageSize * previousIndex;

    this.loadViewMat(Number(this.radius.value),
    this.radiusScale.value !== null?this.radiusScale.value:'miles' ,this.filterStr, pageIndex, pageSize);
  }
  get lastPage() {
    return Math.floor((this.searchTotal-1)/this.pageSize);
  }
}
