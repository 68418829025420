import { Injectable } from '@angular/core';
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http';
import { LocationSearchParams } from '../api/location-search-params';
import { map,tap } from 'rxjs/operators';
import { Location, Door,Room,RoomSensor } from './location';
import { Observable,of } from 'rxjs';
import { switchMap, shareReplay} from 'rxjs/operators';
import { LocationList} from './location-list';
import { AuthService} from '../core/auth.service';
import { TeamMember,TeamMemberWrapper,TeamInvite} from '../core/user';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {


  result:any;
  lastSearchParams:LocationSearchParams;

  // constructor(private _http: Http,private _httpClient: HttpClient) { }
  constructor(private http: HttpClient,
    private authSvc: AuthService) {
  }
  // 'Authorization': 'JWT ' + this.authService.currentUserJWT()

  getHeaders():HttpHeaders{
    return new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + this.authSvc.currentUserJWT
    });
  }

  getLocations() {
    return this.http.get(environment.apiUrl + "/api/locations", {headers: this.getHeaders()})
      .subscribe(result => this.result = result);
  }
  loadView(criteria:LocationSearchParams):Observable<Location[]>  {
    let params = new HttpParams().set('radius',criteria['radius']).set('scale',criteria['scale']).set('categories',criteria['categories'].toString()).set('filter', criteria['filter'])
    // .set('sortOrder', criteria['sortOrder'])

    .set('pageIndex', criteria['pageIndex'].toString())
    .set('pageSize', criteria['pageSize'].toString())
    .set('position_latitude', criteria['latitude'])
    .set('position_longitude', criteria['longitude']);
    if(criteria['latitude']!== null && criteria['longitude']!== null){
      debuglog(RxJsLoggingLevel.DEBUG,'loadView::found latitude | ',criteria['longitude']);
      params.set('position_latitude', criteria['latitude'])
      .set('position_longitude', criteria['longitude']);
    }

    this.lastSearchParams=criteria;
    debuglog(RxJsLoggingLevel.DEBUG,'loadView::params ',criteria);
    debuglog(RxJsLoggingLevel.DEBUG,'loadView::params keys ',Object.keys(criteria));
    // Object.keys(criteria).forEach(key => {
    //   params.set(key,criteria[key])
    //   console.log('loadView::body set'+key,criteria[key]);
    // });
    debuglog(RxJsLoggingLevel.DEBUG,'loadView::params object',{ params});
    switch(criteria.view){
      case 'all':  {
        return this.http.get<Location[]>(environment.apiUrl + "/api/locations/",{ headers: this.getHeaders(), params: params}).pipe(

          debug(RxJsLoggingLevel.DEBUG, 'LocSvc::loadView:response'),
          shareReplay(1)
          // map(res =>  res["data"]),
      );
        break;
      }
      case 'fav': {
        return this.http.get<Location[]>(environment.apiUrl + "/locations/fav/",{ headers: this.getHeaders(), params: params}).pipe(

          debug(RxJsLoggingLevel.DEBUG, 'LocSvc::loadView:fav:response'),
          shareReplay(1)
          // map(res =>  res["data"]),
      );
        break;
      }
      case 'pop': {
        return this.http.get<Location[]>(environment.apiUrl + "/locations/pop/",{ headers: this.getHeaders(), params: params}).pipe(

          debug(RxJsLoggingLevel.DEBUG, 'LocSvc::loadView:pop:response'),
          shareReplay(1)
          // map(res =>  res["data"]),
      );
        break;
      }
      case 'hot': {
        return this.http.get<Location[]>(environment.apiUrl + "/locations/hot/",{ headers: this.getHeaders(),params: params}).pipe(

          debug(RxJsLoggingLevel.DEBUG, 'LocSvc::loadView:hot:response'),
          shareReplay(1)
          // map(res =>  res["data"]),
      );
        break;
      }
      case 'cool': {
        return this.http.get<Location[]>(environment.apiUrl + "/locations/cool/",{ headers: this.getHeaders(),params}).pipe(

          debug(RxJsLoggingLevel.DEBUG, 'LocSvc::loadView:cool:response'),
          shareReplay(1)
          // map(res =>  res["data"]),
      );
        break;
      }
      default:{
        return this.http.get<Location[]>(environment.apiUrl + "/api/locations/",{ headers: this.getHeaders(), params: params}).pipe(

          debug(RxJsLoggingLevel.DEBUG, 'LocSvc::loadView:response'),
          shareReplay(1)
          // map(res =>  res["data"]),
      );
        break;
      }
    }


  }
  getLocationsByUser(criteria:LocationSearchParams){
    const params = new HttpParams().set('radius',criteria['radius']).set('scale',criteria['scale']).set('categories',criteria['categories'].toString()).set('filter', criteria['filter'])
    // .set('sortOrder', criteria['sortOrder'])
    .set('pageIndex', criteria['pageIndex'].toString())
    .set('pageSize', criteria['pageSize'].toString());
    // this.lastSearchParams=criteria;
    debuglog(RxJsLoggingLevel.DEBUG,'getLocationsByUser::params ',criteria);
    debuglog(RxJsLoggingLevel.DEBUG,'getLocationsByUser::params keys ',Object.keys(criteria));
    // debuglog(RxJsLoggingLevel.DEBUG,'getLocationsByUser::headers ',this.getHeaders());
    return this.http.get<Location[]>(environment.apiUrl + "/api/locations/manage",{ headers: this.getHeaders(), params: params}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getLocationsByUser:manage:response'),
      shareReplay(1)
      // map(res =>  res["data"]),
    )
  }

  getLocation(id:number) {
    return this.http.get(environment.apiUrl + "/api/locations/"+id,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getLocation:loc:response'),
        map(res =>  res["data"]),
        shareReplay(1)
      );
      // .subscribe(result => this.result = result.json());
  }

  getLocationFull(id:number) {
    return this.http.get(environment.apiUrl + "/api/location/full/"+id,{ headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::loadView:full:response'),
      map(res =>  res["data"]),
      shareReplay(1)
  );
      // .subscribe(result => this.result = result.json());
  }
  getLocationStats(id:number) {
    return this.http.get(environment.apiUrl + "/locations/stats/"+id,{ headers: this.getHeaders()}).pipe(
      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::loadView:stats:response'),
      // map(res =>  res["data"]),
      // shareReplay(1)
    );
      // .subscribe(result => this.result = result.json());
  }

  insertLocation(post: Location) {

    return this.http.post(environment.apiUrl + '/api/locations', JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::insertLocation:post:response'),
      // shareReplay(1)
      // map(res =>  res["data"]),
    );;
      // .subscribe(result => this.result = result.json());
  }

  updateLocation(post: Location, id:number) {
    // delete post.current_state;
    // delete post.max_cap;
    return this.http.put(environment.apiUrl + '/api/locations/'+id, JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::updateLocation:put:response'),
      // shareReplay(1)
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }
  regenToken( id:number) {
    return this.http.put(environment.apiUrl + '/api/locations/update_token/'+id, JSON.stringify({}), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::regenToken:put:response'),
      shareReplay(1)
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }
  regenKey( id:number) {
    return this.http.put(environment.apiUrl + '/api/locations/update_key/'+id, JSON.stringify({}), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::regenKey:put:response'),
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }

  deleteLocation(id:number) {
    return this.http.delete(environment.apiUrl + "/api/locations/"+id,{ headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  getLastSearchParams(){
    return this.lastSearchParams;
  }
  getDoor(locationId:number, doorId:number) {
    return this.http.get(environment.apiUrl + "/api/locations/"+locationId+"/doors/"+doorId,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getDoor:loc:response'),
        map(res =>  res["data"]),
        shareReplay(1)
      );
      // .subscribe(result => this.result = result.json());
  }
  insertDoor(post: Door, locationId:number) {

    return this.http.post(environment.apiUrl + '/api/locations/'+locationId+'/doors', JSON.stringify(post), {headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  updateDoor(post: Door, locationId:number,id:number) {
    return this.http.put(environment.apiUrl + '/api/locations/'+locationId+'/doors/'+id, JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::updateDoor:put:response'),
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }
  deleteDoor(id:number, locationId:number) {
    return this.http.delete(environment.apiUrl + "/api/locations/"+locationId+"/doors/"+id,{ headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }
  getLocationRooms(locationId:number){
    return this.http.get<Location[]>(environment.apiUrl + '/api/locations/'+locationId+'/rooms',{ headers: this.getHeaders(),}).pipe(
      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getLocationRooms:response'),
      map(res =>  res["data"]),
      shareReplay(1)
    );
  }

  insertRoom(post: Room, locationId:number) {

    return this.http.post(environment.apiUrl + '/api/locations/'+locationId+'/rooms', JSON.stringify(post), {headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  updateRoom(post: Room, locationId:number,id:number) {
    return this.http.put(environment.apiUrl + '/api/locations/'+locationId+'/rooms/'+id, JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::updateRoom:put:response'),
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }
  getRoom(locationId:number, roomId:number) {
    return this.http.get(environment.apiUrl + "/api/locations/"+locationId+"/rooms/"+roomId,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getRoom:loc:response'),
        map(res =>  res["data"]),
        shareReplay(1)
      );
      // .subscribe(result => this.result = result.json());
  }
  deleteRoom(id:number, locationId:number) {
    return this.http.delete(environment.apiUrl + "/api/locations/"+locationId+"/rooms/"+id,{ headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  insertRoomSensor(post: RoomSensor, locationId:number, roomId:number) {

    return this.http.post(environment.apiUrl + '/api/locations/'+locationId+'/rooms/'+roomId+'/room_sensors', JSON.stringify(post), {headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }

  updateRoomSensor(post: RoomSensor, locationId:number,roomId:number,id:number) {
    return this.http.put(environment.apiUrl + '/api/locations/'+locationId+'/rooms/'+roomId+'/room_sensors/'+id, JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::updateRoomSensor:put:response'),
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }
  deleteRoomSensor(id:number,  roomId:number,locationId:number) {
    return this.http.delete(environment.apiUrl + "/api/locations/"+locationId+"/rooms/"+roomId+"/room_sensors/"+id,{ headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }
  getRoomSensor(locationId:number, roomId:number, roomSensorId:number) {
    return this.http.get(environment.apiUrl + "/api/locations/"+locationId+"/rooms/"+roomId+"/room_sensors/"+roomSensorId,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getRoomSensor:loc:response'),
        map(res =>  res["data"]),
        shareReplay(1)
      );
      // .subscribe(result => this.result = result.json());
  }
  getTeam(locationId:number) {
    return this.http.get(environment.apiUrl + "/api/locations/"+locationId+"/location_teams/manage/",{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getTeam:loc:response'),
        map(res =>  res["data"]),
        // shareReplay(1)
      );
      // .subscribe(result => this.result = result.json());
  }
  getTeamMember(locationId:number,id:number) {
    return this.http.get(environment.apiUrl + "/api/locations/"+locationId+"/location_teams/"+id,{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getTeamMember:loc:response'),
        map(res =>  res["data"]),
        // shareReplay(1)
      );
      // .subscribe(result => this.result = result.json());
  }
  updateTeamMember(post: TeamMember, locationId:number,id:number) {
    return this.http.put(environment.apiUrl + '/api/locations/'+locationId+'/location_teams/'+id, JSON.stringify(post), {headers: this.getHeaders()}).pipe(

      debug(RxJsLoggingLevel.DEBUG, 'LocSvc::updateTeamMember:put:response'),
      // map(res =>  res["data"]),
    );
      // .subscribe(result => this.result = result.json());
  }
  deleteTeamMember(locationId:number,id:number) {
    return this.http.delete(environment.apiUrl + "/api/locations/"+locationId+"/location_teams/"+id,{ headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }
  getTeamInvites(locationId:number) {
    return this.http.get(environment.apiUrl + "/api/locations/"+locationId+"/location_team_invites/manage/",{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getTeamInvites:loc:response'),
        map(res =>  res["data"]),
        // shareReplay(1)
      );
      // .subscribe(result => this.result = result.json());
  }
  insertTeamInvite(post: TeamInvite, locationId:number) {

    return this.http.post(environment.apiUrl + '/api/locations/'+locationId+'/location_team_invites', JSON.stringify(post), {headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }
  deleteTeamInvite(locationId:number,id:number) {
    return this.http.delete(environment.apiUrl + "/api/locations/"+locationId+"/location_team_invites/"+id,{ headers: this.getHeaders()});
      // .subscribe(result => this.result = result.json());
  }
  getTeamInvitePublic(linkId:string) {
    return this.http.put(environment.apiUrl + "/api/location_team_invites/redeem", JSON.stringify({link_id:linkId}),{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::getTeamInvitePublic:loc:response'),
        // shareReplay(1)
        // map(res =>  res["data"]),
      );
      // .subscribe(result => this.result = result.json());
  }
  checkEmailRole(email:string,locationId:number){
    return this.http.post(environment.apiUrl + "/api/locations/"+locationId+"/location_team_invites/check_email_role/", JSON.stringify({email:email}),{ headers: this.getHeaders()}).pipe(

        debug(RxJsLoggingLevel.DEBUG, 'LocSvc::checkEmailRole::response'),

      );
  }
}
