import { Component, OnInit,Input } from '@angular/core';
import { AuthService } from '../core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {



  registerForm: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';
  @Input() hideLink!:boolean;

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.createForm();
   }
   ngOnInit() {
   }

   createForm() {
     this.registerForm = this.fb.group({
       email: ['', Validators.required ],
       password: ['',Validators.required]
     });
   }

   tryFacebookLogin(){
     this.authService.loginFacebook()
     .then(res =>{
       this.router.navigate(['/user']);
     }, err => debuglog(RxJsLoggingLevel.DEBUG, 'register::tryFacebookLogin err',err)
     )
   }

   tryTwitterLogin(){
     this.authService.loginTwitter()
     .then(res =>{
       this.router.navigate(['/user']);
     }, err => debuglog(RxJsLoggingLevel.DEBUG, 'register::tryTwitterLogin err',err)
     )
   }

   tryGoogleLogin(){
     this.authService.loginGoogle()
     .then(res =>{
       this.router.navigate(['/user']);
     }, err => debuglog(RxJsLoggingLevel.DEBUG, 'register::tryGoogleLogin err',err)
     )
   }

   tryRegister(value){
     this.authService.doRegister(value)
     .then(res => {
       debuglog(RxJsLoggingLevel.DEBUG, 'register::tryRegister',res);
       this.errorMessage = "";
       this.successMessage = "Your account has been created.";
     }, err => {
       debuglog(RxJsLoggingLevel.DEBUG, 'register::tryRegister err',err);
       this.errorMessage = err.message;
       this.successMessage = "";
     })
   }

}
