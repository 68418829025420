
<div class="user-container">
  <form [formGroup]="userFrm" (ngSubmit)="addUser(id,userFrm.value)" *ngIf="userFrm">
    <div class="row expanded">
      <div  layout="column" layout-align="center end">
        <div>
          <label>Email: {{user.attributes.email}}

          </label>
        </div>

        <div  *ngIf="user.attributes.roles">
          <label>Roles: <button *ngIf="user.attributes.roles.superadmin">Super</button> &nbsp;
            <button *ngIf="user.attributes.roles.admin">Admin</button> &nbsp;
            <button *ngIf="user.attributes.roles.owner">Owner</button> &nbsp;
            <button *ngIf="user.attributes.roles.manager">Manager</button> &nbsp;
            <button *ngIf="user.attributes.roles.worker">Worker</button> &nbsp;
            <button *ngIf="user.attributes.roles.is_anonymous">Anonymous</button> &nbsp;
            <button *ngIf="!user.attributes.roles.is_anonymous">Registered</button> &nbsp;

          </label>
        </div>



        <div *ngIf="userSvc.checkAuthorization(['superadmin']); else elseBlockIsSuperadmin">
          <div>
            <mat-slide-toggle formControlName="is_superadmin" [checked]="userFrm.get('is_superadmin').value">Super Admin</mat-slide-toggle>
            <!-- (change)="onIsPollingChange($event)" -->
          </div>

        </div>
        <ng-template #elseBlockIsSuperadmin>
          <label>Super Admin: {{user.attributes.is_superadmin}}
          </label>
        </ng-template>



        <div *ngIf="userSvc.checkAuthorization(['superadmin']); else elseBlockIsAdmin">
          <div>
            <mat-slide-toggle formControlName="is_admin" [checked]="userFrm.get('is_admin').value" >Admin</mat-slide-toggle>
            <!-- (change)="onIsPollingChange($event)" -->
          </div>

        </div>
        <ng-template #elseBlockIsAdmin>
          <label>Admin: {{user.attributes.is_admin}}

          </label>
        </ng-template>

<br />
<br />
        <input type="submit" class="cta-btn filled" value="Save" >
        <!-- [disabled]="!userFrm.valid" -->

      </div>
    </div>
  </form>
</div>
