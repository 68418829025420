<a [routerLink]="['/location-crud/'+locationId]">
  Back
</a>
<div class="location-container">
  <form [formGroup]="teamInviteFrm" (ngSubmit)="addTeamInvite(locationId,teamInviteFrm.value)" *ngIf="teamInviteFrm">
    <div class="row expanded">
      <div  layout="column" layout-align="center end">
        <div>
          <label>Email
            <input type="email" name="email" [formControl]="teamInviteFrm.controls['email']" formControlName="email">
          </label>

        </div>
        <div>
          <label for="role">Role:</label>
          <select formControlName="role" class="form-control" name="role" (change)="changeRole($event)">
            <option disabled>Select Role</option>
            <option value="worker"  *ngIf="canAddWorker();">Worker</option>
            <option value="manager" *ngIf="canAddManager();" >Manager</option>
            <option value="owner" *ngIf="canAddOwner();" >Owner</option>
          </select>

      </div>

        <input type="submit" class="cta-btn filled" value="Save" >
        <!-- [disabled]="!teamInviteFrm.valid" -->

      </div>
    </div>
  </form>
  <div>{{statusText}}</div>
  <div>
    <app-team-invite-list  [locationId]="locationId"></app-team-invite-list>
  </div>
</div>
