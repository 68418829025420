import { RealtimeSocketService } from './realtime-socket.service';
import { Roles } from './user';


import { debug,debuglog, RxJsLoggingLevel } from '../core/debug.operator';
// import { AuthService } from './auth.service';
import { UserService } from '../api/user.service';
import { environment } from './../../environments/environment';
// import {SwPush,} from "@angular/service-worker";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { DeviceLogService } from './device-log.service';
// https://github.com/angular/angular/blob/main/aio/content/examples/dependency-injection/src/app/heroes/hero.service.provider.ts
// #docregion factory
const realtimeSocketServiceFactory = (userSvc: UserService,fireMessaging: AngularFireMessaging,deviceLogSvc: DeviceLogService) =>
  new RealtimeSocketService( userSvc,fireMessaging,deviceLogSvc);
// #enddocregion factory

// #docregion provider
export const realtimeSocketServiceProvider =
  { provide: RealtimeSocketService,
    useFactory: realtimeSocketServiceFactory,
    deps: [UserService,AngularFireMessaging,DeviceLogService]
  };
