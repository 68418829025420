import { NgModule, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { WINDOW_PROVIDERS } from './window.providers';
import { BrowserModule }    from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SensorFieldMapperComponent } from './sensor-field-mapper/sensor-field-mapper.component';
import { MatTableModule} from '@angular/material/table';
import { MatInputModule} from '@angular/material/input';
import { MatSelectModule, } from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { LocationApiComponent } from './location-api/location-api.component';
import { environment } from '../../environments/environment';
import {AngularFireModule } from '@angular/fire/compat';
import { initializeApp } from "firebase/app";
import { MapIconComponent } from './map-icon/map-icon.component';

initializeApp(environment.firebase);
// import { NgxActionCableModule, NgxActionCableConfiguration } from 'ngx-actioncable';
//
//
// export function getNgxActionCableConfig(): NgxActionCableConfiguration {
//   let config = new NgxActionCableConfiguration('ws-', 'wss://localhost:3030/cable');
//   return config;
// }

// import { AlertCrudComponent } from './alert-crud/alert-crud.component';


@NgModule({
  imports: [
    CommonModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserModule,
    HttpClientModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSlideToggleModule,
    AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase, 'crowdsenz'),
    // NgxActionCableModule.forConfig(getNgxActionCableConfig)
  ],
  // declarations: [HeatcolorDirective],
  providers: [
    AuthService,
    WINDOW_PROVIDERS,
    // { provide: 'Chroma', useValue: window['chroma']() },
    // { provide: 'Uuid', useValue: window['uuidv5']() }
  ],
  declarations: [
    SensorFieldMapperComponent,
    LocationApiComponent,
    MapIconComponent
  ],
  exports: [
    SensorFieldMapperComponent,
    LocationApiComponent
  ]
})
export class CoreModule {
  // constructor( @Inject('Chroma') public chroma: any) {}
  constructor() {}
}
