import { Component, Input,  } from '@angular/core';
import { debuglog,RxJsLoggingLevel } from '../debug.operator';
import { LocationService } from '../../api/location.service';

@Component({
  selector: 'app-location-api',
  templateUrl: './location-api.component.html',
  styleUrls: ['./location-api.component.css']
})
export class LocationApiComponent {
  @Input() token:string='';
  @Input() apiKey:string='';
  @Input() locationId!:number;
  constructor(private locationSvc:LocationService){
    debuglog(RxJsLoggingLevel.DEBUG,'location-api-component::constructor: params',this.token, this.locationId);
  }
  generateToken(){
    this.locationSvc.regenToken(this.locationId).subscribe(tokenObj => {
      this.token=tokenObj.token;
      debuglog(RxJsLoggingLevel.DEBUG,'location-api-component::generateToken: token',this.token);
      return this.token;
    });
  }
  generateKey(){
    this.locationSvc.regenKey(this.locationId).subscribe(keyObj => {
      this.apiKey=keyObj.api_key;
      debuglog(RxJsLoggingLevel.DEBUG,'location-api-component::generateToken: token',this.apiKey);
      return this.token;
    });
  }

}
