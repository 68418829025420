import { Component, Input, OnInit, ElementRef } from '@angular/core';


import { User } from '../core/user';
import { AuthService } from '../core/auth.service';

import { PageEvent,  MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ViewChild } from '@angular/core';
import {MatDialog, } from '@angular/material/dialog';
import { RoomSensorDeleteComponent } from '../room-sensor-delete/room-sensor-delete.component';

import { UserService } from '../api/user.service';
import {RoomSensor,RoomSensorList } from '../api/location';
import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';

@Component({
  selector: 'app-room-sensor-manage',
  templateUrl: './room-sensor-manage.component.html',
  styleUrls: ['./room-sensor-manage.component.css']
})
export class RoomSensorManageComponent implements OnInit {
  // myLocation: MyPositionData;

  myRoles:any;

  user: User| null | undefined;
  // for table and paging of table
  displayedColumns: string[] = ['name', 'current_state','actions'];
  @Input() locationId!:Number;
  @Input() roomId!:Number;
  @Input() roomSensors!:RoomSensorList;
  @Input() dsRoomSensors: MatTableDataSource<RoomSensor>;
  @ViewChild(MatPaginator, { read: false, static: false }) paginator: MatPaginator;
  @ViewChild('input', { read: false, static: false }) input: ElementRef;
  length:number = 100;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5, 10, 20];

  // MatPaginator Output
  pageEvent: PageEvent;
  constructor(
    public auth: AuthService,
    public dialog: MatDialog ,

    private userSvc: UserService,
    // private myPositionSvc: MyPositionService,

  ) { }

  ngOnInit() {
    this.auth.user$.subscribe(user => {this.user=user;
      this.userSvc.myRoles$.subscribe(results=>{
        debuglog(RxJsLoggingLevel.DEBUG,'room-sensor-manage::init: myRole',results);
        this.myRoles= results;
      });

    })
  }
  deleteRoomSensor(roomSensor:RoomSensor):void{
    const dialogRef = this.dialog.open(RoomSensorDeleteComponent, {
      width: '250px',
      data: {roomSensor: roomSensor, locationId: this.locationId, roomId:this.roomId}
    });

    dialogRef.afterClosed().subscribe(result => {

      debuglog(RxJsLoggingLevel.DEBUG, 'room-manage:RoomSensorDeleteComponent::afterClosed:result: ',result);

    });
  }

  onRowClicked(row:number) {
    debuglog(RxJsLoggingLevel.DEBUG, 'Row clicked: ', row);
  }


}
