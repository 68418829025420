import { AfterViewInit, Component, ViewChild, OnDestroy } from '@angular/core';
import { AlertService } from '../api/alert.service';
import { AlertWrapper,Alert } from '../api/alert';
import { User } from '../core/user';
import { AuthService } from '../core/auth.service';
import { UserService } from '../api/user.service';
import {
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
  NgxScannerQrcodeService,
  NgxScannerQrcodeComponent,
  ScannerQRCodeSelectedFiles,
} from 'ngx-scanner-qrcode';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { delay } from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import { UiStateService } from '../core/ui-state.service';

@Component({
  selector: 'app-alert-redemption',
  templateUrl: './alert-redemption.component.html',
  styleUrls: ['./alert-redemption.component.css']
})
export class AlertRedemptionComponent {
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#front_and_back_camera
  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth
      },
    },
    // canvasStyles: [
    //   { /* layer */
    //     lineWidth: 1,
    //     fillStyle: '#00950685',
    //     strokeStyle: '#00950685',
    //   },
    //   { /* text */
    //     font: '17px serif',
    //     fillStyle: '#ff0000',
    //     strokeStyle: '#ff0000',
    //   }
    // ],
  };

  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
  public qrCodeResult2: ScannerQRCodeSelectedFiles[] = [];
  redemptionAlert:Alert;
  redemptionUser:User;
  redemption:any={};
  status:string;
  isRedeemSuccess:boolean|null=null;
  isScanComplete:boolean=false;
  public percentage = 80;
  public quality = 100;

  @ViewChild('action') action: NgxScannerQrcodeComponent;
  // only superadmin admin team worker, team manager
  constructor (
    // private authSvc: AuthService,
    private alertSvc: AlertService,
    private userSvc: UserService,
    private qrcode: NgxScannerQrcodeService,
    private uiStateSvc: UiStateService,
  ){}
  ngAfterViewInit(): void {
    this.action.isReady.pipe(delay(3000)).subscribe(() => {
      debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::ngAfterViewInit: action.isReady action',this.action);
      this.action.start();
    });
  }

  ngOnDestroy(): void {
    this.handle(this.action,'stop');
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
   // console.log(e);
   debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: event',e,action,e[0]['value']);
   // retreive value e.value

   // start of commented code
   const regex_alert_id=/cszEvt=([^&]*)/;
   const regex_uid=/uid=([^&]*)/;

   let redeemStr=e[0]['value'];
   try{

     // this.redemption= JSON.parse(redeemStr);
     debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: parse url enter',redeemStr,redeemStr.match(regex_alert_id),this.redemption);
     this.redemption.alert_id=parseInt(redeemStr.match(regex_alert_id)[1]);
     this.redemption.uid=redeemStr.match(regex_uid)[1];
     debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: parse url uid',redeemStr,this.redemption);

   }catch(e){
     return ;
   }
   if(this.redemption.uid == undefined || this.redemption.alert_id == undefined ) return;
   //  retrieve alert/ validate data
   this.isScanComplete=true;
   let count = 0;
   this.handle(this.action,'stop');
   this.alertSvc.getAlert(+this.redemption.alert_id).subscribe(alertW => {
     debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: get alertW',alertW);
     let isValidRedemptionWorker:boolean=false;
     let isValidRedemptionCustomer:boolean=false;
     let isValidRedemptionAlert:boolean=false;
     const now = new Date();
     this.redemptionAlert=alertW.attributes;
     const date_campaign_start = new Date(this.redemptionAlert.date_campaign_start);
     const date_special_start = new Date(this.redemptionAlert.date_special_start);
     const date_end = new Date(this.redemptionAlert.date_end);
     const begin_date= this.redemptionAlert.date_special_start?date_special_start:date_campaign_start;
     count = (this.redemptionAlert.redemption_current_count !== null?this.redemptionAlert.redemption_current_count:0);
     debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: pre validate offer this.redemptionAlert.redemption_max',this.redemptionAlert.redemption_max,'redemption_current_count',count,'now',now,'begin_date',begin_date,'end_date',date_end);
     if((this.redemptionAlert.redemption_max==0 || this.redemptionAlert.redemption_max>count) &&now>begin_date && date_end> now){
       // check alert.max_count not exceeded
       // alert not expired
       isValidRedemptionAlert= true;
       debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: open and not expred',isValidRedemptionAlert);


       this.userSvc.getUserByUid(this.redemption.uid).subscribe(user =>{

         this.redemptionUser=user[0].attributes;
         debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: getUserByUid user',user,'me',this.userSvc.me,'redemptionUser',this.redemptionUser);
         if(+this.redemptionUser['id'] !== +this.userSvc.me['id']){
           isValidRedemptionCustomer=true;
         }else {
           this.status='You cant Redeem yourself';
           this.isRedeemSuccess=false;
         }
         // has user already redeemed and not mulitple TODO

         debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: Check User valid',isValidRedemptionCustomer);
         //   if verify user is s/admin
         if(this.userSvc.me.is_admin||this.userSvc.me.is_superadmin){
           isValidRedemptionWorker=true;
         }else if(this.redemptionAlert.location_id!== null ){
           // if location id is present,
           let roleFilter=this.userSvc.me.location_teams.filter(role => role.location_id == +this.redemption.alert_id);
           if (roleFilter.length>0){
             //   if part of location team + owner
             isValidRedemptionWorker=true;
           }else{
             this.status='You dont have permissions to Redeem';
             this.isRedeemSuccess=false;
           }

         }else{
           this.status='You dont have permissions to Redeem';
           this.isRedeemSuccess=false;
         }
         debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: Check Worker valid',isValidRedemptionWorker);
         if(isValidRedemptionWorker&&isValidRedemptionCustomer&&isValidRedemptionAlert){
           const redemption={
             alert_id:+this.redemption.alert_id,
             worker_id: +this.userSvc.me.id,
             customer_id: +this.redemptionUser.id,
           };
           this.alertSvc.redeem(redemption).subscribe();
           this.status='Successful Redeem';
           this.isRedeemSuccess=true;
           debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: Success',redemption,status);
           this.uiStateSvc.trackInteraction('alert_redeem', 'alert', this.redemptionAlert.msg, this.redemption.alert_id,undefined, {
             alert_id:+this.redemption.alert_id,
             worker_id: +this.userSvc.me.id,
             customer_id: +this.redemptionUser.id,
             location_id: this.redemptionAlert.location_id?this.redemptionAlert.location_id: -1
           });
           // execute redemption
           //     make redemption call and display success
         }
       });

     }else{
       this.status='Offer expired.';
       this.isRedeemSuccess=false;
     }
     debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onEvent: final state',this.isScanComplete,'redemption',this.redemption,'worker',isValidRedemptionWorker,'Customer',isValidRedemptionCustomer,'alert',isValidRedemptionAlert);
   });
   //   else display error
 }

 public handle(action: NgxScannerQrcodeComponent, fn: string): void {
   debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::handle: action & fn',action,fn);
   const playDeviceFacingBack = (devices: any[]) => {
     debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::handle: playDeviceFacingBack devices',action,fn,devices);
     // front camera or back camera check here!
     const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
     action.playDevice(device ? device.deviceId : devices[0].deviceId);
   }

   if (fn === 'start') {
     action[fn](playDeviceFacingBack).subscribe((r: any) => debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::handle: start',fn, r), alert);
   } else {
     action[fn]().subscribe((r: any) => debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::handle:not start',fn, r), alert);
   }
  }

  public onDowload(action: NgxScannerQrcodeComponent) {
    action.download().subscribe(console.log, alert);
  }

  public onSelects(files: any) {
    this.qrcode.loadFiles(files, this.percentage, ''+this.quality).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
      this.qrCodeResult = res;
    });
  }

  public onSelects2(files: any) {
    this.qrcode.loadFilesToScan(files, this.config, this.percentage, ''+this.quality).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
      debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onSelects2 res',res);
      this.qrCodeResult2 = res;
    });
  }

  public onGetConstraints() {
    const constrains = this.action.getConstraints();
    debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::onGetConstraints: constrains',constrains);
  }

  public applyConstraints() {
    const constrains = this.action.applyConstraints({
      ...this.action.getConstraints(),
      width: 510
    });
    debuglog(RxJsLoggingLevel.DEBUG,'alert-redemption::applyConstraints: constrains',constrains);
  }

}
