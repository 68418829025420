<a [routerLink]="['/location-manage/']">
  Back
</a>
<div class="location-container">
  <form [formGroup]="locationFrm" (ngSubmit)="addLocation(id,locationFrm.value)" *ngIf="locationFrm">
    <div class="row expanded">
      <div  layout="column" layout-align="center end">
        <mat-form-field class="example-full-width">
          <mat-label>Name</mat-label>
          <input matInput  name="name" [formControl]="locationFrm.controls['name']" formControlName="name" placeholder="" >
          <div class="alert" *ngIf="!locationFrm.controls['name'].valid && locationFrm.controls['name'].touched">
            You must specify a name that's at least 10 characters long.
          </div>
        </mat-form-field>
        <div *ngIf="userSvc.checkAuthorization(['superadmin','admin'])">
          <div>
            <mat-slide-toggle formControlName="demo_mode" [checked]="locationFrm.get('demo_mode').value" (change)="onDemoModeChange($event)">Demo Mode</mat-slide-toggle>
            <!-- (change)="onDemoModeChange($event)" -->
          </div>

        </div>
        <!-- <div>
          <label>Name
            <input type="text" name="name" [formControl]="locationFrm.controls['name']" formControlName="name">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['name'].valid && locationFrm.controls['name'].touched">
            You must specify a name that's at least 10 characters long.
          </div>
        </div> -->
        <mat-form-field class="example-full-width">
          <mat-label>Site Url</mat-label>
          <input matInput  name="site_url" [formControl]="locationFrm.controls['site_url']" formControlName="site_url" placeholder="" >
          <div class="alert" *ngIf="!locationFrm.controls['site_url'].valid && locationFrm.controls['site_url'].touched">
            You must specify a properly formated site url.
          </div>
        </mat-form-field>

        <!-- <div>
          <label>Site Url
            <input type="text" name="site_url" [formControl]="locationFrm.controls['site_url']" formControlName="site_url">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['site_url'].valid && locationFrm.controls['site_url'].touched">
            You must specify a properly formated site url.
          </div>
        </div> -->

        <mat-form-field class="example-full-width">
          <mat-label>Yelp Url</mat-label>
          <input matInput  name="yelp_url" [formControl]="locationFrm.controls['yelp_url']" formControlName="yelp_url" placeholder="" >
          <div class="alert" *ngIf="!locationFrm.controls['yelp_url'].valid && locationFrm.controls['yelp_url'].touched">
            You must specify a properly formated yelp url.
          </div>
        </mat-form-field>
        <!-- <div>
          <label>Yelp Url
            <input type="text" name="yelp_url" [formControl]="locationFrm.controls['yelp_url']" formControlName="yelp_url">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['yelp_url'].valid && locationFrm.controls['yelp_url'].touched">
            You must specify a properly formated yelp url.
          </div>
        </div> -->
        <mat-form-field class="example-full-width">
          <mat-label>Address</mat-label>
          <input matInput  name="address" [formControl]="locationFrm.controls['address']" formControlName="address" placeholder="ex. 1313 mockingbird lane " >
          <div class="alert" *ngIf="!locationFrm.controls['address'].valid && locationFrm.controls['address'].touched">
            You must specify a address
          </div>
        </mat-form-field>

        <!-- <div>
          <label>Address
            <input type="text" name="address" [formControl]="locationFrm.controls['address']" formControlName="address">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['address'].valid && locationFrm.controls['address'].touched">
            You must specify a address
          </div>
        </div> -->

        <mat-form-field class="example-full-width">
          <mat-label>City</mat-label>
          <input matInput  name="city" [formControl]="locationFrm.controls['city']" formControlName="city" placeholder="ex. Atlanta" >
          <div class="alert" *ngIf="!locationFrm.controls['city'].valid && locationFrm.controls['city'].touched">
            You must specify a city.
          </div>
        </mat-form-field>

        <!-- <div>
          <label>City
            <input type="text" name="city" [formControl]="locationFrm.controls['city']" formControlName="city">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['city'].valid && locationFrm.controls['city'].touched">
            You must specify a city.
          </div>
        </div> -->
        <mat-form-field class="example-full-width">
          <mat-label>State</mat-label>
          <input matInput  name="state" [formControl]="locationFrm.controls['state']" formControlName="state" placeholder="ex. GA" >
          <div class="alert" *ngIf="!locationFrm.controls['state'].valid && locationFrm.controls['state'].touched">
            You must specify a state.
          </div>
        </mat-form-field>
        <!-- <div>
          <label>State
            <input type="text" name="state" [formControl]="locationFrm.controls['state']" formControlName="state">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['state'].valid && locationFrm.controls['state'].touched">
            You must specify a state.
          </div>
        </div> -->
        <mat-form-field class="example-full-width">
          <mat-label>Country</mat-label>
          <input matInput  name="country" [formControl]="locationFrm.controls['country']" formControlName="country" placeholder="ex. US" >
          <div class="alert" *ngIf="!locationFrm.controls['country'].valid && locationFrm.controls['country'].touched">
            You must specify a country.
          </div>
        </mat-form-field>

        <!-- <div>
          <label>Country
            <input type="text" name="country" [formControl]="locationFrm.controls['country']" formControlName="country">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['country'].valid && locationFrm.controls['country'].touched">
            You must specify a Country.
          </div>
        </div> -->
        <mat-form-field class="example-full-width">
          <mat-label>Postal Code</mat-label>
          <input matInput  name="postal_code" [formControl]="locationFrm.controls['postal_code']" formControlName="postal_code" placeholder="ex. 07042" >
          <div class="alert" *ngIf="!locationFrm.controls['postal_code'].valid && locationFrm.controls['postal_code'].touched">
            You must specify a postal code.
          </div>
        </mat-form-field>

        <!-- <div>
          <label>Postal Code
            <input type="text" name="postal_code" [formControl]="locationFrm.controls['postal_code']" formControlName="postal_code">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['postal_code'].valid && locationFrm.controls['postal_code'].touched">
            You must specify a Country.
          </div>
        </div> -->

        <mat-form-field class="example-full-width">
          <mat-label>Geolocation - Latitude</mat-label>
          <input matInput  name="latitude" [formControl]="locationFrm.controls['latitude']" formControlName="latitude" placeholder="" >
          <!-- <div class="alert" *ngIf="!locationFrm.controls['latitude'].valid && locationFrm.controls['latitude'].touched">
            You must specify a postal code.
          </div> -->
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Geolocation - Longitude</mat-label>
          <input matInput  name="longitude" [formControl]="locationFrm.controls['longitude']" formControlName="longitude" placeholder="" >
          <!-- <div class="alert" *ngIf="!locationFrm.controls['latitude'].valid && locationFrm.controls['latitude'].touched">
            You must specify a postal code.
          </div> -->
        </mat-form-field>

        <!-- <div>
          <label>Geolocation
            <input type="text" placeholder="latitude" name="latitude" [formControl]="locationFrm.controls['latitude']" formControlName="latitude"> <input type="text" name="longitude" placeholder="longitude" [formControl]="locationFrm.controls['longitude']" formControlName="longitude">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['latitude'].valid && locationFrm.controls['latitude'].touched">
            You must specify a address
          </div>
        </div> -->
        <div *ngIf="userSvc.checkAuthorization(['superadmin','admin','owner','manager']); else elseBlockMaxCap">
          <mat-form-field class="example-full-width">
            <mat-label>Max Capacity</mat-label>
            <input matInput  name="max_cap" [formControl]="locationFrm.controls['max_cap']" formControlName="max_cap" placeholder="ex. 250" >
            <div class="alert" *ngIf="!locationFrm.controls['max_cap'].valid && locationFrm.controls['max_cap'].touched">
              You must specify a Maximum Capacity greater than zero.
            </div>
          </mat-form-field>
          <!-- <label>Max Capacity
            <input type="text" name="max_cap" [formControl]="locationFrm.controls['max_cap']" formControlName="max_cap">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['max_cap'].valid && locationFrm.controls['max_cap'].touched">
            You must specify an Integer greater than zero.
          </div> -->
        </div>
        <ng-template #elseBlockMaxCap>
          <section>
            <label>Max Capacity: {{locationFrm.get('max_cap').value}}
            </label>
          </section>
        </ng-template>



        <div *ngIf="userSvc.checkAuthorization(['superadmin','admin','owner','manager']); else elseBlockCurrentState">
          <mat-form-field class="example-full-width">
            <mat-label>Current Crowd Size</mat-label>
            <input matInput  name="current_state" [formControl]="locationFrm.controls['current_state']" formControlName="current_state" placeholder="ex. 250" >
            <div class="alert" *ngIf="!locationFrm.controls['current_state'].valid && locationFrm.controls['current_state'].touched">
              You must specify a Current Crowd Size.
            </div>
          </mat-form-field>

          <!-- <label>Current Crowd Size
            <input type="text" name="current_state" [formControl]="locationFrm.controls['current_state']" formControlName="current_state">
          </label>
          <div class="alert" *ngIf="!locationFrm.controls['current_state'].valid && locationFrm.controls['current_state'].touched">
            You must specify an Integer equal to or greater than 0.
          </div> -->

        </div>
        <ng-template #elseBlockCurrentState>
          <section>
            <label>Current Crowd Size: {{locationFrm.get('current_state').value}}

            </label>
          </section>
        </ng-template>



        <!-- <label>Description
          <textarea name="content" id="content" required [formControl]="locationFrm.controls['content']" formControlName="content"></textarea>
        </label>
        <div class="alert" *ngIf="!locationFrm.controls['content'].valid && locationFrm.controls['content'].touched">
          You must specify content that's at least 10 characters long.
        </div> -->

        <input type="submit" class="cta-btn filled" value="Post Location" >
        <!-- [disabled]="!locationFrm.valid" -->


      </div>
    </div>
  </form>
  <br /><br /><br />
  <div  *ngIf="!isAddMode">
    <app-location-api [locationId]="id" [token]="token" [apiKey]="apiKey" *ngIf="(userSvc.checkAuthorization(['superadmin','admin'])||userSvc.checkLocationRole(locationId,['owner','manager']) )"></app-location-api>
  </div>
</div>
<br /><br /><br />
<div>
  <div   *ngIf="!isAddMode">
    <app-room-manage [dsRooms]="dsRooms" [locationId]="id"></app-room-manage>
  </div>
  <br /><br />
  <div   *ngIf="!isAddMode">
    <app-door-manage [dsDoors]="dsDoors" [locationId]="id"></app-door-manage>
  </div>
  <br /><br />
  <div  *ngIf="!isAddMode">
    <app-team-manage  [locationId]="id"></app-team-manage>
  </div>
  <br /><br />
  <div  *ngIf="!isAddMode">
    <app-team-invite-list  [locationId]="id"></app-team-invite-list>
  </div>
  <br /><br />
  <div  *ngIf="!isAddMode">
    <app-alert-manage  [locationId]="id"></app-alert-manage>
  </div>
</div>
