import { Injectable } from '@angular/core';
import { Observable,of,Subscriber,Subject } from 'rxjs';
import { UserPlaceService } from '../api/user-place.service';
import { UserPlace,UserPlaceWrapper } from '../api/user-place';
import { debug,debuglog,RxJsLoggingLevel } from './debug.operator';

@Injectable({
  providedIn: 'root'
})
export class MyPositionService {
  myPositions: MyPositionData[];
  searchResults: MyPositionDataWrapper[]=[];
  currentPosition = new MyPositionData;
  public currentPositionSubject= new Subject<MyPositionData>;
  public currentPosition$ = this.currentPositionSubject.asObservable();
  public searchResultsSubject= new Subject<MyPositionDataWrapper[]>;
  public searchResults$ = this.searchResultsSubject.asObservable();
  constructor( private userPlaceSvc: UserPlaceService) {
//     const { observables, proxy } = observe<MyPositionData>(this.currentPosition);
//     observables.ngOnInit.pipe(
//       switchMapTo(observables.name),
//       debounceTime(400),
//       distinctUntilChanged(),
//       takeUntil(observables.ngOnDestroy)
//     ).subscribe(value => console.log(value));
// return proxy;
    // this.currentPosition.address='Jersey City, NJ';
  }
  getCurrentPositionObservable():Observable<MyPositionData>{
    return this.currentPosition$;
  }
  getSearchResultsObservable():Observable<MyPositionDataWrapper[]>{
    return this.searchResults$;
  }
  clearSearchResults():void{
    this.searchResults=[];
    this.searchResultsSubject.next(this.searchResults);
  }
  setSearchResults(results:MyPositionDataWrapper[]):void{
    this.searchResults=results;
    this.searchResultsSubject.next(this.searchResults);
  }
  setCurrentPosition(userPlace:UserPlaceWrapper):void{
    debuglog(RxJsLoggingLevel.DEBUG, 'my-position:setCurrentPosition:param userPlace', userPlace);
    this.currentPosition= userPlace.attributes ;
    this.currentPositionSubject.next(this.currentPosition);
  }
  setCurrentPositionGPS(userPlace:MyPositionData):void{
    debuglog(RxJsLoggingLevel.DEBUG, 'my-position:setCurrentPositionGPS:param userPlace', userPlace);
    this.currentPosition= userPlace ;
    this.currentPositionSubject.next(this.currentPosition);
  }
  geoSearch(position:any){
    debuglog(RxJsLoggingLevel.DEBUG, 'my-position:geoSearch:param position', position);
    if(typeof(position)== 'string'){
      // geocode address w/0 saving  an store in current position
      this.searchResults=[];
      let sub=this.userPlaceSvc.geocodePlace(position).subscribe(places => {
        places.map((place)=>{
          let result={
            id: -1,
            label: '',
            place:place.data.address.house_number+' '+place.data.address.road+', '+((place.data.address.city!==undefined)?place.data.address.city+', ':' ')+place.data.address.state+' '+place.data.address.postcode,
            latitude: place.data.lat,
            longitude: place.data.lon
          };
          this.searchResults.push({attributes:result});
          sub.unsubscribe();
        });
        debuglog(RxJsLoggingLevel.DEBUG, 'my-position:geoSearch:geocodePlace results/SearchResults', places, this.searchResults);
        this.searchResultsSubject.next(this.searchResults);
      });
    }else{
      this.currentPosition.place=position.attributes.place;
      this.currentPosition.label=position.attributes.label;
      this.currentPosition.longitude=position.attributes.longitude;
      this.currentPosition.latitude=position.attributes.latitude;
      this.currentPositionSubject.next(this.currentPosition);
    }

  }
// https://github.com/rodrigokamada/angular-leaflet
  getDevicePosition(): any {
    return new Observable((observer: Subscriber<any>) => {
      debuglog(RxJsLoggingLevel.DEBUG, 'my-position:getCurrentPosition position');
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: any) => {
          debuglog(RxJsLoggingLevel.DEBUG, 'my-position:geolocation position', position);
          this.userPlaceSvc.reverseGeocode(position.coords.latitude,position.coords.longitude).subscribe((pos)=>{
            observer.next({
              id: -1,
              label: 'Current Position',
              place: '',
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              position: pos
            });
            observer.complete();
          });

        },
        (err:any)=>{
          debuglog(RxJsLoggingLevel.DEBUG, 'my-position:geolocation err', err);
          this.userPlaceSvc.getPositionHttp().subscribe((pos)=>{
            debuglog(RxJsLoggingLevel.DEBUG, 'my-position:geolocation fail position', pos);
            observer.next(pos);
            observer.complete();
          });
        });
      } else {

        this.userPlaceSvc.getPositionHttp().subscribe((pos)=>{
          debuglog(RxJsLoggingLevel.DEBUG, 'my-position:geolocation fail position', pos);
          observer.next(pos);
          observer.complete();
        });
        // observer.error();
      }
    });
  }


  // https://www.tutscoder.com/post/get-current-location-angular
  // const position: any = await this.locationService.getCurrentLocation();
  getCurrentLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              debuglog(RxJsLoggingLevel.DEBUG, 'my-position:getCurrentLocation position',
                'Latitude: ' +
                  position.coords.latitude +
                  'Longitude: ' +
                  position.coords.longitude
              );
              let lat = position.coords.latitude;
              let lng = position.coords.longitude;

              const location = {
                lat,
                lng,
              };
              resolve(location);
            }
          },
          (error) => debuglog(RxJsLoggingLevel.ERROR, 'my-position:getCurrentLocation error',error)
        );
      } else {
        reject('Geolocation is not supported by this browser.');
      }
    });
  }
}

export class MyPositionData {
  id?:number;
  label: string;
  place: string;
  latitude: string;
  longitude: string;
}
export interface MyPositionDataWrapper {
  attributes: MyPositionData

}
