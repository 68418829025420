import { Component, OnInit, ElementRef, Input } from '@angular/core';
import {FormControl } from '@angular/forms';
import { User } from '../core/user';
import { AuthService } from '../core/auth.service';
import { Observable, of,  } from 'rxjs';
import {  finalize, catchError } from 'rxjs/operators';
// import { PageEvent, } from '@angular/material/paginator';
import { MatInput } from '@angular/material/input';
// import { MatPaginator,} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
// import {  MatProgressSpinner} from '@angular/material/progress-spinner';
import { ViewChild } from '@angular/core';
import { Alert, AlertWrapper } from '../api/alert';
import { AlertService } from '../api/alert.service';
import { UserService } from '../api/user.service';
// import { Alert } from '../api/alert';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { BehaviorSubject } from 'rxjs';
import {MatDialog, } from '@angular/material/dialog';
import { AlertDeleteComponent } from '../alert-delete/alert-delete.component';
import { formatDistance, subDays, format,parseISO } from 'date-fns';

@Component({
  selector: 'app-alert-manage',
  templateUrl: './alert-manage.component.html',
  styleUrls: ['./alert-manage.component.css']
})


export class AlertManageComponent implements OnInit {

  myRoles:any;
  // form:FormGroup= new FormGroup({
  //   name: new FormControl('default'),
  //   address: new FormControl(),
  // });
  user: User|undefined;
  categories = new FormControl([]);
  categoryList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];



  // for table and paging of table
  displayedColumns: string[] = ['msg', 'start', 'end','actions'];

  // @ViewChild(MatPaginator, {  static: false }) paginator: MatPaginator;
  @ViewChild('searchInput', {  static: false }) input: ElementRef;
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20];

  // MatPaginator Output
  // pageEvent: PageEvent;
  @Input() locationId:number|undefined;
  backRoutePath:string='/alert-crud/';
  crudRoutePath:string='/alert-crud/';
  alerts:AlertWrapper[]=[];
  private alertsIndex:number[];
  dataSourceMat: MatTableDataSource<AlertWrapper>;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  filterStr:string='';
  searchTotal:number;
  pageIndex:number=0;

  constructor(
    public auth: AuthService,
    private alertSvc: AlertService,
    private userSvc: UserService,
    public dialog: MatDialog ,
   ) {
     // this.dataSource = new AlertsDataSource(this.alertSvc);
    }


   ngOnInit() {
    // to lockdown particular functionality in Component
    // this.dataSource = new AlertsDataSource(this.alertSvc);
    // this.auth.userFiltered$.subscribe(user => {this.user=user;
      // this.userSvc.myRoles$.subscribe(results=>{
        debuglog(RxJsLoggingLevel.DEBUG,'alert-manage::init: myRole',this.userSvc.myRoles);
        debuglog(RxJsLoggingLevel.DEBUG,'alert-manage::init: locationId',this.locationId);
        this.myRoles= this.userSvc.myRoles;
        if (this.locationId!==null){
          this.backRoutePath='/location/'+this.locationId+'/alert-crud/';
          this.crudRoutePath='/location/'+this.locationId+'/alert-crud/';
        }

        this.loadViewMat(

          '',
          this.locationId,
          // sortDirection,
          this.pageIndex,   this.pageSize);


      // });

    // })
    // this.form = new FormGroup({
    //   name: new FormControl('default'),
    //   address: new FormControl(),
    // });


  }

  ngAfterViewInit() {
    // server-side search TODO
    // fromEvent(this.input.nativeElement, 'keyup')
    //   .pipe(
    //     debounceTime(150),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       this.paginator.pageIndex = 0;
    //       this.loadView();
    //     })
    //   )
    //   .subscribe();
    // this.dataSourceMat.paginator = this.paginator;
    // this.paginator.page
    //   .pipe(
    //     tap(() => this.loadView())
    //   )
    //   .subscribe();

  }



  deleteAlert(alertW:AlertWrapper):void{
    const dialogRef = this.dialog.open(AlertDeleteComponent, {
      width: '250px',
      data: {alert: alertW.attributes}
    });

    dialogRef.afterClosed().subscribe(result => {

      debuglog(RxJsLoggingLevel.DEBUG, 'alert-manage:AlertDeleteComponent::afterClosed:result: ',result);

    });
  }
  search():void{
    const filter = this.input.nativeElement.value ;
    this.filterStr=filter.trim().toLowerCase();
    debuglog(RxJsLoggingLevel.DEBUG, 'Alert-manage::search: input', this.input);
    this.dataSourceMat.filter=this.filterStr;
    this.loadViewMat(

      this.filterStr,
      this.locationId,
      // sortDirection,
      this.pageIndex, this.pageSize
    );
  }
  onRowClicked(row) {
    debuglog(RxJsLoggingLevel.DEBUG, 'Row clicked: ', row);
  }
  dateFormat(dateItem) {
    return (dateItem==null?null:format(new Date(dateItem),'MM-dd-yy hh:mm aa'));
  }

  loadViewMat(filter = this.filterStr,
    locationId=null,
    // sortDirection = 'asc',
    pageIndex = 0, pageSize = 10) {

    this.loadingSubject.next(true);
    // filter, sortDirection,pageIndex, pageSize

    let alertQuerySub = this.alertSvc.getAlertsByUser({

      filter:filter,
      locationId:locationId,
      // sortDirection: sortDirection,
      pageIndex: pageIndex,
      pageSize: pageSize
    }).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)),
      debug(RxJsLoggingLevel.DEBUG, "Loading Alerts from backend")
    ).subscribe(result => {
      // create location index from results
      debuglog(RxJsLoggingLevel.DEBUG, 'alert-manage:AlertsDataSource::loadview:subscribe: before alertsIndex', result.alerts.data,this.alertsIndex);
      let alertsIndex=[];
      debuglog(RxJsLoggingLevel.DEBUG, 'alert-manage:AlertsDataSource::loadview:subscribe: after alertsIndex', this.alertsIndex);
      debuglog(RxJsLoggingLevel.DEBUG, 'alert-manage:AlertsDataSource::loadview:results: alertsIndex',alertsIndex);
      // map in favorites to locations results

      debuglog(RxJsLoggingLevel.DEBUG, 'alert-manage:loadview:results::AlertsDataSource enhanced locations', result.alerts.data);
      this.searchTotal = result.total;
      this.alerts=result.alerts.data;
      this.alertsIndex=alertsIndex;


      debuglog(RxJsLoggingLevel.DEBUG, 'alert-manage:AlertsDataSource::loadview:results: locsIndex', this.alertsIndex);
      // this.alertsSubject.next(this.alerts);
      this.dataSourceMat = new MatTableDataSource(this.alerts);
      this.dataSourceMat.filterPredicate = (data: AlertWrapper, filter: string) => data.attributes.msg.toLowerCase().indexOf(filter) != -1;
      // this.dataSourceMat.paginator = this.paginator;
      alertQuerySub.unsubscribe();
    });

  }

  pageChanged(event){
    // this.loading = true;

    let previousIndex = this.pageIndex;
    let pageIndex = event;
    let pageSize = this.pageSize;
    this.pageIndex = event;
    // this.pageSize = event.pageSize;




    this.loadViewMat(this.filterStr,this.locationId, pageIndex, pageSize);
  }
  get lastPage() {
    return Math.floor((this.searchTotal-1)/this.pageSize);
  }

}
