import { Component, Input,OnInit  } from '@angular/core';
import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { AlertService } from '../api/alert.service';
import { ChartConfiguration } from 'chart.js';
import { formatDistance, subDays, format,parseISO } from 'date-fns';

@Component({
  selector: 'app-alert-stats',
  templateUrl: './alert-stats.component.html',
  styleUrls: ['./alert-stats.component.css']
})
export class AlertStatsComponent {
  @Input() alertId!:number;
  stats:any={
    hrly:[],
    hrly_rollup:[],
    dow_rollup:[]
  };

  public barChartLegend = false;
  public barChartPlugins = [

  ];

  public barChartData: ChartConfiguration<'bar'>['data'];
  public barChartDataDow: ChartConfiguration<'bar'>['data'];

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: false,
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: false

        },
        max:1
      },
      x: {

        grid: {
          display: false

        }
      },

    },
    plugins: {
      tooltip: {
        callbacks: {
          // footer: footer,
          label: barLabel
        }
      }
    }
  };

  constructor(private alertSvc:AlertService){
    debuglog(RxJsLoggingLevel.DEBUG,'alert-stats::constructor: params', this.alertId);

  }
  ngOnInit(){
    debuglog(RxJsLoggingLevel.DEBUG,'alert-stats::ngOnInit: params', this.alertId);
    let alertStatSub=this.alertSvc.getAlertStats(this.alertId).subscribe(stats=>{
      this.stats=stats;
      this.barChartData={
        labels: this.stats.hrly_rollup.map(stat=> {
                  // return ''+(stat.hour<12?stat.hour+1:stat.hour-12+1)+(stat.hour==23?'AM':stat.hour<10?'AM':'PM');
                  return this.datetimeFormat(stat.tstamp_hour);
                }),
        datasets: [
          { data: this.stats.hrly_rollup.map(stat=> {return stat.cnt;}),
            label: '# of Redemptions',
            // backgroundColor: this.stats.hrly_rollup.map(stat=> {let color=this.chroma.getHeatColorRGBA(stat.average);
            //   return  'rgba('+color[0]+', '+color[1]+', '+color[2]+', .8)';}),
            // borderColor: this.stats.hrly_rollup.map(stat=> {let color=this.chroma.getHeatColorRGBA(stat.average);
            //   return  'rgba('+color[0]+', '+color[1]+', '+color[2]+', 1)';}),
            borderWidth: 1
          },
        ]
      };
      this.barChartDataDow={
        labels: this.stats.daily_rollup.map(stat=> {
                  // return weekday[stat.dow];
                  return this.dateFormat(stat.day);
                }),
        datasets: [
          { data: this.stats.daily_rollup.map(stat=> {return stat.sum;}),
            label: '# of Redemptions',
            // backgroundColor: this.stats.dow_rollup.map(stat=> {let color=this.chroma.getHeatColorRGBA(stat.average);
            //   return  'rgba('+color[0]+', '+color[1]+', '+color[2]+', .8)';}),
            // borderColor: this.stats.dow_rollup.map(stat=> {let color=this.chroma.getHeatColorRGBA(stat.average);
            //   return  'rgba('+color[0]+', '+color[1]+', '+color[2]+', 1)';}),
            borderWidth: 1
          },
        ]
      };
      let now= new Date();
      debuglog(RxJsLoggingLevel.DEBUG, 'alert-stats::get stats alerts', stats,this.barChartData,now.getTimezoneOffset());
    });
  }
  dateFormat(dateItem) {
    debuglog(RxJsLoggingLevel.TRACE, 'alert-stats::dateFormat ', dateItem);
    return (dateItem==null?null:format(new Date(dateItem),'MM-dd-yy'));
  }
  datetimeFormat(dateItem) {
    debuglog(RxJsLoggingLevel.TRACE, 'alert-stats::datetimeFormat ', dateItem);
    return (dateItem==null?null:format(new Date(dateItem),'MM-dd-yy hh:mm aa'));
  }

}

const barLabel = (data) => {
  // let label=data.parsed.y<.34?'free':(data.parsed.y>.65?'busy':'average');
  let label=data.formattedValue;

  // console.log('barchart barLabel data',data,);
  // formattedValue and parsed.x , parsed.y
  return label;
};
const weekday = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",];
