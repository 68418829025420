import { Injectable, OnInit, NgZone } from '@angular/core';
// import { Router } from '@angular/router';
import * as  auth  from 'firebase/auth';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FacebookAuthProvider, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
import { User,UserWrapper, Roles } from './user';
// import { UserService } from '../api/user.service';


import { debug,debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { Subject,shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  user$: Observable<User | null | undefined>;

  authenticated: boolean; // has a user in Firebase (anonymous or signed in with provider)
  registered: boolean; // signed in with a provider
  verified: boolean; // signed in with a provider
  loginError: boolean=false;
  authState: any = null;
  exist: boolean = false;
  user: User|undefined;
  userData: any;
  userJWT: string|undefined;
  public jwtSubject = new Subject<string>();
  public jwt$ = this.jwtSubject.asObservable();
  public userFilteredSubject = new Subject<any>();
  public userFiltered$ = this.userFilteredSubject.asObservable();
  roles:any;
  lastUser:any=null;
  // me:User;
  constructor(
    // public userSvc: UserService,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    // private router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    this.authenticated=false;
    this.registered=false;
    // this.afAuth.authState.subscribe(this.authStateChange.bind(this));
    this.user$ = this.afAuth.authState.pipe(
      shareReplay(1),
      tap(user=> this.userData=user),
      switchMap(user => {
        if (user) {
          debuglog(RxJsLoggingLevel.DEBUG,'AuthService::Constructor::$user pipe::updated user', user);
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          debuglog(RxJsLoggingLevel.DEBUG,'AuthService::Constructor::$user pipe::user dont exist', user);
          return of(null);
        }
      })
    );
    this.user$.subscribe((user) => {
      if((this.lastUser!==null && this.lastUser!== user)||this.lastUser==null){
        this.lastUser=user;
        this.userFilteredSubject.next(user);
      }
      debuglog(RxJsLoggingLevel.DEBUG,'AuthService::Constructor:: AuthState subscribe user', user);
      // this.authStateChange.bind(this);
      this.authStateChange(this.userData);
      if (user) {
        // this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user')!);
        this.jwtSubject.next(JSON.parse(JSON.stringify(this.userData._delegate.accessToken)));
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
      this.setUser(this.userData);
    });

    // this.user$.subscribe(this.setUser.bind(this));
    this.upgradeUserData = this.upgradeUserData.bind(this);
    this.anonymouUserUpdate = this.anonymouUserUpdate.bind(this);
  }
  getAuthstate(): Observable<any> {
    return of(this.afAuth.authState)
  }
  getNullObservable(): Observable<null> {
    return of(null);
  }
  ngOnInit(): void {

  }
  anonymousLogin(): any {
    debuglog(RxJsLoggingLevel.DEBUG,'firebase anonymous login fired');
    return this.afAuth.signInAnonymously()
      .then(this.anonymouUserUpdate)
      .catch(error => debuglog(RxJsLoggingLevel.DEBUG,'firebase anonymous login error',error));
  }
  anonymouUserUpdate(user) {

    debuglog(RxJsLoggingLevel.DEBUG,'anonymous user:', user);
    debuglog(RxJsLoggingLevel.DEBUG,'anonymous authState:', this.authState);

    let userRec: User = {
      name: 'anon-' + user.user.uid,
      email: '',
      uid: user.user.uid,
      roles: { guest: true },
      is_anonymous: true,
    }
    this.user = userRec;
    this.updateUserData(userRec);
    debuglog(RxJsLoggingLevel.DEBUG,'anonymous complete:', user)
  }
  private setUser(user: any) {
    this.user = user;

    if (user != undefined) {
      this.registered = user.isAnonymous == true ? false : true;
      this.authenticated= true;
      this.exist = true;
    }
    if (user == undefined ) { // && this.exist == true
      this.exist = false;
      this.afAuth.signOut().then(this.anonymousLogin.bind(this));
    }
    debuglog(RxJsLoggingLevel.DEBUG,'AuthService::setUser::subscription to user:', user);
  }
  loginGoogle() {
    return this.AuthLogin(new GoogleAuthProvider());
  }
  loginFacebook() {
    return this.AuthLogin(new FacebookAuthProvider());
  }
  loginTwitter() {
    return this.AuthLogin(new TwitterAuthProvider());
  }
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.upgradeUserData(result);
        // this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
}
  //     @deprecated
  // firebase.auth.Auth.signInAndRetrieveDataWithCredential method is deprecated. Use {@link firebase.auth.Auth.signInWithCredential} instead.
  signInWithCredential(credential: any) { return this.afAuth.signInWithCredential(credential); }
  loginEmailPass(email:string, password:string ) {
    var prevUser = this.afAuth.currentUser;
    //var credential = auth.EmailAuthProvider.credential(email, password);
    return this.afAuth.signInWithEmailAndPassword(email, password).then((userCred) => {
      let userRec: User = {
        name: userCred.user.displayName === null ? userCred.user.providerData[0].displayName : userCred.user.displayName,
        email: userCred.user.email,
        uid: userCred.user.uid,
        roles: { guest: false, registered: true },
        is_anonymous: false,
      }
      this.updateUserData(userRec);
    }, err => {
      this.loginError=true;

    }).catch(this.handleEmailPassError.bind(this, email, password));

  }
  handleEmailPassError(email:string, password:string, error:any) {
    debuglog(RxJsLoggingLevel.DEBUG,error);
    if (error.code == "auth/user-not-found") {
      return this.afAuth.createUserWithEmailAndPassword(email, password).then(userCred => {
        let userRec: User = {
          name: userCred.user.displayName === null ? userCred.user.providerData[0].displayName : userCred.user.displayName,
          email: userCred.user.email,
          uid: userCred.user.uid,
          roles: { guest: false, registered: true },
          is_anonymous: false
        }
        this.updateUserData(userRec);
      }).catch(e => debuglog(RxJsLoggingLevel.DEBUG,e));
    } else{
      return of(null);
    }
  }
  private authStateChange(user) {
    debuglog(RxJsLoggingLevel.DEBUG,"authStateChange::finding detection:", user);
    if (user) {
      this.authState = user;
      if (user.isAnonymous) {
        debuglog(RxJsLoggingLevel.DEBUG,"authStateChange::User is annonymous", user);
        this.authenticated = true;
        this.registered=false;
      } else {
        this.authenticated = true;
        this.registered=true;
        debuglog(RxJsLoggingLevel.DEBUG,"authStateChange::User is Registered", user);
        if (!user.emailVerified){
          debuglog(RxJsLoggingLevel.DEBUG,"authStateChange::Not verified sending Verifivation", user);
          user.sendEmailVerification();
          this.verified=false;
        } else{
          this.verified=true;
        }
        // this.userSvc.me().subscribe(user => {
        //   this.me=user.attributes;
        // });
      }
      //this.user = this.afs.doc<User>(`users/${this.afAuth.auth.currentUser.uid}`);
    } else {
      // User is signed out.
      // ...
      this.authenticated = false;
      this.registered=false;
      debuglog(RxJsLoggingLevel.DEBUG,'authStateChange::no user- set state ', user, this);
      // this.anonymousLogin();  // rely on setUser method instead

    }
    return user;
  }

  private oAuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((credential) => {
        credential.user['roles']['registered'] = true
        this.updateUserData(credential.user)
      })
  }


  signOut() {
    this.afAuth.signOut();
    window.location.reload();
  }

  private updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc<User>(`users/${user.uid}`);

    return userRef.set(user, { merge: true })
  }

  public upgradeUserData(data) {
    const user = data.user;
    debuglog(RxJsLoggingLevel.DEBUG,"Anonymous account successfully upgraded", data.user);
    const userRef: AngularFirestoreDocument<User> = this.afs.doc<User>(`users/${user.uid}`);

    let userRec: User = {
      name: user.displayName === null ? user.providerData[0].displayName : user.displayName,
      email: user.email,
      uid: user.uid,
      roles: { registered: true, guest: false },
      is_anonymous: false
    };
    return userRef.set(userRec, { merge: true });

  }
  resendVerification(){
    this.authState.sendEmailVerification();
  }




  get isUserAnonymousLoggedIn(): boolean {
    return (this.authState !== null) ? this.authState.isAnonymous : false
  }

  get isUserRegistered(): boolean {
    return (this.authState !== null) ? !this.authState.isAnonymous : false
  }

  // Returns true if user is logged in
  /*get authenticated(): boolean {
    return this.authState !== null;
  }*/
  // Returns current user
  get currentUser(): any {
    return this.authenticated ? this.authState.auth : null;
  }

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.authState.uid : '';
  }

  get currentUserJWT(): string {
    debuglog(RxJsLoggingLevel.DEBUG,"Auth:currentUserJWT::CurrrentUser", this.afAuth.currentUser);
    // const newLocal = '';
    // this.userJWT = (JSON.parse(JSON.stringify(this.afAuth.currentUser)) != newLocal ? JSON.parse(JSON.stringify(this.afAuth.currentUser)).stsTokenManager.accessToken : '');
    // this.userJWT = (JSON.parse(JSON.stringify(this.userData)) != newLocal ? JSON.parse(JSON.stringify(this.userData._delegate.accessToken)) : '');
    this.userJWT = (this.userData !== undefined && this.userData !== null? JSON.parse(JSON.stringify(this.userData._delegate.accessToken)) : '');

    debuglog(RxJsLoggingLevel.DEBUG,"Auth:currentUsrJWT::jwt", this.userData,this.userJWT);
    // return JSON.parse(JSON.stringify(this.afAuth.currentUser)).stsTokenManager.accessToken;
    return this.userJWT;
  }

  // new code for email signup
  doRegister(value){
    return this.afAuth.createUserWithEmailAndPassword(value.email, value.password).then(userCred => {
      let userRec: User = {
        name: userCred.user.displayName === null ? userCred.user.providerData[0].displayName : userCred.user.displayName,
        email: userCred.user.email,
        uid: userCred.user.uid,
        roles: { guest: false, registered: true },
        is_anonymous: false
      }
      this.updateUserData(userRec);
    }).catch(e => debuglog(RxJsLoggingLevel.DEBUG,e));
  }
 


  /*
  checkAuthorization(){
    return true;
  }*/
}
