import { Alert,AlertWrapper } from '../api/alert';
export interface Location {
  id:number|undefined;
  created_user_id: number|undefined;
  name: string;
  max_cap: number;
  yelp_url: string;
  site_url: string;
  is_active: boolean;
  latitude: number;
  longitude:number;
  address: string;
  city: string;
  state: string;
  country: string;
  postal_code:string
  current_state: number;
  updated_user_id: number|undefined;
  rooms:RoomList|undefined;
  doors:DoorList|undefined;
  categories:Array<string>;
  favId: number|null;
  isFavorite:boolean;
  alerts: AlertWrapper[];
  fanscnt:number|null;
  demo_mode:boolean|null;
}
export interface LocationWrapper {
  attributes:Location;
}
export interface LocationDialogData {
  loc: LocationWrapper;
}
export class RoomWrapper {
  attributes:Room;
}
export class DoorWrapper {
  attributes:Door;
}
export class RoomSensorWrapper {
  attributes:RoomSensor;
}


export class Room {
  id:number;
  location_id:number;
  name:string;
  max_cap:number;
  current_state:number;

  entry_doors:DoorList|undefined;
  exit_doors:DoorList|undefined;
  all_doors:DoorList|undefined;
  room_sensors:RoomSensorList|undefined;
}

export class RoomSensor {
  id:number;
  room_id:number;
  name:string;
  sensor_id:string;

  is_polled:boolean;
  external_api_key:string;
  external_poll_url:string;
  serialized_sensor_field_map:string;
  current_state:number = 0;

}

export class Door {
  id:number;
  location_id:number;
  name:string;
  flow_from_id:number;
  flow_to_id:number;
  flow_to:Room|null;
  flow_from:Room|null;
  sensor_id:string;
  is_external:boolean;
  is_polled:boolean;
  external_api_key:string;
  external_poll_url:string;
  serialized_sensor_field_map:string;
  current_state:number = 0;
}

export class RoomList {
  rooms :Array<Room>;
}
export class RoomSensorList {
  room_sensors :Array<RoomSensor>;
}
export class DoorList {
  doors :Array<Door>;
}
