import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  flagShowProfile:boolean=true;
  constructor(private authSvc: AuthService,
    public dialogRef: MatDialogRef<LoginComponent>,) { }

  ngOnInit() {
  }
  cancel(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'login-popup::cancel: data');
    this.dialogRef.close();

  }
  toggleShowProfile(){
    this.flagShowProfile=!this.flagShowProfile;
  }


}
