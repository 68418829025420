
  <mat-dialog-content>
    <div layout="column"  class="container">
      <div layout="row"  class="item" >
        <div>
          <button type="button" class="location-heat-lg" style="background-color: {{ getcolor(loc.current_state/loc.max_cap) }}">&nbsp;</button>
        </div>
        <div flex class="h3">{{loc.name}}</div>
      </div>

      <div layout="row" layout-wrap class="item" *ngIf="chartSelection.value== undefined||chartSelection.value=='hour'">
        <canvas baseChart
          [data]="barChartData"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [type]="'bar'">
        </canvas>
      </div>
      <div layout="row" layout-wrap class="item" *ngIf="chartSelection.value=='dow'">
        <canvas baseChart
          [data]="barChartDataDow"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [legend]="barChartLegend"
          [type]="'bar'">
        </canvas>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around center" >
      <div layout="row" layout-wrap class="item">
        <mat-button-toggle-group name="chartSelect" #chartSelection="matButtonToggleGroup" aria-label="Chart Selection" value="hour">
          <mat-button-toggle value="hour" >Hourly</mat-button-toggle>
          <mat-button-toggle value="dow">Week Days</mat-button-toggle>

        </mat-button-toggle-group>
      </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around center" >
        <div layout="column" layout-wrap class="item">
          <div flex="25"  *ngIf="loc.yelp_url!==null && loc.address!==''">{{loc.address}}</div>
          <div flex="25"  *ngIf="(loc.city!==null &&  loc.city!=='')||(loc.state!==null && loc.state!=='')||loc.postal_code!==null && loc.postal_code!==''">{{loc.city}}, {{loc.state}} {{loc.postal_code}} </div>
          <div flex="25"  *ngIf="loc.country!==null &&  loc.country!==''">{{loc.country}} </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around center" >
        <div layout="row" layout-wrap class="item">
          <div id="detail-map-id" #mapElement fxFlex="30"></div>
        </div>
      </div>

<!-- layout-wrap class="item" -->
      <div  fxLayout="row" fxLayoutAlign="space-around center"   *ngIf="loc.site_url.length >0||loc.yelp_url.length >0">
        <div   *ngIf="loc.site_url!==null && loc.site_url.length >0"><a href="{{loc.site_url}}" target="new">website</a></div>

        <div    *ngIf="loc.yelp_url!==null && loc.yelp_url.length >0"><a href="{{loc.yelp_url}}" target="new">yelp</a></div>
      </div>
      <div fxLayout="row" layout-wrap class="item">
        <div flex="20"># of fans:</div>
        <div flex="75">{{loc.fanscnt}}</div>
      </div>

      <div layout="row" class="item">
        <mat-list>
          <mat-list-item *ngFor="let alert of loc.alerts"><button type="button" name="button" (click)="alertDetail(alert)">{{alert.attributes.msg}}
            <br> {{dateFormat(alert.attributes.date_campaign_start)}} - {{dateFormat(alert.attributes.date_end)}}</button>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button  color="primary" [mat-dialog-close]>OK</button>
  </mat-dialog-actions>
