import { Component, Input,  ElementRef,ViewChild,Injector,ChangeDetectorRef,OnInit,AfterViewInit } from '@angular/core';
import { UiStateService } from '../core/ui-state.service';
import { RealtimeSocketService,RealtimeMsgObj} from '../core/realtime-socket.service';
import { realtimeSocketServiceProvider } from '../core/realtime-socket.service.provider';
import { ChromaService } from '../core/chroma.service';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
import { Location,LocationWrapper } from '../api/location';
import { LocationDetailComponent} from '../location-detail/location-detail.component';
import {MatDialog,} from '@angular/material/dialog';
@Component({
  selector: 'app-map-marker-popup',
  templateUrl: './map-marker-popup.component.html',
  styleUrls: ['./map-marker-popup.component.css']
})
export class MapMarkerPopupComponent {
  @Input() loc: LocationWrapper;
  realtimeSocketService:RealtimeSocketService;

  constructor(
    // private faye: FayeService,
    private chroma: ChromaService,
    private uiStateSvc: UiStateService,
    public dialog: MatDialog,
    private injector: Injector,
    private changeDetectorRefs: ChangeDetectorRef,
  ) {
    debuglog(RxJsLoggingLevel.DEBUG, 'MapMarkerPopupComponent::constructor loc', this.loc);
  }

  onClicked() {
    debuglog(RxJsLoggingLevel.DEBUG, 'MapMarkerPopupComponent::onClicked: ', );
    const dialogRef = this.dialog.open(LocationDetailComponent, {
      data: {loc: this.loc,},
    });
  }

}
