// attributes :id, :location_id,:msg, :url, :date_end, :date_campaign_start, :dscription, :is_qr_redeem, :date_special_start,
// :redemption_max, :redemption_current_count, :is_multiple_redeem
import { User } from '../core/user';
import { Location } from '../api/location';
export interface Alert {
    id: number;
    location_id: number|undefined;
    msg: string;
    date_end: string|undefined;
    date_campaign_start: any|undefined;
    date_special_start: any|undefined;
    url: string;
    description: string;
    is_qr_redeem: boolean;
    is_multiple_redeem: boolean;
    redemption_max: number|undefined;
    redemption_current_count:number|undefined;
    location:Location|undefined|null;
    alertRedemptions:AlertRedmption[];
    duration_minutes:number|undefined;
    duration_hours:number|undefined;
    rrule:string|undefined;

  }
  export interface AlertWrapper {
    attributes:Alert|undefined;
    id:string|undefined;

  }

  export class AlertDialogData {
    alert:AlertWrapper|undefined;

  }

  export class AlertRedmption {
    worker_id:number;
    customer_id:number;
    alert_id:number;
    created_at:number;
    worker:User;
    customer:User;

  }
