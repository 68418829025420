import { Component, OnInit, AfterViewInit, Inject, Injector,   } from '@angular/core';

import {MediaMatcher,Breakpoints,BreakpointObserver} from '@angular/cdk/layout';
import {ChangeDetectorRef, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MyPositionService,MyPositionData } from './core/my-position.service';
import { SetUserLocationDialog } from './set-user-location-dialog/set-user-location-dialog.component';
import { SearchUserLocationComponent } from './search-user-location/search-user-location.component';
import { AuthService } from './core/auth.service';
import { UserService } from './api/user.service';
import { debug,debuglog,RxJsLoggingLevel } from './core/debug.operator';
import { Alert,AlertWrapper } from './api/alert';
// import { Location,LocationWrapper } from './api/location';
import { AlertService } from './api/alert.service';
import { UserPlaceService } from './api/user-place.service';
import { Observable,of,mergeMap } from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {MatAutocomplete} from '@angular/material/autocomplete';
import { AlertDetailComponent } from './alert-detail/alert-detail.component';
import { UiStateService } from './core/ui-state.service';
import { ViewChild,ElementRef } from '@angular/core';
// import { NgxActionCableBroadcaster, NgxActionCableService } from 'ngx-actioncable';
// import { Socket } from 'ngx-socketio2';
import { RealtimeSocketService,RealtimeMsg,RealtimeMsgObj } from './core/realtime-socket.service';
import { realtimeSocketServiceProvider } from './core/realtime-socket.service.provider';

// import { NgXCable, Broadcaster } from 'ngx-cable';
// import { tap, } from 'rxjs/operators';
import {  Subscription } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, } from 'rxjs/operators';
import { LoginComponent } from './login/login.component';
import { WINDOW } from './core/window.providers';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
// import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
// import {MatGridListModule} from '@angular/material/grid-list';
// import {CommonModule} from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [realtimeSocketServiceProvider,]
})
export class AppComponent implements OnInit, OnDestroy {
  realtimeSocketService:RealtimeSocketService;
  mobileQuery: MediaQueryList;
  title = 'headcountphoenix';
  currentPositionTxt :string;
  authSvc:AuthService;
  myControl = new FormControl();
  userSvc:UserService;
  myPositionSvc:MyPositionService;
  myRoles:any;
  alerts:AlertWrapper[]=[];
  alertRTLock:number[]=[];
  private alertsIndex:number[]=[];
  userPlaces:any[];
  userPlaceSelected: number;
  filteredUserPlaces: Observable<any[]>;
  showPositionButtons:boolean=false;
  showPositionCheckButtons:boolean=false;
  private userPlacesIndex:number[];
  rolesSub:Subscription=null;
  locRTsub:Subscription=null;
  alertRTsub:Subscription=null;
  alertQuerySub:Subscription=null;
  authUserSub:Subscription=null;
  userPlaceSub:Subscription=null;
  myPositionSub:Subscription=null;
  isOnline: boolean;
  modalVersion: boolean;
  modalPwaEvent: any;
  modalPwaPlatform: string|undefined;
  @ViewChild('snav', {read: true, static: true}) snavElement: ElementRef| null;
  @ViewChild('search') searchElement: ElementRef;
  isCompressedLayout:boolean;
  offerSeen:boolean=false;
  durationInSeconds = 5;
  snackList:AlertWrapper[]=[];
  isSnackOpen:boolean=false;
  snackListIndex:boolean[]=[];
  constructor(changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public dialog: MatDialog,
    myPositionSvc: MyPositionService,
    authSvc: AuthService,
    userSvc: UserService,
    private alertSvc: AlertService,
    private userPlaceSvc: UserPlaceService,
    private uiStateSvc: UiStateService,
    private injector: Injector,
    private swUpdate: SwUpdate,
    private platform: Platform,
    private responsive: BreakpointObserver,
    @Inject(WINDOW) private window: Window,
    private _snackBar: MatSnackBar,
    public router: Router,
    private route: ActivatedRoute
    // private cable: NgxActionCableService
    // private socket: Socket
    // private ngcable: NgXCable,
    // private broadcaster: Broadcaster
  ){
    // this.cable.logger.enabled = true;
    this.isOnline = false;
    this.modalVersion = false;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.authSvc=authSvc;
    this.userSvc=userSvc;
    this.myPositionSvc=myPositionSvc;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.rootRoute(this.route)),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
      // mergeMap((route: ActivatedRoute) => route.data)
    ).subscribe(event => {
      debuglog(RxJsLoggingLevel.DEBUG,'app.component::router_event: ',event);
      // this.uiStateSvc.trackPage('/'+event.snapshot.routeConfig.path, event.snapshot.routeConfig.component.name, 'website', {
      // });
      this.uiStateSvc.trackInteraction('/'+event.snapshot.routeConfig.path, 'page_view', event.snapshot.routeConfig.component.name,undefined,undefined, {
      });
    });


  }
  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    // debuglog(RxJsLoggingLevel.DEBUG,'app.component::rootRoute test: ',route);
    return route;
  }

  private _mobileQueryListener: () => void;
  ngAfterViewInit(): void {
    debuglog(RxJsLoggingLevel.DEBUG,'app.component::ngAfterViewInit: snav',this.snavElement);
  }
  ngOnInit(): void {

    this.updateOnlineStatus();

    window.addEventListener('online',  this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt: any) => {
          debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:ngOnInit::swUpdate.versionUpdates',`currentVersion=[${evt.currentVersion} | latestVersion=[${evt.latestVersion}]`);
          this.modalVersion = true;
        }),
      );
    }
    this.loadModalPwa();
    this.responsive.observe([
      Breakpoints.HandsetPortrait,
      // Breakpoints.TabletLandscape,
      ])
      .subscribe(result => {

        this.isCompressedLayout = false;

        if (result.matches) {
          this.isCompressedLayout = true;
        }
      });
    this.authUserSub=this.authSvc.user$.subscribe(user =>{
      debuglog(RxJsLoggingLevel.DEBUG,'appcomponent::init: auth',user);
      if (this.rolesSub !==null)this.rolesSub.unsubscribe();
      this.rolesSub=this.userSvc.myRoles$.subscribe(results=>{

        // if (this.alertRTsub !==null)this.alertRTsub.unsubscribe();
        // if (this.alertQuerySub !==null)this.alertQuerySub.unsubscribe();
        // if (this.myPositionSub !==null)this.myPositionSub.unsubscribe();
        // if (this.userPlaceSub !==null)this.userPlaceSub.unsubscribe();

        debuglog(RxJsLoggingLevel.DEBUG,'appcomponent::init: myRole',results);
        this.myRoles= results;
        this.realtimeSocketService = this.injector.get(RealtimeSocketService);
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:realtime_msg::new Socket', this.realtimeSocketService);
        // this.realtimeSocketService.emit("subscribe", 'realtime_msg');
        this.alertRTsub=this.realtimeSocketService.alerts$.subscribe((msg:RealtimeMsgObj[])=> {
          debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:realtime_msg::new msg',msg);
          if(msg[0].msg["resource"] =='alerts'){
            debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:realtime_msg::new alert step1',msg[0].msg.obj,this.alerts,this.alertsIndex,this.alertRTLock);
            if(!this.alertsIndex[Number(msg[0].msg.obj.id)]&& !this.alertRTLock.includes(Number(msg[0].msg.obj.id))){
              // add to the list
              this.alertRTLock.push(Number(msg[0].msg.obj.id));
              debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:realtime_msg::new alert step2',msg[0].msg.obj,this.alerts,this.alertsIndex,this.alertRTLock);
              if(this.alerts[0].attributes.id!== msg[0].msg.obj.id ){
                this.alerts.unshift({id:''+msg[0].msg.obj.id,attributes:msg[0].msg.obj as Alert});
                if((msg[0].msg.obj as Alert).location_id){
                  if((msg[0].msg.obj as Alert).location.demo_mode){
                    (msg[0].msg.obj as Alert).msg=(msg[0].msg.obj as Alert).msg +' ('+(msg[0].msg.obj as Alert).id + ') RT';
                  }
                }
              }
              this.alerts= [...this.alerts];
              this.alertsReindex();
              debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:realtime_msg::new alert add',this.alerts,this.alertsIndex);
              this.alertRTLock.splice(this.alertRTLock.indexOf(Number(msg[0].msg.obj.id)),1);
            }else{
              this.alerts.splice(this.alertsIndex[Number(msg[0].msg.obj.id)],1,{id:''+msg[0].msg.obj.id,attributes:msg[0].msg.obj as Alert});
            }
            this.alertsFilter();

            // to prevent duplicate alert snackswhen computer is shutdown
            debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:realtime_msg::snack check',this.snackList,this.snackListIndex,msg[0].msg.obj,this.snackListIndex[msg[0].msg.obj.id]);
            if(this.snackListIndex[msg[0].msg.obj.id]==undefined){
              this.snackListIndex[msg[0].msg.obj.id]=true;
              this.snackList.push({id:''+msg[0].msg.obj.id,attributes:msg[0].msg.obj as Alert});
              if( this.snackList.length == 1 && !this.isSnackOpen )this.openAlertSnackBar();

            }


          }
        });;
      });
      this.alertQuerySub=this.alertSvc.getAlerts().subscribe(alerts => {
        // create location index from results
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:AlertsDataSource::loadview:subscribe: before alertsIndex', alerts,this.alertsIndex);
        let alertsIndex=[];
        alerts.map(function(alert:AlertWrapper,i:number){
          debuglog(RxJsLoggingLevel.TRACE, 'appcomponent:AlertsDataSource::', 'in loop alert ',i, alert );
          alertsIndex[Number(alert.attributes.id)] = Number(i);
          if(alert.attributes.location_id){
            if(alert.attributes.location.demo_mode){
              alert.attributes.msg=alert.attributes.msg +' ('+alert.attributes.id + ')';
            }
          }
          // alert.attributes.alerts=[];
          // alert.attributes.isFavorite=false;
          // alert.attributes.favId=null;
          return alert;
        });
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:AlertsDataSource::loadview:subscribe: after alertsIndex', this.alertsIndex);
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:AlertsDataSource::loadview:results: alertsIndex',alertsIndex);
        // map in favorites to locations results

        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:loadview:results::AlertsDataSource enhanced alerts', alerts);
        this.alerts=alerts;
        this.alertsIndex=alertsIndex;
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:AlertsDataSource::loadview:results: alertsIndex', this.alertsIndex);

        let sharedId:number|boolean;
        let sharedUid:string;
        const regex=/cszEvt=([^&]*)/;
        const regex_uid=/uid=([^&]*)/;
        debuglog(RxJsLoggingLevel.DEBUG,'appcomponent::nginit: cszEvt',this.window.location.search||false);

        if( regex.test(this.window.location.search)&& !this.offerSeen){
          // open modal
          sharedId=parseInt(this.window.location.search.match(regex)[1]);
          sharedUid=this.window.location.search.match(regex_uid)[1];
          debuglog(RxJsLoggingLevel.DEBUG,'appcomponent::nginit: sharedId',sharedId,this.window.location.search.match(regex));
          this.alertDetail(this.alerts[this.alertsIndex[sharedId]]);
          this.offerSeen=true;
          this.uiStateSvc.trackInteraction('alert_share_follow', 'alert', this.alerts[this.alertsIndex[sharedId]].attributes.msg, this.alerts[this.alertsIndex[sharedId]].attributes.id,undefined, {
              alert_id:this.alerts[this.alertsIndex[sharedId]].attributes.id,
              msg:this.alerts[this.alertsIndex[sharedId]].attributes.msg,
              from_uid:sharedUid
          });

        }

      });
      this.userPlaceSub=this.userPlaceSvc.getUserPlaces().subscribe(userPlaces => {
        // create location index from results
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:UserPlacesDataSource::loadview:subscribe: before userPlaces userPlacesIndex', userPlaces,this.userPlacesIndex);
        let userPlacesIndex=[];
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:UserPlacesDataSource::loadview:subscribe: after userPlacesIndex', this.userPlacesIndex);
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:UserPlacesDataSource::loadview:results: userPlacesIndex',userPlacesIndex);
        // map in favorites to locations results

        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:loadview:results::UserPlacesDataSource enhanced userPlaces', userPlaces);
        this.userPlaces=userPlaces;
        this.userPlacesIndex=userPlacesIndex;
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:UserPlacesDataSource::loadview:results: userPlacesIndex', this.userPlacesIndex);
        this.filteredUserPlaces = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        this.userPlaceSub.unsubscribe();

      });

      // if user is worker | manager
      if(this.userSvc.checkAuthorization(['worker','manager','owner'])){
        // get the list of locations for dropdown menu

      }


    });
    this.myPositionSub=this.myPositionSvc.getCurrentPositionObservable().subscribe((position) =>{
      debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:Init::getCurrentPositionObservable: position', position);
      this.currentPositionTxt=position.place;
    });
  }

  searchFn():void{
   // this.filteredUserPlaces = this.myPositionSvc.getSearchResultsObservable();
   // this.myPositionSvc.getSearchResultsObservable().subscribe();
   this.myPositionSvc.geoSearch(this.myControl.value);
   this.searchElement.nativeElement.focus();
  }
  alertsReindex():void{
    let alertsIndex=[];
    let alerts=this.alerts.map(function(alert:AlertWrapper,i:number){
      debuglog(RxJsLoggingLevel.TRACE,'alertsReindex in loop alert ',i, alert );
      // if(Date.parse(alert.attributes.date_end) > Date.now()){
        alertsIndex[Number(alert.attributes.id)] = Number(i);
        // alert.attributes.alerts=[];
        // alert.attributes.isFavorite=false;
        // alert.attributes.favId=null;
        return alert;
      // };
      // debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:alertsReindex::map: alert expired', alert);
      // return null;

    });
    // this.alerts=alerts;
    this.alertsIndex=alertsIndex;
    debuglog(RxJsLoggingLevel.TRACE, 'appcomponent:alertsReindex::complete', this.alerts,this.alertsIndex);
  }

  alertsFilter():void{
    let prevLen=this.alerts.length;
    let newList= this.alerts.filter(alert => {
      if(alert==undefined)return false;
      if(Date.parse(alert.attributes.date_end) > Date.now()){
        return true;
      }else{
        debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:alertsFilter:: remove', alert);
        return false;
      }
    });
    this.alerts=newList;
    if(newList.length !== prevLen)this.alertsReindex();

    debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:alertsFilter::', prevLen,newList.length,this.alerts);

  }




  detectPosition(): void {
    this.myPositionSvc.getDevicePosition().subscribe((result)=>{
      debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:detectPosition::g result', result);
      if(result.latitude !== null && result.longitude !== null ){
        this.myPositionSvc.clearSearchResults();
        this.myPositionSvc.setSearchResults([{attributes:result}]);

        this.myPositionSvc.setCurrentPositionGPS(result);
      }else{
        alert('Can not determine location\nEnsure Location Service is enabled');
      }
    });

  }
  toggleMap(): void {
    debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:toggleMap::loadview:');
    this.uiStateSvc.toggleShowMap();
  }
  newUserPlace(): void {
    const dialogRef = this.dialog.open(SetUserLocationDialog, {
      width: '250px',
      data: {'inputPosition': this.myControl.value,
      'position': this.myPositionSvc.currentPosition,
      'userPlaces': this.userPlaces}
    });

    dialogRef.afterClosed().subscribe(result => {
      debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:setMyLocation::afterClosed:result: ',result);
      this.userPlaces=result;
      // this.currentPositionTxt = this.myPositionSvc.currentPosition.address;
    });
  }
  editUserPlace(up): void {
    debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:editMyLocation::beforedialog:result: ',up);
    const dialogRef = this.dialog.open(SetUserLocationDialog, {
      width: '250px',
      data: {'userPlace': up,
      'position': this.myPositionSvc.currentPosition,
      'userPlaces': this.userPlaces}
    });

    dialogRef.afterClosed().subscribe(result => {
      debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:editMyLocation::afterClosed:result: ',result);
      this.userPlaces=result;
      // this.myPositionSvc.setCurrentPosition();
    });
  }

  openPositionSearch(): void {
    debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:openPositionSearch::beforedialog:result: ');
    const dialogRef = this.dialog.open(SearchUserLocationComponent, {
      width: '300px',
      data: {
      'position': this.myPositionSvc.currentPosition,
      'userPlaces': this.userPlaces}
    });

    dialogRef.afterClosed().subscribe(result => {
      debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:editMyLocation::afterClosed:result: ',result);
      this.userPlaces=result;
      // this.myPositionSvc.setCurrentPosition();
    });
  }

  alertDetail(alert): void {
    // TODO update to Notifictions dialog
    const dialogRef = this.dialog.open(AlertDetailComponent, {
      width: '300px',
      data: {'alert': alert}
    });

    dialogRef.afterClosed().subscribe(result => {

      debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:AlertDetailComponent::afterClosed:result: ',result);

    });
  }
  displayFn(userPlaceW?: any): string | undefined {
    debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:displayFn::l userPlace',userPlaceW);
    // if(userPlaceW !== undefined){
    //   this.myPositionSvc.setCurrentPosition(userPlaceW);
    //   this.showPositionCheckButtons = false;
    // }
    return userPlaceW ?( userPlaceW.attributes.label.length > 0?userPlaceW.attributes.label:userPlaceW.attributes.place ): '';
  }
  openLoginDialog():void{
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '250px',
      data: {}
    });
    this.uiStateSvc.trackInteraction('login_dialog', 'page_view','Login',undefined,undefined, {
    });

    dialogRef.afterClosed().subscribe(result => {

      debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:LoginComponent::afterClosed:result: ',result);

    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    if (this.alertQuerySub !==null)this.alertQuerySub.unsubscribe();
    if (this.rolesSub !==null)this.rolesSub.unsubscribe();
    if (this.alertRTsub !==null)this.alertRTsub.unsubscribe();
    if (this.myPositionSub !==null)this.myPositionSub.unsubscribe();
    if (this.userPlaceSub !==null)this.userPlaceSub.unsubscribe();
    if (this.authUserSub !==null)this.authUserSub.unsubscribe();

    // if (this.authSvc.user$ !==null) this.authSvc.user$.;
    // this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  private _filter(value: any): string[] {
    debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:_filter:value',value);
    if (typeof value !== 'string') {
      this.showPositionCheckButtons = false;
      this.myPositionSvc.setCurrentPosition(value);
      // this.showPositionCheckButtons = false;
      //when an object sent it means itsm selectd or (hopefully) position detect loaded
      // this.myPositionSvc.geoSearch(value);
      return this.userPlaces;
    }
    this.myPositionSvc.clearSearchResults();
    const filterValue = value.toLowerCase();

    const newList= this.userPlaces.filter(userPlace => filterValue==''||userPlace.attributes.place.toLowerCase().includes(filterValue)||userPlace.attributes.label.toLowerCase().includes(filterValue));
    // if no matches and sarch filter long enough, show buttons to ad to saved places and set as position(verify and set)
    if(newList.length ==0 && filterValue.length >5){
      this.showPositionCheckButtons = true;
    } else {
      this.showPositionCheckButtons = false;
    }
    return newList;
  }
  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:updateOnlineStatus',`isOnline=[${this.isOnline}]`);
  }
  public updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }

  public closeVersion(): void {
    this.modalVersion = false;
  }
  private loadModalPwa(): void {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
      });
    }

    if (this.platform.IOS && this.platform.SAFARI) {
      const isInStandaloneMode = ('standalone' in window.navigator) && ((<any>window.navigator)['standalone']);
      if (!isInStandaloneMode) {
        this.modalPwaPlatform = 'IOS';
      }
    }
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  public closePwa(): void {
    this.modalPwaPlatform = undefined;
  }
  openAlertSnackBar() {
    this.isSnackOpen=true;
    if(this.snackList.length>0){
      if(this.snackList[0]!==undefined){
        let alertW=this.snackList.shift();

        // let snackBarRef = this._snackBar.openFromComponent(AlertSnackComponent, {
        let snackBarRef =   this._snackBar.open(alertW.attributes.msg + (alertW.attributes.location !== null ?' @ '+alertW.attributes.location.name : ''), 'View',{
          duration: this.durationInSeconds * 1000,
          // data: alertW
        });
        snackBarRef.onAction().subscribe(() => {
          this.alertDetail(alertW);
          this.uiStateSvc.trackInteraction('alert_notice_open', 'alert', alertW.attributes.msg, alertW.attributes.id,undefined, {
              alert_id:alertW.attributes.id,
              msg:alertW.attributes.msg,
          });
        });
        snackBarRef.afterDismissed().subscribe(() => {
          debuglog(RxJsLoggingLevel.DEBUG, 'appcomponent:openAlertSnackBar::afterDismissed::result: ',this.snackList);
          if(this.snackList.length>0){
            this.openAlertSnackBar();
          } else {
            this.isSnackOpen=false;
          }
          delete this.snackListIndex[alertW.attributes.id];
        });
        this.uiStateSvc.trackInteraction('alert_notice_view', 'alert', alertW.attributes.msg, alertW.attributes.id,undefined, {
            alert_id:alertW.attributes.id,
            msg:alertW.attributes.msg,
        });
      }else{
        this.isSnackOpen=false;
        this.snackListIndex=[];
      }
    }else{
      this.isSnackOpen=false;
      this.snackListIndex=[];
    }

  }
}
