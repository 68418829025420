<mat-card>
  <mat-card-actions>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-toolbar>
              <!-- <mat-list-item *ngFor="let link of links"> -->
              <span>
                <!-- <a matLine href="...">{{ link }}</a> -->
                <button mat-icon-button (click)="changeView($event,'all')">
                  <mat-icon [ngClass]="{'highlighted': view=='all'}">list</mat-icon>
                </button>
              </span>
              <span>
                <!-- <a matLine href="...">{{ link }}</a> -->
                <button mat-icon-button (click)="changeView($event,'fav')">
                  <i class="material-icons" [ngClass]="{'highlighted': view=='fav'}">
                    favorite
                  </i>
                </button>
              </span>
              <span>
                <!-- <a matLine href="...">{{ link }}</a> -->
                <button mat-icon-button (click)="changeView($event,'pop')">
                  <i class="material-icons" [ngClass]="{'highlighted': view=='pop'}">
                    local_activity
                  </i>
                </button>
              </span>
              <span>
                <!-- <a matLine href="...">{{ link }}</a> -->
                <button mat-icon-button (click)="changeView($event,'hot')">
                  <i class="material-icons" [ngClass]="{'highlighted': view=='hot'}">whatshot</i>
                </button>
              </span>
              <span>
                <!-- <a matLine href="...">{{ link }}</a> -->
                <button mat-icon-button (click)="changeView($event,'cool')">
                  <i class="material-icons" [ngClass]="{'highlighted': view=='cool'}">ac_unit</i>
                </button>
              </span>
              <span >
                <button mat-icon-button (click)="toggleMap($event)">
                  <i class="material-icons" [ngClass]="{'highlighted': showMap}">public</i>
                </button>
              </span>

            </mat-toolbar>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field>

            <input #searchInput matInput placeholder="Search Locations" #input (click)="inputFilterEvent($event)">
        </mat-form-field>
          <button mat-icon-button (click)="search($event)">
            <mat-icon>search</mat-icon>
          </button>

        <mat-form-field>
          <mat-select placeholder="Select categories" [formControl]="categories" multiple (blur)="loadView()">
            <mat-option *ngFor="let category of categoryList" [value]="category">{{category}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Select radius" [formControl]="radius" (blur)="loadView()">
            <mat-option  value="-1">Unlimited</mat-option>
            <mat-option *ngFor="let radius of radiusList" [value]="radius">{{radius}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-button-toggle-group [formControl]="radiusScale" aria-label="Radius Scale">
          <mat-button-toggle value="km" (click)="loadView()">KM</mat-button-toggle>
          <mat-button-toggle value="miles" (click)="loadView()">Miles</mat-button-toggle>
        </mat-button-toggle-group>


      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-actions>
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayout.xs="column" fxFlexFill>
      <div fxFlex="90" fxFlex.xs="60">
        <div class="mat-elevation-z8" fxLayout="column" fxFlexFill>
          <!-- <div class="spinner-container" *ngIf="dataSource.loading$ | async">
            <mat-spinner></mat-spinner>
          </div> -->
          <div >{{titles[view]}}</div>
          <div  layout="row" layout-align="center center"  *ngIf="locs.length == 0">No Results</div>
          <div *ngIf="showMap " >
            <!-- && locs.length > 0 -->
            <!-- <div id="leafmap"></div> -->
            <app-search-map [locs]="locs"></app-search-map>

            <!-- for google maps integration *ngIf="showMap"  -->

            <!-- <ng-container #gmap>
            </ng-container> -->
          </div>
          <div  layout="row" layout-align="center center"  *ngIf="locs.length > 0">
          <!-- <table mat-table [dataSource]="dataSourceMat | paginate: { itemsPerPage: pageSize, currentPage: pageIndex, totalItems: searchTotal,id: 'locs' }"> -->
          <table mat-table [dataSource]="dataSourceMat">

            <!-- Location Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Location </th>
              <td mat-cell *matCellDef="let element">
                <i class="material-icons icon-size-sm" [ngClass]="{'yellow': element.attributes.alerts.length>0}" *ngIf="element.attributes.alerts.length>0">
                  grade
                </i>
                {{element.attributes.name}}
                <br><small>{{element.attributes.address}}</small>
                <br><small>{{element.attributes.tags}}</small>
              </td>
            </ng-container>

            <!-- Categories Column -->
            <!-- <ng-container matColumnDef="tags">
              <th mat-header-cell *matHeaderCellDef> Categories </th>
              <td mat-cell *matCellDef="let element"> {{element.attributes.tags}} </td>
            </ng-container> -->

            <!-- Favorite Column -->
            <ng-container matColumnDef="fav">
              <th mat-header-cell *matHeaderCellDef> Favorite </th>
              <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
                <i (click)="toggleFavorite(element,$event)" class="material-icons"
                  [ngClass]="{'favorite': element.attributes.isFavorite}">
                  favorite
                </i>
              </td>
            </ng-container>

            <!-- Alert Column -->
            <!-- <ng-container matColumnDef="alert">
              <th mat-header-cell *matHeaderCellDef> Offer </th>
              <td mat-cell *matCellDef="let element">
                <i class="material-icons" [ngClass]="{'yellow': element.attributes.alerts.length>0}" *ngIf="element.attributes.alerts.length>0">
                  grade
                </i></td>
            </ng-container> -->
            <!-- Heat Column -->
            <ng-container matColumnDef="heat">
              <th mat-header-cell *matHeaderCellDef> Heat </th>
              <td mat-cell *matCellDef="let element"> <button class="location-heat"
                  [appHeatcolor]='getcolor(element.attributes.current_state/element.attributes.max_cap)'>&nbsp;
                </button></td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></tr>
          </table>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-around center" >
            <!-- <mat-paginator
                appResponsivePaginator
                [length]="length"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="pageChanged($event)"
                showFirstLastButtons>
            </mat-paginator> -->
            <div  fxLayout="column" fxLayoutAlign="space-around center" *ngIf="locs.length > 0" >
              <div layout="row"   layout-align="center center">
                <button [disabled]="pageIndex==0?true:false"  (click)="pageChanged(0)">&lt;&lt;</button>
                <button  [disabled]="pageIndex==0?true:false"  (click)="pageChanged(pageIndex-1)">&lt;</button>
                <!-- <pagination-controls
                (pageChange)="pageIndex= $event; pageChanged($event)"
                (pageBoundsCorrection)="pageChanged($event)"
                id="locs"></pagination-controls> -->
                {{pageIndex+1}}  of {{lastPage+1}}
                <button [disabled]="pageIndex==lastPage?true:false" (click)="pageChanged(pageIndex+1)">&gt;</button>
                <button  [disabled]="pageIndex==lastPage?true:false" (click)="pageChanged(lastPage)">&gt;&gt;</button>
              </div>
            </div>
            <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
            <div fxLayout="column" fxLayoutAlign="space-around center"  *ngIf="locs.length > 0" >
              <div layout="row"   layout-align="center center">
                <mat-form-field appearance="fill">
                  <mat-label>Items per page:</mat-label>

                  <!-- style="width: 50px;margin-left: 15px" -->
                  <mat-select [(value)]="pageSize" (valueChange)="pageIndex = 0;pageChanged(0)" >
                    <mat-option [value]="2">2</mat-option>
                    <mat-option [value]="5">5</mat-option>
                    <mat-option [value]="10">10</mat-option>
                    <mat-option [value]="50">50</mat-option>
                    <mat-option [value]="100">100</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <!-- <div fxLayout="column" fxLayoutAlign="center"  *ngIf="locs.length > 0" > -->

            <!-- </div> -->
          </div>

        </div>
      </div>

    </div>



  </mat-card-content>

</mat-card>
