import { Component, OnInit, ElementRef, AfterViewInit, Injector, ChangeDetectorRef  } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { UserService } from '../api/user.service';
// import { FayeService } from '../core/faye.service';
import { ChromaService } from '../core/chroma.service';
import { LocationService } from '../api/location.service';
import { AlertService } from '../api/alert.service';
import { LocationSearchParams } from '../api/location-search-params';
import { UserLocationFavService } from '../api/user-location-fav.service';
import { Location,LocationWrapper } from '../api/location';
import { Alert,AlertWrapper } from '../api/alert';
import { UserLocationFavWrapper } from '../api/user-location-fav';
import { ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { LocationDetailComponent } from '../location-detail/location-detail.component';
// import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatInput } from '@angular/material/input';
import { MatTable } from '@angular/material/table';
// import { PageEvent,  MatPaginator } from '@angular/material/paginator';
import {MatDialog,} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Observable, of,} from 'rxjs';
import {  finalize, catchError } from 'rxjs/operators';
import { MyPositionService, MyPositionData } from '../core/my-position.service';

import { BehaviorSubject, } from 'rxjs';
import { debug, debuglog, RxJsLoggingLevel } from '../core/debug.operator';
// import {Directive, HostListener} from "@angular/core";
// import * as _ from 'lodash';
import { UiStateService } from '../core/ui-state.service';
import { RealtimeSocketService,RealtimeMsgObj} from '../core/realtime-socket.service';
import { realtimeSocketServiceProvider } from '../core/realtime-socket.service.provider';
import * as L from 'leaflet';
import { environment } from '../../environments/environment';
import {Breakpoints,BreakpointObserver} from '@angular/cdk/layout';
// import { ResponsivePaginatorDirective } from '../core/responsive-paginator.directive';


@Component({
  selector: 'location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.css'],
  providers: [realtimeSocketServiceProvider,]
})
export class LocationSearchComponent implements OnInit, AfterViewInit {
  realtimeSocketService:RealtimeSocketService;
  showMap: Boolean=false;


  categories = new FormControl([]);
  categoryList: string[] = ['Bar', 'Restaurant', 'Club', 'Show', 'Other'];

  radius = new FormControl('1');
  radiusList: string[] = ['1', '5', '10', '25', '50'];
  radiusScale = new FormControl('Miles');
  radiusScaleList: string[] = ['KM', 'Miles'];

  // for table and paging of table
  // name, categories, fav toggle, has_alert,heat color
  // displayedColumns: string[] = ['name', 'tags', 'fav', 'alert', 'heat'];
  // displayedColumns: string[] = ['name', 'fav', 'alert', 'heat'];
  displayedColumns: string[] = ['name', 'fav', 'heat'];

  subscriptionAlerts: Subscription;
  subscriptionLocationUpdates: Subscription;
  alerts: Array<Object>=[];
  view: string;

  locations: Array<Location>=[];
  myFavorites: Array<UserLocationFavWrapper>=[];
  myPosition: MyPositionData;
  authsvc: AuthService;
  alertRTLock:number[]=[];
  locationRTLock:number[]=[];
  map: any;
  markers:any[]=[];




  // @ViewChild(MatPaginator, { read: false, static: false }) paginator: MatPaginator;
  // @ViewChild(MatPaginator, {  static: false }) paginator: MatPaginator;
  @ViewChild('searchInput', {  static: false }) input: ElementRef;
  @ViewChild(MatTable) table: MatTable<LocationWrapper>;
  // MatPaginator Inputs
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 50,100];
  uistateSub:Subscription=null;
  authUserSub:Subscription=null;
  rolesSub:Subscription=null;
  userFavSub:Subscription=null;
  myPositionSub:Subscription=null;
  locRTsub:Subscription=null;
  public locs:LocationWrapper[]=[];
  private locsIndex:number[];
  dataSourceMat: MatTableDataSource<LocationWrapper>;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  filterStr:string='';
  loadViewSub:Subscription=null;
  alertSub:Subscription=null;
  locAlerts:Array<any>;
  isCompressedLayout:boolean;
  titles:Object={
    all:'All Listings',
    fav: 'Your Favorites',
    pop: 'Most Popular',
    hot: 'Busiest',
    cool: 'Least Busy'
  };
  userSvc:UserService;
  searchTotal:number;
  pageIndex:number=0;



  // MatPaginator Output
  // pageEvent: PageEvent;

  // constructor(private chromaService: ChromaService) {
  //   chromaService.setColors(['black', 'blue', 'red']);
  //   this.getColor=chromaService.getHeatColor;
  // }
  constructor(private auth: AuthService,
    // private faye: FayeService,
    private chroma: ChromaService,
    private locationSvc: LocationService,
    private alertSvc: AlertService,
    private uiStateSvc: UiStateService,
    private userLocationFavSvc: UserLocationFavService,
    private myPositionSvc: MyPositionService,
    public dialog: MatDialog,
    private injector: Injector,
    private usrSvc: UserService,
    private changeDetectorRefs: ChangeDetectorRef,
    private responsive: BreakpointObserver,

  ) {
      this.authsvc = auth;
      this.userSvc =usrSvc;
    //   this.subscriptionAlerts = this.faye.getAlertMessage().subscribe(message => { this.alerts.push(message); });
    //   this.subscriptionLocationUpdates = this.faye.getLocUpdateMessage().subscribe(message => {
    //   // parse the message for the lcoation id,
    //   // update the occupancy and  color
    // });
  }
  ngOnInit() {


    this.responsive.observe([
      Breakpoints.HandsetPortrait,
      // Breakpoints.TabletLandscape,
      ])
      .subscribe(result => {

        this.isCompressedLayout = false;

        if (result.matches) {
          this.isCompressedLayout = true;
        }
      });
    this.uiStateSvc.getShowMapState().subscribe(state => {
      this.showMap=state;
      debuglog(RxJsLoggingLevel.DEBUG, 'location-search:getShowMapState', this.showMap);
    });

    // this.showMap = false;
    // for the Table
    this.view = 'all';
    //    this.dataSource.paginator = this.paginator;
    debuglog(RxJsLoggingLevel.TRACE, 'location-search color check', this.chroma.getHeatColor(.75));

    this.categories.valueChanges.subscribe(value => this.loadView());
    this.radius.setValue('1', { onlySelf: true });
    this.radius.valueChanges.subscribe(value => this.loadView());
    this.radiusScale.setValue('Miles', { onlySelf: true });
    this.radiusScale.valueChanges.subscribe(value => this.loadView());
    this.myPositionSvc.getCurrentPositionObservable().subscribe((pos) => {
      debuglog(RxJsLoggingLevel.DEBUG, 'location-search:getCurrentPositionObservable new position', pos);
      this.myPosition = pos;
      // this.loadView();
    });

    debuglog(RxJsLoggingLevel.DEBUG,'location-search::ngoninit : table ',this.table);
    this.authsvc.user$.subscribe((user)=> {
      debuglog(RxJsLoggingLevel.DEBUG,'location-search::ngoninit : table ',this.table,user);
      if (true) {
        debuglog(RxJsLoggingLevel.DEBUG,'location-search::ngoninit authStateChange: logged in',user);
        this.userLocationFavSvc.getUserFavorites().subscribe(result => {
          this.myFavorites = result;
          this.myPositionSub=this.myPositionSvc.getCurrentPositionObservable().subscribe((pos)=>{
            this.myPosition=pos;
          });
          this.loadViewMat(this.myFavorites,this.view,
            this.radius.value!==null?Number(this.radius.value):5,
            this.radiusScale.value!==null?this.radiusScale.value:'miles',
            // this.categories.value,
            [],
            this.myPosition!==undefined?Number(this.myPosition.latitude):null,
            this.myPosition!==undefined?Number(this.myPosition.longitude):null,
            '',
            // sortDirection,
            this.pageIndex, 10);
        });
        this.userSvc.myRoles$.subscribe(results=>{
          this.realtimeSocketService = this.injector.get(RealtimeSocketService);
          debuglog(RxJsLoggingLevel.DEBUG, 'location-search::realtime_msg::new Socket', this.realtimeSocketService);
          // this.realtimeSocketService.emit("subscribe", 'realtime_msg');
          this.realtimeSocketService.locs$.subscribe((msg:RealtimeMsgObj[])=> {
            debuglog(RxJsLoggingLevel.DEBUG, 'location-search::realtime_msg::new loc',msg, this.table);
            if(msg[0].msg["resource"] =='locations'&& !this.locationRTLock.includes(Number(msg[0].msg.id))){
              this.locationRTLock.push(Number(msg[0].msg.id));
              this.realtimeUpdate({attributes:msg[0].msg.obj as Location}, this.table);
              // this.changeDetectorRefs.detectChanges();
              // this.table.renderRows();
              // this.loadView(); // TODO find better solution that doesnt haave whole table flash
              this.locationRTLock.splice(this.locationRTLock.indexOf(Number(msg[0].msg.id)),1);
            }
          });;
          this.realtimeSocketService.alerts$.subscribe((msg:RealtimeMsgObj[])=> {
            debuglog(RxJsLoggingLevel.DEBUG, 'location-search::realtime_msg::new alert',msg, this.table);
            if(msg[0].msg["resource"] =='alerts'&& !this.alertRTLock.includes(Number(msg[0].msg.id))){
              this.alertRTLock.push(Number(msg[0].msg.id));
              if((msg[0].msg.obj as Alert).location_id && this.locs[this.locsIndex[(msg[0].msg.obj as Alert).location_id]]!==undefined ){

                let locAlertIDs=this.locs[this.locsIndex[(msg[0].msg.obj as Alert).location_id]].attributes.alerts.map(function(alert,i){
                  return alert.attributes.id;
                });
                if(!locAlertIDs.includes((msg[0].msg.obj as Alert).id)){
                  if((msg[0].msg.obj as Alert).location.demo_mode){
                    (msg[0].msg.obj as Alert).msg=(msg[0].msg.obj as Alert).msg +' ('+(msg[0].msg.obj as Alert).id + ') RT';
                  }
                  this.locs[this.locsIndex[(msg[0].msg.obj as Alert).location_id]].attributes.alerts.push({id:''+msg[0].msg.obj.id,attributes:msg[0].msg.obj as Alert});
                  debuglog(RxJsLoggingLevel.DEBUG, 'location-search::realtime_msg:: alert add', (msg[0].msg.obj as Alert),this.locs[this.locsIndex[(msg[0].msg.obj as Alert).location_id]]);
                }

              }
              this.alertsFilter();
              // this.realtimeUpdate({attributes:msg[0].msg.obj as Location}, this.table);
              // this.changeDetectorRefs.detectChanges();
              // this.table.renderRows();
              // this.loadView(); // TODO find better solution that doesnt haave whole table flash
              this.alertRTLock.splice(this.alertRTLock.indexOf(Number(msg[0].msg.id)),1);
            }
          });;
        });
      }

    });
  }

  ngAfterViewInit() {
    // server-side search
    debuglog(RxJsLoggingLevel.DEBUG,'location-search::ngAfterViewInit this.input',this.input);
    // this.loadMap();

    // took out this block to eliminate error but needs to be refactored TODO
    // fromEvent(this.input.nativeElement, 'keyup')
    //   .pipe(
    //     debounceTime(150),
    //     distinctUntilChanged(),
    //     tap(() => {
    //       this.paginator.pageIndex = 0;
    //       this.loadView();
    //     })
    //   )
    //   .subscribe();
    // this.paginator.page
    //   .pipe(
    //     tap(() => this.loadView())
    //   )
    //   .subscribe();

  }

  ngOnDestroy(): void {
    if (this.rolesSub !==null)this.rolesSub.unsubscribe();
    if (this.authUserSub !==null)this.authUserSub.unsubscribe();
    if (this.userFavSub !==null)this.userFavSub.unsubscribe();
    if (this.myPositionSub !==null)this.myPositionSub.unsubscribe();
    if (this.locRTsub !==null)this.locRTsub.unsubscribe();
    if (this.uistateSub !==null)this.uistateSub.unsubscribe();
    // unsubscribe to ensure no memory leaks
    // this.subscriptionAlerts.unsubscribe();
    // this.subscriptionLocationUpdates.unsubscribe();
  }
  loadView() {
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::loadView params', {
      view: this.view ? this.view : 'all',
      radius: this.radius.value != undefined||this.radius.value == "-1" ? this.radius.value : '1',
      scale: this.radiusScale.value != undefined ? this.radiusScale.value : 'miles',
      categories: this.categories.value != undefined ? this.categories.value : [],
      myLocation: this.myPosition
    });
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::loadView  before position');
    // this.myPosition=this.myPositionSvc.getCurrentPosition();
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::loadView  after position',{
      fav:this.myFavorites,
        view:this.view,
        radius:this.radius.value!==null?Number(this.radius.value):5,
        scale:this.radiusScale.value !== null ? this.radiusScale.value : 'miles',
        categories:[],
        latitude:this.myPosition !==undefined?Number(this.myPosition.latitude):null,
        longitude:this.myPosition !==undefined?Number(this.myPosition.longitude):null,
        filter:this.input!==undefined?this.input.nativeElement.value:'',
        // sortDirection,
        // index:this.paginator!==undefined?this.paginator.pageIndex:0, size:this.paginator!==undefined?this.paginator.pageSize:10
    }
    // sortDirection,
    );
    this.loadViewMat(this.myFavorites,
      this.view,
      this.radius.value!==null?Number(this.radius.value):5,
      this.radiusScale.value !== null ? this.radiusScale.value : 'miles',
      [],
      this.myPosition !==undefined?Number(this.myPosition.latitude):null,
      this.myPosition !==undefined?Number(this.myPosition.longitude):null,
      this.input!==undefined?this.input.nativeElement.value:'',
      // sortDirection,
      // this.paginator!==undefined?this.paginator.pageIndex:0, this.paginator!==undefined?this.paginator.pageSize:10
    );
    // event(action:, category?:, label?: string, value?: number, interaction?: boolean, options?: Object)

    this.uiStateSvc.trackInteraction('location_search', 'location',  'Location Search', undefined,undefined, {
        view:this.view,
        radius:this.radius.value!==null?Number(this.radius.value):5,
        scale:this.radiusScale.value !== null ? this.radiusScale.value : 'miles',
        // categories:[],
        // latitude:this.myPosition !==undefined?Number(this.myPosition.latitude):null,
        // longitude:this.myPosition !==undefined?Number(this.myPosition.longitude):null,
        filter:this.input!==undefined?this.input.nativeElement.value:'',
        // sortDirection,
        // index:this.paginator!==undefined?this.paginator.pageIndex:0, size:this.paginator!==undefined?this.paginator.pageSize:10
    });

  }
  changeView(e:any, view:string) {
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::viewChange', view);
    e.stopPropagation();
    if (view != this.view) {
      this.view = view;
      this.locs=[];
      this.locsIndex=[];
      this.pageIndex=0;
      this.loadView();
    }
  }
  onRowClicked(row:LocationWrapper) {
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::Row clicked: ', row);
    const dialogRef = this.dialog.open(LocationDetailComponent, {
      data: {loc: row,},
    });
  }
  toggleMap(e:any) {
    this.showMap = this.uiStateSvc.toggleShowMap();

    e.stopPropagation();
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::toggleMap', this.showMap);
    // if(this.showMap) this.loadMap();
    //  _updateMap();
  }
  inputFilterEvent(e:any) {
    this.input.nativeElement.focus();
    e.stopPropagation();
    e.preventDefault();
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::click inside input');
    return false;
  }

  getcolor(heat:Number) {
    let color = this.chroma.getHeatColor(heat);
    debuglog(RxJsLoggingLevel.TRACE, 'location-search::getcolor ' + heat, color);
    return color;
  }

   toggleFavorite(loc:LocationWrapper,$event) {
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search:toggleFavorite loc ' , loc);
    if (this.authsvc.user== undefined || this.authsvc.user.is_anonymous){
      // show dialog dircting user to become registered
      debuglog(RxJsLoggingLevel.DEBUG, 'location-search::toggleFavorite -anon user ' );
    }else{
      // toggle fav status
      loc.attributes.isFavorite= !loc.attributes.isFavorite;
      if(loc.attributes.isFavorite){
        // add user_location_fav entry
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::toggleFavorite add loc ' , loc);
        this.userLocationFavSvc.addFavorite(loc.attributes.id).subscribe(fav => {
          // this.myFavorites = result;
          // add new entry to my favorites
          this.setLocationFav(loc.attributes.id,fav.attributes.id);
          this.myFavorites.push(fav);
          debuglog(RxJsLoggingLevel.DEBUG, 'location-search::toggleFavorite add loc:result ' , fav);
        });
      }else{
        // delete entry
        // remove from my favorites
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::toggleFavorite remove loc ' , loc);
        function filterFunc(x:UserLocationFavWrapper,index:number,favArray: Array<UserLocationFavWrapper>){return x.attributes.location_id != loc.attributes.id;};
        let filterArr=this.myFavorites.filter(filterFunc,this);
        this.myFavorites=filterArr;
        // this.myFavorites.splice(filterArr[0], 1);
        if(loc.attributes.favId !== null){
          this.userLocationFavSvc.deleteFavorite(loc.attributes.favId).subscribe(result => {
            debuglog(RxJsLoggingLevel.DEBUG, 'location-search::toggleFavorite remove loc:result ' , result);

          });
        }
        this.removeLocationFav(loc.attributes.id);
      }
      this.uiStateSvc.trackInteraction('location_favorite', 'location', loc.attributes.name, loc.attributes.id,undefined, {
          location_id:loc.attributes.id,
          name:loc.attributes.name,
          fav: loc.attributes.isFavorite
      });
    }


  }
  setLocationFav(locationId:number,favId:number){
    this.locs[this.locsIndex[locationId]].attributes.isFavorite = true;
    this.locs[this.locsIndex[locationId]].attributes.favId = favId;

  }
  removeLocationFav(locationId:number){
    this.locs[this.locsIndex[locationId]].attributes.isFavorite = false;
    this.locs[this.locsIndex[locationId]].attributes.favId = null;

  }
  realtimeUpdate(locW:LocationWrapper,table:MatTable<LocationWrapper>){
    debuglog(RxJsLoggingLevel.DEBUG, "location-search::Datasource RealtimeUpdate enter",locW,this.locs,this.locsIndex, table);
    // if(this.locsIndex[Number(locW.attributes.id)]== undefined){
    //   // add to the list
    //   locW.attributes.alerts=[];
    //   locW.attributes.isFavorite=false;
    //   locW.attributes.favId=null;
    //   debuglog(RxJsLoggingLevel.DEBUG, "location-search::Datasource RealtimeUpdate add",locW, table);
    //   this.locs.push(locW);
    //   // this.locsReindex();
    //   this.locsIndex[Number(locW.attributes.id)]=this.locs.length;
    // }else{
    if(this.locsIndex[Number(locW.attributes.id)]!== undefined){
      this.locs[this.locsIndex[Number(locW.attributes.id)]].attributes.current_state=locW.attributes.current_state;
      debuglog(RxJsLoggingLevel.DEBUG, "location-search::Datasource RealtimeUpdate update",locW,this.locs, table);
      this.locs=[...this.locs];
      this.sortData();

      this.dataSourceMat = new MatTableDataSource(this.locs);
      this.dataSourceMat.filterPredicate = (data: LocationWrapper, filter: string) => data.attributes.name.toLowerCase().indexOf(filter) != -1;
      // this.dataSourceMat.paginator = this.paginator;
      this.uiStateSvc.setLocations(this.locs);
    }
    // this.locs=[...this.locs];
    // this.sortData();
    //
    // this.dataSourceMat = new MatTableDataSource(this.locs);
    // this.dataSourceMat.filterPredicate = (data: LocationWrapper, filter: string) => data.attributes.name.toLowerCase().indexOf(filter) != -1;
    // this.dataSourceMat.paginator = this.paginator;
    // this.uiStateSvc.setLocations(this.locs);
    // table.renderRows();

  }
  locsReindex(){
    let locsIndex=[]
    this.locs.map(function(loc:LocationWrapper,i:number){
      debuglog(RxJsLoggingLevel.TRACE, 'location-search::LocationsDataSource::loadview:favs mapping','locsReindex in loop alert ',i, alert );
      locsIndex[Number(loc.attributes.id)] = Number(i);
      // alert.attributes.alerts=[];
      // alert.attributes.isFavorite=false;
      // alert.attributes.favId=null;
      return loc;
    });
    this.locsIndex=locsIndex;
  }
  loadViewMat(favs:Array<UserLocationFavWrapper> =[],view = 'all', radius = 5, scale = 'miles', categories: [], latitude:number|null, longitude:number|null, filter = this.filterStr,
    // sortDirection = 'asc',
    pageIndex = this.pageIndex, pageSize = this.pageSize) {
      debuglog(RxJsLoggingLevel.DEBUG, "location-search::Datasource Loadview enter",);

    this.loadingSubject.next(true);
    // filter, sortDirection,pageIndex, pageSize
    let lastParams:LocationSearchParams = this.locationSvc.getLastSearchParams();
    debuglog(RxJsLoggingLevel.DEBUG, "location-search::Datasource Loadview lastParams", lastParams);
    let currentParams:LocationSearchParams={
      view: view,
      radius: radius,
      scale: scale,
      categories: categories,
      filter: filter,
      latitude: null,
      longitude: null,
      // sortDirection: sortDirection,
      pageIndex: pageIndex,
      pageSize: pageSize
    };
    if(latitude!== undefined){
      currentParams.latitude=latitude;
      currentParams['longitude']=longitude;
    }
    debuglog(RxJsLoggingLevel.DEBUG, "location-search::Datasource Loadview currentParams", currentParams);
    this.loadViewSub =this.locationSvc.loadView(currentParams).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false)),
      debug(RxJsLoggingLevel.DEBUG, "location-search::Loading locations from backend")
    ).subscribe(result => {
      // create location index from results
      let locations=result.locations.data;
      this.alertSub=this.alertSvc.getAlerts().subscribe(alerts => {
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::LocationsDataSource::loadview:subscribe: before locsIndex', locations,this.locsIndex);
        let locsIndex:Array<number>=[];
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::LocationsDataSource::loadview:subscribe: after locsIndex', this.locsIndex);
        locations.map(function(loc,i){
          debuglog(RxJsLoggingLevel.TRACE, 'location-search::LocationsDataSource::loadview:subscribe: before locsIndex','in loop loc ',i, loc );
          locsIndex[Number(loc.attributes.id)] = Number(i);
          loc.attributes.alerts=[];
          loc.attributes.isFavorite=false;
          loc.attributes.favId=null;
          return loc;
        });
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::LocationsDataSource::loadview:results: locsIndex',locsIndex);
        let locAlerts=alerts.filter(function(alert){
          debuglog(RxJsLoggingLevel.TRACE, 'location-search::LocationsDataSource::build locAlert', alert,locsIndex[alert.attributes.location_id],locations[locsIndex[alert.attributes.location_id]]);
          return alert.attributes.location_id ==null?false:isObject(result.locations.data[locsIndex[alert.attributes.location_id]])?true:false;
        });
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::LocationsDataSource::loadview:done locAlerts', locAlerts,locations);
        locAlerts.map(function(alert){
          if(locations[locsIndex[alert.attributes.location_id]].attributes.demo_mode){
            alert.attributes.msg=alert.attributes.msg+' ('+alert.attributes.id + ')';
            debuglog(RxJsLoggingLevel.TRACE, 'location-search::LocationsDataSource::loadview:alert demo', alert,locations[locsIndex[alert.attributes.location_id]]);
          }
          locations[locsIndex[alert.attributes.location_id]].attributes.alerts.push(alert);
          debuglog(RxJsLoggingLevel.TRACE, 'location-search:LocationsDataSource::loadview:alert mapping', alert,locations[locsIndex[alert.attributes.location_id]]);
          return alert.attributes.location_id;
        });

        // map in favorites to locations results
        favs.map( function(fav){
          debuglog(RxJsLoggingLevel.TRACE, 'location-search::LocationsDataSource::loadview:favs mapping','in loop f.loc_id '+fav.attributes.location_id+ ' f.id '+fav.attributes.id + ' loc.indx '+ locsIndex[fav.attributes.location_id] );
          if(locsIndex[fav.attributes.location_id] !== undefined){
            locations[locsIndex[fav.attributes.location_id]].attributes.isFavorite = true;
            locations[locsIndex[fav.attributes.location_id]].attributes.favId = fav.id;
            debuglog(RxJsLoggingLevel.TRACE, 'location-search::LocationsDataSource::loadview:favs mapping',locations[locsIndex[fav.attributes.location_id]]);
          }
          return fav;
        });
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::loadview:results::LocationsDataSource enhanced locations', result.locations.data);

        // this.locs.length = result.total;
        this.searchTotal=result.total;
        // this.locs.splice(pageIndex*pageSize - pageSize,result.locations.data.length, ...locations);
        this.locs=locations;
        // if(this.showMap)this.addMarkers();
        this.uiStateSvc.setLocations(this.locs);
        this.uiStateSvc.setRefreshMapIfOpen(true);
        this.locsIndex=locsIndex;
        this.locAlerts=locAlerts;
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::LocationsDataSource::loadview:results: locsIndex', this.locsIndex);
        // this.locationsSubject.next(this.locs);
        // this.uiStateSvc.setLocations(this.locs);

        // let viewLocs=this.locs.slice((this.pageIndex +1 )* this.pageSize - this.pageSize, (this.pageIndex+1) * this.pageSize);
        let viewLocs=this.locs.slice(this.pageIndex * this.pageSize - this.pageSize, (this.pageIndex) * this.pageSize);
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::LocationsDataSource::loadview:results: viewLocs', viewLocs,this.pageIndex,this.pageSize,this.locs);
        this.dataSourceMat = new MatTableDataSource(this.locs);
        this.dataSourceMat.filterPredicate = (data: LocationWrapper, filter: string) => data.attributes.name.toLowerCase().indexOf(filter) != -1;
        // this.dataSourceMat.paginator = this.paginator;
        this.loadViewSub.unsubscribe();

      });
    });




  }

  alertsFilter():void{
    let prevLen=this.locAlerts.length;
    let newList= this.locAlerts.filter(alert => {
      if(alert==undefined)return false;
      if(Date.parse(alert.attributes.date_end) > Date.now()){
        return true;
      }else{
        debuglog(RxJsLoggingLevel.DEBUG, 'location-search::alertsFilter:: remove', alert);
        if(alert.attributes.location_id !== null){
          this.locs[this.locsIndex[alert.attributes.location_id]].attributes.alerts=this.locs[this.locsIndex[alert.attributes.location_id]].attributes.alerts.filter((alertL)=>{
            if(alertL==undefined) return false;
            if(alertL.attributes.id==alert.attributes.id){
              return false;
            }else return true;

          });
        }
        return false;
      }
    });
    this.locAlerts=newList;
    // if(newList.length !== prevLen)this.alertsReindex();

    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::alertsFilter::', prevLen,newList.length,this.alerts);

  }

  sortData() {
    // const data = this.desserts.slice();
    // if (!sort.active || sort.direction === '') {
    //   this.sortedData = data;
    //   return;
    // }

    this.locs = this.locs.sort((a, b) => {
      // const isAsc = this.direction === 'asc';
      switch (this.view) {
        case 'all':
          return compare(a.attributes.name.toLowerCase(), b.attributes.name.toLowerCase(), true);
        case 'fav':
          return compare(a.attributes.isFavorite?1:0 , b.attributes.isFavorite ?1:0, false);
        case 'hot':
          return compare(a.attributes.current_state/a.attributes.max_cap, b.attributes.current_state/b.attributes.max_cap, false);
        case 'cool':
          return compare(a.attributes.current_state/a.attributes.max_cap, b.attributes.current_state/b.attributes.max_cap, true);
        case 'pop':
          return compare(a.attributes.fanscnt, b.attributes.fanscnt, false);
        default:
          return 0;
      }
    });
    this.locsReindex();
  }

  pageChanged(event){
    // this.loading = true;
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::pageChanged event', event);
    let previousIndex = this.pageIndex;
    let pageIndex = event;
    let pageSize = this.pageSize;
    this.pageIndex = event;
    // this.pageSize = event.pageSize;



    let previousSize = this.pageSize * previousIndex;

    this.loadViewMat(this.myFavorites,
      this.view,
      this.radius.value!==null?Number(this.radius.value):5,
      this.radiusScale.value !== null ? this.radiusScale.value : 'miles',
      [],
      this.myPosition!==undefined?Number(this.myPosition.latitude):null,
      this.myPosition!==undefined?Number(this.myPosition.longitude):null,
      this.filterStr, pageIndex, pageSize);
  }
  search(e:any):void{
    e.stopPropagation();
    e.preventDefault();
    const filter = this.input.nativeElement.value ;
    this.filterStr=filter.trim().toLowerCase();
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::search: input', this.input);
    this.dataSourceMat.filter=this.filterStr;
    this.locs=[];
    this.locsIndex=[];
    this.loadViewMat(
      this.myFavorites,
      this.view,
      this.radius.value!==null?Number(this.radius.value):5,
      this.radiusScale.value !== null ? this.radiusScale.value : 'miles',
      [],
      Number(this.myPosition.latitude),
      Number(this.myPosition.longitude),
      this.filterStr,
      this.pageIndex, this.pageSize
    );
  }

  private loadMap(): void {
    this.map = L.map('leafmap').setView([0, 0], 1);

    L.tileLayer('https://api.maptiler.com/maps/openstreetmap/?key='+environment.maptiler.accessToken+'#0.7/22.80535/2.86559', {
      attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
      crossOrigin: true,
      minZoom: 1,
      tileSize: 512,
      zoomOffset: -1,
      accessToken: environment.maptiler.accessToken,
    }).addTo(this.map);

    // const position: any = await this.locationService.getCurrentLocation();
    this.myPositionSvc.getDevicePosition()
    .subscribe((position: any) => {
      // let position= this.myPositionSvc.currentPosition;
      if((position.latitude !== undefined && position.latitude !== null) && (position.longitude !== undefined && position.longitude !==null )){
        this.map.flyTo([position.latitude, position.longitude], 13);

        const icon = L.icon({
          iconUrl: 'assets/images/marker-icon.png',
          shadowUrl: 'assets/images/marker-shadow.png',
          popupAnchor: [13, 0],
        });

        const marker = L.marker([position.latitude, position.longitude], { icon }).bindPopup('You are here.');
        marker.addTo(this.map);
        this.markers.push(marker);
      }
    });
    this.addMarkers();
  }
  addMarkers():void{
    this.markers.map((marker)=>{
      this.map.removeLayer(marker);
    });
    this.markers=[];
    this.locs.map((loc)=>{
      if(loc.attributes.latitude!== undefined && loc.attributes.longitude!== undefined){
        const icon = L.icon({
          iconUrl: 'assets/images/marker-icon.png',
          shadowUrl: 'assets/images/marker-shadow.png',
          popupAnchor: [13, 0],
        });

        const marker = L.marker([loc.attributes.latitude, loc.attributes.longitude], { icon }).bindPopup(loc.attributes.name);
        marker.addTo(this.map);
        this.markers.push(marker);
      }
    });
    debuglog(RxJsLoggingLevel.DEBUG, 'location-search::addMarkers', this.markers);
  }

  get lastPage() {
    return Math.floor((this.searchTotal-1)/this.pageSize);
  }
}



const isObject = (obj) => obj === Object(obj);
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
