<h1 mat-dialog-title>Remove Team Member</h1>

<div mat-dialog-content>
  <p>Are you sure you want to remove:</p>
  <p>{{data['team'].user.name}} </p>
  <p>{{data['team'].user.email}}</p>
  <div>{{data['team'].role}}  @ {{data['team'].location.name}}</div>


</div>
<div mat-dialog-actions>
  <button mat-button (click)="delete()">Remove</button>
  <button mat-button (click)="cancel()">Cancel</button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>
