<div class="mat-elevation-z8">Location management
  <!-- add input to filter -->
  <input #searchInput placeholder="filter test">
  <button mat-icon-button  (click)="search()"><mat-icon>search</mat-icon></button>
  <!-- add button for add new location -->
  <button mat-icon-button  routerLink="/location-crud"><mat-icon>add</mat-icon></button>
</div>
<mat-card>
  <mat-card-actions>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            additional filters
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field>
          <mat-select placeholder="Select categories" [formControl]="categories" multiple >
            <mat-option *ngFor="let category of categoryList" [value]="category">{{category}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select placeholder="Select radius" [formControl]="radius">
            <mat-option  value="-1">Unlimited</mat-option>
            <mat-option *ngFor="let radius of radiusList" [value]="radius">{{radius}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-button-toggle-group [formControl]="radiusScale" aria-label="Radius Scale"  (change)="loadViewMat()">
          <mat-button-toggle value="km">KM</mat-button-toggle>
          <mat-button-toggle value="miles">Miles</mat-button-toggle>
        </mat-button-toggle-group>


      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-actions>
  <mat-card-content>
    <div class="mat-elevation-z8">
      <div class="spinner-container" *ngIf="loading$ | async">
        <mat-spinner></mat-spinner>
      </div>
      <!-- TODO use ngTemplate to swap in message to search first -->
      <table mat-table [dataSource]="dataSourceMat">

        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Location </th>
          <td mat-cell *matCellDef="let element"> {{element.attributes.name}}
            <br><small>{{element.attributes.address}}</small>
            <br><small>{{element.attributes.tags}}</small>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="fanscnt">
          <th mat-header-cell *matHeaderCellDef>fans </th>
          <td mat-cell *matCellDef="let element"> {{element.attributes.fanscnt}} </td>
        </ng-container>

        <!-- Weight Column -->
        <!-- <ng-container matColumnDef="tags">
          <th mat-header-cell *matHeaderCellDef> categories </th>
          <td mat-cell *matCellDef="let element"> {{element.attributes.tags}} </td>
        </ng-container> -->

        <!-- Symbol Column -->
        <ng-container matColumnDef="heat">
          <th mat-header-cell *matHeaderCellDef> Heat </th>
          <td mat-cell *matCellDef="let element"> <button class="location-heat" [appHeatcolor]='getcolor(element.attributes.current_state/element.attributes.max_cap)' >&nbsp;
          </button></td>
        </ng-container>
        <!-- Action Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element" class="button-container">

            <button mat-icon-button  routerLink="/location-crud/{{ element.attributes.id }}"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button  (click)="deleteLocation(element)"><mat-icon>delete</mat-icon></button>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <!-- <mat-paginator [length]="length"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageChanged($event)"
              showFirstLastButtons>
      </mat-paginator> -->
      <div fxLayout="column" fxLayoutAlign="space-around center" >
        <!-- <mat-paginator
            appResponsivePaginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageChanged($event)"
            showFirstLastButtons>
        </mat-paginator> -->
        <div  fxLayout="column" fxLayoutAlign="space-around center" *ngIf="locs.length > 0" >
          <div layout="row"   layout-align="center center">
            <button [disabled]="pageIndex==0?true:false"  (click)="pageChanged(0)">&lt;&lt;</button>
            <button  [disabled]="pageIndex==0?true:false"  (click)="pageChanged(pageIndex-1)">&lt;</button>
            <!-- <pagination-controls
            (pageChange)="pageIndex= $event; pageChanged($event)"
            (pageBoundsCorrection)="pageChanged($event)"
            id="locs"></pagination-controls> -->
            {{pageIndex+1}}  of {{lastPage+1}}
            <button [disabled]="pageIndex==lastPage?true:false" (click)="pageChanged(pageIndex+1)">&gt;</button>
            <button  [disabled]="pageIndex==lastPage?true:false" (click)="pageChanged(lastPage)">&gt;&gt;</button>
          </div>
        </div>
        <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
        <div fxLayout="column" fxLayoutAlign="space-around center"  *ngIf="locs.length > 0" >
          <div layout="row"   layout-align="center center">
            <mat-form-field appearance="fill">
              <mat-label>Items per page:</mat-label>

              <!-- style="width: 50px;margin-left: 15px" -->
              <mat-select [(value)]="pageSize" (valueChange)="pageIndex = 0;pageChanged(0)" >
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="10">10</mat-option>
                <mat-option [value]="50">50</mat-option>
                <mat-option [value]="100">100</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>

</mat-card>
