<a [routerLink]="['/location-crud/'+locationId]">
  Back
</a>
<div class="location-container">
  <form [formGroup]="roomFrm" (ngSubmit)="addRoom(locationId,id,roomFrm.value)" *ngIf="roomFrm">
    <div class="row expanded">
      <div  layout="column" layout-align="center end">
        <div>
          <label>Name
            <input type="text" name="name" [formControl]="roomFrm.controls['name']" formControlName="name">
          </label>
          <div class="alert" *ngIf="!roomFrm.controls['name'].valid && roomFrm.controls['name'].touched">
            You must specify a name that's at least 10 characters long.
          </div>
        </div>
        <div *ngIf="userSvc.checkAuthorization(['superadmin','admin','owner','manager']); else elseBlockMaxCap">
          <label>Max Capacity
            <input type="text" name="max_cap" [formControl]="roomFrm.controls['max_cap']" formControlName="max_cap">
          </label>
          <div class="alert" *ngIf="!roomFrm.controls['max_cap'].valid && roomFrm.controls['max_cap'].touched">
            You must specify an Integer greater than zero.
          </div>
        </div>
        <ng-template #elseBlockMaxCap>
          <label>Max Capacity: {{roomFrm.get('max_cap').value}}
          </label>
        </ng-template>
        <div *ngIf="userSvc.checkAuthorization(['superadmin','admin','owner','manager']); else elseBlockCurrentState">
          <label>Current Crowd Size
            <input type="text" name="current_state" [formControl]="roomFrm.controls['current_state']" formControlName="current_state">
          </label>
          <div class="alert" *ngIf="!roomFrm.controls['current_state'].valid && roomFrm.controls['current_state'].touched">
            You must specify an Integer equal to or greater than 0.
          </div>

        </div>
        <ng-template #elseBlockCurrentState>
          <label>Current Crowd Size: {{roomFrm.get('current_state').value}}

          </label>
        </ng-template>

        <input type="submit" class="cta-btn filled" value="Save" >
        <!-- [disabled]="!doorFrm.valid" -->

      </div>
    </div>
  </form>
</div>
<div>
  <app-room-sensor-manage [dsRoomSensors]="dsRoomSensors" [locationId]="locationId" [roomId]="id"></app-room-sensor-manage>
</div>
<div>
  <app-door-manage [dsDoors]="dsDoors" [locationId]="locationId"></app-door-manage>
</div>
