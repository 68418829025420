import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {  debuglog, RxJsLoggingLevel } from '../core/debug.operator';

import { UserService } from '../api/user.service';
import { Location,  } from '../api/location';
import { User,TeamMember,TeamMemberWrapper, TeamInvite } from '../core/user';
import { LocationService } from '../api/location.service';
import {  Subscription } from 'rxjs';


@Component({
  selector: 'app-team-invite-delete',
  templateUrl: './team-invite-delete.component.html',
  styleUrls: ['./team-invite-delete.component.css']
})
export class TeamInviteDeleteComponent {
  locSub:Subscription;
  constructor(
      private userSvc: UserService,
      private locSvc: LocationService,
      public dialogRef: MatDialogRef<TeamInviteDeleteComponent>,
      @Inject(MAT_DIALOG_DATA) public data:  {invite:TeamInvite, locationId:number},) { }

    ngOnInit() {
      debuglog(RxJsLoggingLevel.DEBUG,'team-invite-delete::ngOnInit: data',this.data);
    }
    cancel(): void {
      debuglog(RxJsLoggingLevel.DEBUG,'team-invite-delete::cancel: data');
      this.dialogRef.close();

    }
    delete(): void {
      debuglog(RxJsLoggingLevel.DEBUG,'team-invite-delete::delete: data');
      if(this.userSvc.checkAuthorization(['superadmin','admin'])||this.userSvc.checkLocationRole(this.data['team'].id,['owner']) )this.locSub=this.locSvc.deleteTeamInvite(this.data['locationId'],this.data['invite'].id).subscribe((result)=>{
        this.locSub.unsubscribe();
      });

      this.dialogRef.close();

    }
  }
