import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { AuthService } from '../core/auth.service';
import { Location } from '../api/location';
import { LocationService } from '../api/location.service';
import { UserService } from '../api/user.service';
import { debug,debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import {  Subscription } from 'rxjs';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit,OnDestroy {
  mobileQuery: MediaQueryList;
  authSvc:AuthService;
  locationSvc:LocationService;
  userSvc:UserService;
  myRoles:any;
  private _mobileQueryListener: () => void;
  locationList:Array<Location>=[];
  authUserSub:Subscription=null;
  rolesSub:Subscription=null;
  version:string='unset';

  constructor(
    changeDetectorRef: ChangeDetectorRef,
     media: MediaMatcher,
     authSvc: AuthService,
     locationSvc: LocationService,
     userSvc: UserService,
     ) {
    debuglog(RxJsLoggingLevel.DEBUG,'main.nav::constructor: enter',this);
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.version=environment.version;
    this.authSvc=authSvc;
    this.locationSvc=locationSvc;
    this.userSvc=userSvc;
    debuglog(RxJsLoggingLevel.DEBUG,'main.nav::constructor: userSvc',this.userSvc);



  }
  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);

  ngOnInit() {
    this.authUserSub=this.authSvc.userFiltered$.subscribe(user =>{
      if (user == undefined) return;
      debuglog(RxJsLoggingLevel.DEBUG,'main.nav::init: auth',user);
      this.rolesSub=this.userSvc.myRoles$.subscribe(results=>{
        debuglog(RxJsLoggingLevel.DEBUG,'main.nav::init: myRole',results);
        this.myRoles= results;
      });
      // this.userSvc.refreshMe();
      // if user is worker | manager
      if(this.userSvc.checkAuthorization(['worker','manager','owner'])){
        // get the list of locations for dropdown menu
        debuglog(RxJsLoggingLevel.DEBUG,'main.nav::init: checkAuthorization team',this.userSvc.checkAuthorization(['worker','manager','owner']));

      }


    });
  }
  checkAuth(roles:Array<string>):Boolean{
    return this.userSvc.checkAuthorization(roles);
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    if (this.rolesSub !==null)this.rolesSub.unsubscribe();
    if (this.authUserSub !==null)this.authUserSub.unsubscribe();
  }

}
