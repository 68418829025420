<!-- attributes :id, :location_id,:msg, :url, :date_end, :date_campaign_start, :dscription, :is_qr_redeem, :date_special_start, :redemption_max, :redemption_current_count, :is_multiple_redeem  -->
<a [routerLink]="[backRoutePath]">
  Back
</a>
<div class="alert-container">
  <app-alert-stats *ngIf="!isAddMode" [alertId]="id"></app-alert-stats>
  <form [formGroup]="alertFrm" (ngSubmit)="addAlert(id,alertFrm.value)" *ngIf="alertFrm" class="example-form">
    <div class="row expanded">
      <div  layout="column" layout-align="center end">
        <mat-form-field class="example-full-width">
          <mat-label>Headline</mat-label>
          <input matInput  name="msg" [formControl]="alertFrm.controls['msg']" formControlName="msg" placeholder="Ex. Secret Happy Hour" >
        </mat-form-field>
        <div class="alert" *ngIf="!alertFrm.controls['msg'].valid && alertFrm.controls['msg'].touched">
          You must specify a Headline that's at least 10 characters long.
        </div>
        <!-- <div>
          <label>Headline
            <input type="text" name="msg" [formControl]="alertFrm.controls['msg']" formControlName="msg">
          </label>
          <div class="alert" *ngIf="!alertFrm.controls['msg'].valid && alertFrm.controls['msg'].touched">
            You must specify a Headline that's at least 10 characters long.
          </div>
        </div> -->
        <!-- <mat-form-field class="example-full-width">
          <mat-label>Description</mat-label>
          <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
          <ngx-editor [editor]="editor"   [placeholder]="'Type here...'" ngDefaultControl formControlName="description" ></ngx-editor>
          <div class="alert" *ngIf="!alertFrm.controls['description'].valid && alertFrm.controls['description'].touched">
          </div>

        </mat-form-field> -->

        <section class="example-section">
          <label>Description
            <!-- <textarea  name="description" [formControl]="alertFrm.controls['description']" formControlName="description"></textarea> -->

          <!-- <div class="NgxEditor__Wrapper">
              <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
              <ngx-editor [editor]="editor"  [disabled]="false" [placeholder]="'Type here...'" formControlName="description"  [ngModel]="description"></ngx-editor>
            </div> -->
          </label>
          <!-- <div class="NgxEditor__Wrapper"> -->
              <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
              <!-- <ngx-editor [editor]="editor" [placeholder]="'Type here...'" formControlName="description" ngDefaultControl></ngx-editor> -->
              <!-- <ngx-editor [editor]="editor"  [disabled]="false" [placeholder]="'Type here...'"  [(ngModel)]="alert.attributes['description']"></ngx-editor> -->
              <ngx-editor [editor]="editor"   [placeholder]="'Type here...'" ngDefaultControl formControlName="description" height="400px" minHeight="100px"></ngx-editor>
          <!-- </div> -->
          <div class="alert" *ngIf="!alertFrm.controls['description'].valid && alertFrm.controls['description'].touched">
            <!-- You must specify a state. -->
          </div>
        </section>



        <!-- optionally add start time and duration in hours (used in ics event file)
        include recursion controls with "Just Once" option default -->

        <mat-form-field  class="example-full-width">
          <mat-label>Enter Offer date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
        <!-- <div>
          <label>Special Start (optional)
            <input type="text" name="date_special_start" [formControl]="alertFrm.controls['date_special_start']" formControlName="date_special_start"  matInput [ngxMatDatetimePicker]="special_picker" placeholder="Choose a date" [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="special_picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #special_picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
              [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
              [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
              /* Custom icon or text of Apply icon */
              <ng-template>
                /* <mat-icon>star</mat-icon> */
                <span>OK</span>
              </ng-template>
            </ngx-mat-datetime-picker>
          </label>
          <div class="alert" *ngIf="!alertFrm.controls['date_special_start'].valid && alertFrm.controls['date_special_start'].touched">
            You must specify a properly formated Start Datetime for Event Advertised
          </div>
        </div> -->
        <mat-form-field  class="example-full-width">
          <mat-label>Event Start date and time (optional)</mat-label>
          <input matInput  name="date_special_start" [formControl]="alertFrm.controls['date_special_start']" formControlName="date_special_start"  matInput [ngxMatDatetimePicker]="special_picker" placeholder="Choose a date" [min]="minDate" [max]="maxDate">
          <mat-hint>MM/DD/YYYY HH:MM </mat-hint>
          <mat-datepicker-toggle matSuffix [for]="special_picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #special_picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
            [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
            [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
            <!-- Custom icon or text of Apply icon -->
            <ng-template>
              <!-- <mat-icon>star</mat-icon> -->
              <span>OK</span>
            </ng-template>
          </ngx-mat-datetime-picker>
          <div class="alert" *ngIf="!alertFrm.controls['date_special_start'].valid && alertFrm.controls['date_special_start'].touched">
            You must specify a properly formated Start Datetime for Event Advertised
          </div>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Duration (hrs)</mat-label>
          <input matInput   name="duration_hours" [formControl]="alertFrm.controls['duration_hours']" formControlName="duration_hours" placeholder="ex. 1 or 2" >
          <!-- <div class="alert" *ngIf="!alertFrm.controls['redemption_max'].valid && alertFrm.controls['redemption_max'].touched">
            You must specify a Redemption Max of 0 or greater. 0 indicates no max.
          </div> -->
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <mat-label>Duration (min)</mat-label>
          <input matInput   name="duration_minutes" [formControl]="alertFrm.controls['duration_minutes']" formControlName="duration_minutes" placeholder="ex. 1 or 2" >
          <!-- <div class="alert" *ngIf="!alertFrm.controls['redemption_max'].valid && alertFrm.controls['redemption_max'].touched">
            You must specify a Redemption Max of 0 or greater. 0 indicates no max.
          </div> -->
        </mat-form-field>
        <section class="example-section">
        <div class="form-group mb-1">
          <label class="font-weight-bold">Frequency</label>
        </div>

        <div class="form-group">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              id="once"
              checked
              formControlName="frequency"
              [value]="oneTime"
            />
            <label
              class="custom-control-label form-check-label"
              for="once"
            >
              One Time
            </label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              id="daily"

              formControlName="frequency"
              [value]="Frequency.DAILY"
            />
            <label
              class="custom-control-label form-check-label"
              for="daily"
            >
              Daily {{getDailyText(alertFrm.value['date_special_start'])}}
            </label>
          </div>

          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              id="weekly"
              formControlName="frequency"
              [value]="Frequency.WEEKLY"
            />
            <label
              class="custom-control-label form-check-label"
              for="weekly"
            >
              Weekly {{getDailyText(alertFrm.value['date_special_start'])}}
            </label>
          </div>

          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              id="monthly"
              formControlName="frequency"
              [value]="Frequency.MONTHLY"
            />
            <label
              class="custom-control-label form-check-label"
              for="monthly"
            >
              Monthly  {{getMonthlyText(alertFrm.value['date_special_start'])}}
            </label>
          </div>
        </div>
        </section>



        <ng-container *ngIf="alertFrm.value['frequency'] === Frequency.WEEKLY">
          <div class="form-group mb-1">
            <label class="font-weight-bold">Repeat on</label>
          </div>
          <div class="btn-group btn-group-toggle mb-2">
            <mat-button-toggle-group name="Weekday" aria-label="Weekday Selction" multiple  formControlName="weekdays" >
              <mat-button-toggle value="{{weekdayMap[0]}}">Mon</mat-button-toggle>
              <mat-button-toggle value="{{weekdayMap[1]}}">Tue</mat-button-toggle>
              <mat-button-toggle value="{{weekdayMap[2]}}">Wed</mat-button-toggle>
              <mat-button-toggle value="{{weekdayMap[3]}}">Thu</mat-button-toggle>
              <mat-button-toggle value="{{weekdayMap[4]}}">Fri</mat-button-toggle>
              <mat-button-toggle value="{{weekdayMap[5]}}">Sat</mat-button-toggle>
              <mat-button-toggle value="{{weekdayMap[6]}}">Sun</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <!-- <div class="btn-group btn-group-toggle mb-2" formArrayName="onWeekday">
            <label class="btn-primary" ngbButtonLabel>
              <input type="checkbox" formControlName="0" ngbButton> Mon
            </label>
            <label class="btn-primary" ngbButtonLabel>
              <input type="checkbox" formControlName="1" ngbButton> Tue
            </label>
            <label class="btn-primary" ngbButtonLabel>
              <input type="checkbox" formControlName="2" ngbButton> Wed
            </label>
            <label class="btn-primary" ngbButtonLabel>
              <input type="checkbox" formControlName="3" ngbButton> Thu
            </label>
            <label class="btn-primary" ngbButtonLabel>
              <input type="checkbox" formControlName="4" ngbButton> Fri
            </label>
            <label class="btn-primary" ngbButtonLabel>
              <input type="checkbox" formControlName="5" ngbButton> Sat
            </label>
            <label class="btn-primary" ngbButtonLabel>
              <input type="checkbox" formControlName="6" ngbButton> Sun
            </label>
          </div> -->
        </ng-container>

        <ng-container *ngIf="alertFrm.value['frequency'] === Frequency.MONTHLY">
          <div class="form-group">
            <label class="font-weight-bold" for="onMonthday">On month day</label>
            <!-- <div class="input-group" style="width: 250px;">
              <input
                id="onMonthday"
                class="form-control"
                placeholder="yyyy-mm-dd"
                name="dp"
                formControlName="onMonthday"
                ngbDatepicker
                #d="ngbDatepicker"
              >
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-outline-secondary calendar"
                  (click)="d.toggle()"
                ></button>
              </div>
            </div> -->
          </div>
        </ng-container>



        <!-- <div>
          <label>QR Redeem
            <input type="checkbox"  value="true" name="is_qr_redeem" [formControl]="alertFrm.controls['is_qr_redeem']" formControlName="is_qr_redeem">
          </label>
          <div *ngIf="alertFrm.value['is_qr_redeem']" class="alert">
            <qrcode
            [elementType]="img"
            [qrdata]="qrvalue"
            cssClass="aclass"
            [width]="256"
            errorCorrectionLevel="M">
            </qrcode>
          </div>

        </div> -->
        <section class="example-section">
          <div layout="row">
            <mat-checkbox class="example-margin" value="true" name="is_qr_redeem" [formControl]="alertFrm.controls['is_qr_redeem']" formControlName="is_qr_redeem">QR Redeem</mat-checkbox>
          </div>
          <div layout="row">
            <label id="example-radio-group-label">Landing Page</label>
          </div>
          <div layout="row">
            <mat-radio-group name="qr_url"
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              [(ngModel)]="baseRefSelection" [ngModelOptions]="{standalone: true}">
                <mat-radio-button class="example-radio-button" value="default">default</mat-radio-button>
                <mat-radio-button class="example-radio-button" value="custom">
                  <mat-form-field>
                    <input matInput placeholder="Custom Page w/ Crowdsenz" [(ngModel)]="customRef" (ngModelChange)="baseRefSelection = 'other'" name="something" [ngModelOptions]="{standalone: true}">
                  </mat-form-field>
                </mat-radio-button>

            </mat-radio-group>
          </div>
          <div layout="row">Encoded URL: {{qrvalue}}</div>

          <div *ngIf="alertFrm.value['is_qr_redeem']" class="alert">
            <qrcode
            [elementType]="img"
            [qrdata]="qrvalue"
            cssClass="aclass"
            [width]="256"
            errorCorrectionLevel="M">
            </qrcode>
          </div>
        </section>



        <!-- <div>
          <label>Maximum Number of Redeemptions
            <input type="text" name="redemption_max" [formControl]="alertFrm.controls['redemption_max']" formControlName="redemption_max">
          </label>
          <div class="alert" *ngIf="!alertFrm.controls['redemption_max'].valid && alertFrm.controls['redemption_max'].touched">
            You must specify a Redemption Max of 0 or greater. 0 indicates no max.
          </div>
        </div> -->
        <mat-form-field class="example-full-width" *ngIf="alertFrm.value['is_qr_redeem']">
          <mat-label>Maximum Number of Redeemptions</mat-label>
          <input matInput   name="redemption_max" [formControl]="alertFrm.controls['redemption_max']" formControlName="redemption_max" placeholder="Zero means unlimited" >
          <div class="alert" *ngIf="!alertFrm.controls['redemption_max'].valid && alertFrm.controls['redemption_max'].touched">
            You must specify a Redemption Max of 0 or greater. 0 indicates no max.
          </div>
        </mat-form-field>

        <!-- <div>
          <label>Allow Multiple Redemptions
            <input type="checkbox" value="true" name="is_multiple_redeem" [formControl]="alertFrm.controls['is_multiple_redeem']" formControlName="is_multiple_redeem">
          </label>

        </div> -->
        <section class="example-section" *ngIf="alertFrm.value['is_qr_redeem']">

          <mat-checkbox class="example-margin" value="true" name="is_multiple_redeem" [formControl]="alertFrm.controls['is_multiple_redeem']" formControlName="is_multiple_redeem">Allow Multiple Redemptions</mat-checkbox>
        </section>
        <!-- <div>
          <label> URL (optional)
            <input type="text" name="url" [formControl]="alertFrm.controls['url']" formControlName="url">
          </label>
          <div class="alert" *ngIf="!alertFrm.controls['url'].valid && alertFrm.controls['url'].touched">
            You must specify a properly formated site url.
          </div>
        </div> -->
        <mat-form-field class="example-full-width">
          <mat-label>URL (optional)</mat-label>
          <input matInput  name="url" [formControl]="alertFrm.controls['url']" formControlName="url" placeholder="Ex. Evite or Facebook Event" >
          <div class="alert" *ngIf="!alertFrm.controls['url'].valid && alertFrm.controls['url'].touched">
            You must specify a properly formated site url.
          </div>
        </mat-form-field>







        <!-- <label>Description
          <textarea name="content" id="content" required [formControl]="alertFrm.controls['content']" formControlName="content"></textarea>
        </label>
        <div class="alert" *ngIf="!alertFrm.controls['content'].valid && alertFrm.controls['content'].touched">
          You must specify content that's at least 10 characters long.
        </div> -->

        <input type="submit" class="cta-btn filled" value="Post Alert" >
        <!-- [disabled]="!alertFrm.valid" -->

      </div>
    </div>
    <!-- <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
    <ngx-editor [editor]="editor"   [placeholder]="'Type here...'" [ngModel]="description" ></ngx-editor> -->
  </form>

</div>
