<div class="container">
<div *ngIf="auth.registered; then authenticated else guest">
        <!-- template will replace this div -->
</div>
</div>



<!-- User NOT logged in -->
<ng-template #guest>

    <div class="row">
    <div class="col-md-6 col-md-offset-3">
      <h3>Howdy, GUEST</h3>
  	<!-- <p>Name: {{ auth.user!=undefined?auth.user.name:"" }}</p> -->
      <p>Login to take full advantage...</p>
      <div *ngIf="auth.loginError">Login attempt failed. Check email and password</div>
      <form [formGroup]="loginForm">
        <div class="form-group">
          <label>Email address</label>
          <input type="email" formControlName="email" class="form-control">
        </div>
        <div class="form-group">
          <label>Password</label>
          <input type="password" class="form-control" formControlName="password">
          <label class="error">{{errorMessage}}</label>
        </div>
        <button type="submit" (click)="tryLogin(loginForm.value)" class="btn btn-default">Login</button>
      </form>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-md-offset-4">
      <button (click)="googleLogin()">
          <i class="fa fa-google"></i> Connect Google
      </button>
  </div>
</div>
<div class="row">
  <div class="col-md-4 col-md-offset-4">
    <button type="button" class="btn btn-primary btn-block" (click)="tryFacebookLogin()">Connect Facebook</button>
  </div>
</div>
<!-- <div class="row">
  <div class="col-md-4 col-md-offset-4">
    <button type="button" class="btn btn-info btn-block" (click)="tryTwitterLogin()">Login with Twitter</button>
  </div>
</div> -->
<div class="row">
  <div class="col-md-4 col-md-offset-4">
    <p *ngIf="!hideLink">No account yet? <a routerLink="/register">Create an account</a></p>
  </div>
</div>

</ng-template>


<!-- User logged in -->
<ng-template #authenticated>
    <!-- <div *ngIf="this.auth.user$ | async">
        <h3>Howdy, {{ user.displayName }}</h3>
        <img  [src]="user.photoURL">
        <p>UID: {{ user.uid }}</p>
        <p>Favorite Color: {{ user?.favoriteColor }} </p>
        <button (click)="auth.signOut()">Logout</button>
    </div> -->
    <div class="row">
      <div class="col-md-6 col-md-offset-3">
	<p>Name: {{ auth.user.displayName }}</p>
  <p>Email: {{ auth.user.email }}</p>
        <!-- <p>UID: {{ auth.authState.uid }}</p> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-md-offset-4">

        <button (click)="auth.signOut()">Logout</button>
      </div>
    </div>
</ng-template>
