<table mat-table [dataSource]="dsDoors">

  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Door</th>
    <td mat-cell *matCellDef="let element"> {{element.name}}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="is_external">
    <th mat-header-cell *matHeaderCellDef>Outside Door </th>
    <td mat-cell *matCellDef="let element"> {{element.is_external}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="flow_from">
    <th mat-header-cell *matHeaderCellDef> From </th>
    <td mat-cell *matCellDef="let element"> {{element.is_external==false?element.flow_from.name:'Outside'}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="flow_to">
    <th mat-header-cell *matHeaderCellDef> To </th>
    <td mat-cell *matCellDef="let element"> {{element.flow_to.name}} </td>
  </ng-container>
  <!-- Action Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions <a [routerLink]="['/location/'+locationId+'/door-crud/']">
      <mat-icon>add</mat-icon>
    </a></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button  routerLink="/location/{{element.location_id}}/door-crud/{{ element.id }}"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button  (click)="deleteDoor(element.location_id,element.id)"><mat-icon>delete</mat-icon></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
