<div class="container">
  <h1> Scan Offer QR </h1>

  <!-- devices -->
  <div class="row">
    <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
      <select #select1 (change)="action.playDevice(select1.value)" class="form-select form-select-md">
        <option [value]="null" selected>Select device</option>
        <option *ngFor="let c of action.devices.value; let i = index" [value]="c.deviceId" [selected]="i == action.deviceIndexActive">{{c.label}}</option>
      </select>
    </div>

    <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3 my-1">
      <select #select2 (change)="action.decode = select2.value" class="form-select form-select-md">
        <option value="utf-8" [selected]="action.decode == 'utf-8'">UTF-8</option>
        <option value="iso-8859-15" [selected]="action.decode == 'iso-8859-15'">ISO-8859-15</option>
        <option value="windows-1252" [selected]="action.decode == 'windows-1252'">Windows-1252</option>
        <option value="macintosh" [selected]="action.decode == 'macintosh'">Macintosh</option>
      </select>
    </div>
  </div>


  <!-- ngx-scanner-qrcode -->
  <div class="scanner-window">
    <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
  </div>


<!-- data  -->
<!-- value -->
<!-- <span>{{ action.data.value | json }}</span> -->
<!-- async -->
<!-- <span>{{ action.data | async | json }}</span> -->



<!-- Loading -->

<p *ngIf="action.isLoading">⌛ Loading...</p>



<!-- start/stop -->
  <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading" (click)="handle(action, action.isStart ? 'stop' : 'start')">
    <img [src]="action.isStart ? 'https://id1945.github.io/images/svg/camera-off.svg' : 'https://id1945.github.io/images/svg/camera-on.svg'" width="30px"/>
  </button>

  <!-- play/pause -->
  <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="!action.isStart" (click)="handle(action, action.isPause ? 'play' : 'pause')">
    <img [src]="action.isPause ? 'https://id1945.github.io/images/svg/play.svg': 'https://id1945.github.io/images/svg/pause.svg'" width="30px"/>
  </button>

  <!-- isTorch -->
  <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="!action.isStart" (click)="action.isTorch = !action.isTorch; handle(action, 'torcher')">
    <img [src]="action.isTorch ? 'https://id1945.github.io/images/svg/flash-off.svg' : 'https://id1945.github.io/images/svg/flash-on.svg'" width="30px"/>
  </button>

  <!-- download -->
  <!-- <button class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="!action.isStart" (click)="handle(action, 'download')">
    <img src="https://id1945.github.io/images/svg/capture.svg" width="30px"/>
  </button> -->
  <br>

  <!-- for select files -->
  <!-- <input #file type="file" (change)="onSelects(file.files)" [multiple]="'multiple'" [accept]="'.jpg, .png, .gif, .jpeg'" class="btn btn-success my-2"/>
  <br>
  <input #file2 type="file" (change)="onSelects2(file2.files)" [multiple]="'multiple'" [accept]="'.jpg, .png, .gif, .jpeg'" class="btn btn-success my-2"/>
  <br> -->

  <button (click)="onGetConstraints()" class="btn btn-success">Get</button>
  <button (click)="applyConstraints()" class="btn btn-success">Apply</button>

  <div class="d-flex justify-content-center my-2">
    <div class="col-xs-12 col-md-3 m-1">
      <select #select3 (change)="percentage = +select3.value" class="form-select form-select-md">
        <option *ngFor="let item of [10,20,30,40,50,60,70,80,90,100]" [value]="item" [selected]="item == percentage">Scale {{item}}%</option>
      </select>
    </div>
    <div class="col-xs-12 col-md-3 m-1">
      <select #select4 (change)="quality = +select4.value" class="form-select form-select-md">
        <option *ngFor="let item of [10,20,30,40,50,60,70,80,90,100]" [value]="item" [selected]="item == quality">Quality {{item}}%</option>
      </select>
    </div>
  </div>

  <!-- <div *ngFor="let item of qrCodeResult">
    <ngx-scanner-qrcode #actionFile="scanner" [src]="item.url" [config]="config" (event)="onEvent($event)"></ngx-scanner-qrcode>

  </div>

  <div *ngFor="let item of qrCodeResult2">
    <img [src]="item.url | safe: 'url'" alt="" style="max-width: 100%">

  </div> -->

  <!-- <ngx-scanner-qrcode
    #actionFile="scanner"
    (event)="onEvent($event)"
    [src]="'https://raw.githubusercontent.com/id1945/ngx-scanner-qrcode/master/supported-barcode-types.png'"
    [config]="config">
  </ngx-scanner-qrcode> -->
<div *ngIf="!isScanComplete && isRedeemSuccess == null; else elseBlockResults">
</div>
<ng-template #elseBlockResults>
  <div>
    <img *ngIf="isRedeemSuccess" src="/assets/images/check-green.png" class="status">
    <img *ngIf="!isRedeemSuccess" src="/assets/images/x-red.png" class="status">
  </div>
  <div>{{status}}</div>

</ng-template>
</div>
