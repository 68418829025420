<textarea #sample matInput placeholder="Paste sample device message."></textarea>
<button class="header-item" mat-icon-button (click)="procesSample($event)" >
   Process
</button>
<button class="header-item" mat-icon-button (click)="clearSample($event)" >
   clear
</button>
<table mat-table [dataSource]="fieldMap" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="is_used">
    <th mat-header-cell *matHeaderCellDef> Use </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <mat-checkbox class="example-margin" [checked]="fieldMap[i].iu" (change)="boxClick($event.checked,i)" ></mat-checkbox>
    </td>
    <!-- [(ngModel)]="checked" -->
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="friendly">
    <th mat-header-cell *matHeaderCellDef> Internal Field </th>
    <td mat-cell *matCellDef="let element; let i = index"> {{element.fr}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="external_field">
    <th mat-header-cell *matHeaderCellDef> External Field </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <div  *ngIf="parseResults.length==0; else elseBlockSampleSelect">
        <label>Count Field<br />
          <input type="text" matInput [(ngModel)]="fieldMap[i].ef" (blur)="onBlurEvent($event,i)" value="{{fieldMap[i].ef}}" >
        </label>
        <div>
          Specifc Position in Message <mat-slide-toggle [checked]="fieldMap[i].ic" (change)="onIsConditionalChange($event,i)">Use Condition</mat-slide-toggle>
 <!-- [checked]="fieldMap[i].ic" -->
        </div>
        <div  *ngIf="fieldMap[i].ic">
          <label>Conditional Field <br />
            <span class="small">All instances of counts that match will be summed</span><br />
            <input type="text" matInput [(ngModel)]="fieldMap[i].wf" (blur)="onWhereBlurEvent($event,i)" value="{{fieldMap[i].wf}}" >
          </label>
          <label>Conidtional Value<br />
            <input type="text" matInput [(ngModel)]="fieldMap[i].wv" (blur)="onWhereBlurEvent($event,i)" value="{{fieldMap[i].wv}}" >
          </label>
        </div>

      </div>


      <!-- select from paramResults  -->
      <ng-template #elseBlockSampleSelect>
        <mat-select placeholder="Select field"  [(ngModel)]="fieldMap[i].ef" [(value)]="fieldMap[i].ef" (blur)="onBlurSelectEvent($event,i)" >
          <mat-option *ngFor="let result of parseResults" [value]="result.displayTxt">{{result.displayTxt}}</mat-option>
        </mat-select>
        <!-- choose "Exact position" (specific) or "where condition" -->
        <div>
          <mat-slide-toggle [checked]="fieldMap[i].ic"  [(ngModel)]="fieldMap[i].wf" (change)="onIsConditionalChange($event,i)">Use Condition vs Specifc Position in Message</mat-slide-toggle>
 <!-- [checked]="fieldMap[i].is_conditional" -->
        </div>
         <!-- (change)="onIsPollingChange($event)" -->
        <!-- if "where condition", select second paramResults and use path and value as condition -->
        <div  *ngIf="fieldMap[i].ic">
          Conditional Field and Value<br />
          <span class="small">All instances of counts that match will be summed</span><br />
          <mat-select placeholder="Select field and value" [(value)]="fieldMap[i].wf" (blur)="onWhereBlurSelectEvent($event,i)" >
            <mat-option *ngFor="let result of parseResults" [value]="result.displayTxt">{{result.displayTxt}}</mat-option>
          </mat-select>
        </div>
      </ng-template>

    </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
