import { Component, OnInit } from '@angular/core';
import { UserService } from '../api/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../api/location.service';


import { Door,RoomSensor,Room, } from '../api/location';
import { AuthService } from '../core/auth.service';
import { first } from 'rxjs/operators';

import { debuglog,RxJsLoggingLevel } from '../core/debug.operator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-room-crud',
  templateUrl: './room-crud.component.html',
  styleUrls: ['./room-crud.component.css']
})
export class RoomCrudComponent implements OnInit {

  room: any;

  id: number;
  locationId:number;
  isAddMode: boolean;

  roomDummy:Room={
    name : '',
    location_id:-1,
    entry_doors: undefined,
    exit_doors:undefined,
    all_doors:undefined,
    max_cap: 0,
    current_state: 0,
    room_sensors:undefined,
    id: undefined
  };
  roomFrm: FormGroup;

  roomSensors: Array<RoomSensor>;
  doorsIndex:Array<Number>;
  loadedRoom:any;
  dsRoomSensors: MatTableDataSource<RoomSensor>;
  dsDoors: MatTableDataSource<Door>;

  constructor(
    public auth: AuthService,
    public userSvc: UserService,
    private locationSvc: LocationService,
    private router: Router,
    private aR: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.dsRoomSensors = new MatTableDataSource<RoomSensor>();
    this.dsDoors = new MatTableDataSource<Door>();
   }

  ngOnInit() {
    this.userSvc.refreshMe();
    this.id = +this.aR.snapshot.params['id'];
    this.locationId = +this.aR.snapshot.params['location_id'];
    debuglog(RxJsLoggingLevel.DEBUG,'room-crud::init: id',this.id);
    debuglog(RxJsLoggingLevel.DEBUG,'room-crud::init: locationId',this.locationId);
    this.isAddMode = !this.id;
    this.setupFormGroup(this.roomDummy);
    if (!this.isAddMode) {
      this.locationSvc.getRoom(this.locationId,this.id)
        .pipe(first())
        .subscribe(x => {
          this.room=x.attributes;
          debuglog(RxJsLoggingLevel.DEBUG,'room-crud::init: room',this.room);
          // this.rooms.push(...x.attributes.rooms);
          this.dsRoomSensors.data = this.room.room_sensors;
          this.dsDoors.data = this.room.all_doors;
          // this.doors.push(...x.attributes.doors);
          // this.dsDoors.data = this.doors;
          // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: doors',this.doors);
          // debuglog(RxJsLoggingLevel.DEBUG,'location-crud::init: rooms',this.rooms);
          this.roomFrm.patchValue(x.attributes);
        });
    }
  }

  public setupFormGroup(room:Room){
    if(room!= null){
      this.roomFrm = this.fb.group({
        'name' : [room['name'], Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'max_cap' : [room['max_cap'], Validators.compose([Validators.required, Validators.pattern('[1-9].[0-9]*')])],
        'current_state' : [room['current_state'], Validators.compose([Validators.pattern('[0-9]*')])],
      });
    }else{
      this.roomFrm = this.fb.group({
        'name' : [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
        'max_cap' : [null, Validators.compose([Validators.required, Validators.pattern('[1-9].[0-9]*')])],
        'current_state' : [null, Validators.compose([Validators.pattern('[0-9]*')])],
      });
    }

  }
  addRoom(locationId:number,roomId:number|undefined, room: Room) {
    debuglog(RxJsLoggingLevel.DEBUG, 'room-crud:addRoom::room', locationId,roomId,room);

    if ( !isNaN(roomId)) {

      let sub=this.locationSvc.updateRoom(room,locationId, roomId)
        .subscribe(updateRoom => {
          sub.unsubscribe();
          this.router.navigateByUrl('/location-crud/'+locationId);
        })
    } else {

      let sub=this.locationSvc.insertRoom(room,locationId)
        .subscribe(newRoom => {
          // this.locations.push(newLocation);
          sub.unsubscribe();
          this.router.navigateByUrl('/location-crud/'+locationId);
        })
    }
  }

}
const urlPattern= '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$'
