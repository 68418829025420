
<mat-nav-list>
  <a mat-list-item routerLink="/" >Home</a>
  <a mat-list-item routerLink="/user" >User Profile</a>
  <a mat-list-item *ngIf="userSvc.checkAuthorization(['superadmin','admin'])" routerLink="/location-manage" >Location Management</a>
  <a mat-list-item *ngIf="userSvc.checkAuthorization(['manager','owner'])" routerLink="/location-manage">Manage UR Locations</a>
  <a mat-list-item *ngIf="userSvc.checkAuthorization(['admin','superadmin'])" routerLink="/user-manage" >User Management</a>
  <a mat-list-item *ngIf="userSvc.checkAuthorization(['superadmin','admin'])" routerLink="/alert-manage" >Alert Messages</a>
  <a mat-list-item *ngIf="userSvc.checkAuthorization(['superadmin','admin','manager','owner','worker'])" routerLink="/alert-redemption" >Alert Redemption</a>
  <a mat-list-item routerLink="/about" >About Us</a>
  <a mat-list-item routerLink="/contact" >Contact Us</a>
  <a mat-list-item *ngIf="userSvc.checkAuthorization(['registered'])" routerLink="/join-team" >Join Team</a>
  <!-- <a mat-list-item routerLink="." *ngFor="let nav of fillerNav">{{nav}}</a> -->
</mat-nav-list>
<mat-menu  #locationMenu="matMenu">
  <!-- TODO add pipe to min length and tooltip for with full name, add link to location edit componenent -->
  <button *ngFor="let location of locationList" mat-menu-item>{{location.name}}</button>
</mat-menu>
<mat-hint>{{version}}</mat-hint>
