<a [routerLink]="['/location-crud/'+locationId]">
  Back
</a>
<div class="location-container" *ngIf="!isAddMode">
  <form [formGroup]="teamMemberFrm" (ngSubmit)="addTeamInvite(locationId,teamMemberFrm.value)" *ngIf="teamMemberFrm">
    <div class="row expanded">
      <div  layout="column" layout-align="center end">
        <div>
          {{teamMember.user.name}}<br />
            {{teamMember.user.email}}

        </div>
        <div>
          <label for="role">Role:</label>
          <select formControlName="role" class="form-control" name="role"  [formControl]="teamMemberFrm.controls['role']" (change)="changeRole($event)">
            <option disabled>Select Role</option>
            <option value="worker"  *ngIf="canAddWorker();">Worker</option>
            <option value="manager" *ngIf="canAddManager();" >Manager</option>
            <option value="owner" *ngIf="canAddOwner();" >Owner</option>
          </select>

      </div>

        <input type="submit" class="cta-btn filled" value="Update" >
        <!-- [disabled]="!teamMemberFrm.valid" -->

      </div>
    </div>
  </form>

</div>
